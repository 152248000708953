<template>
    <div class="py-4 px-5 space-y-2">
        {{ event.description }}
    </div>

    <div class="py-1 px-5">
        <ul class="list-disc pl-6">
            <li v-for="key_point in event.keyPoints" :key="key_point">{{ key_point }}</li>
        </ul>
    </div>

    <div class="py-4 px-5 space-y-2">
        <div v-if="event.location.address">
            <div class="text-sm text-gray-300 w-28 inline-block table-cell items-center">Location</div>
            <div class="table-cell">{{ event.location.address }}</div>
        </div>

        <div v-if="event.location.country">
            <div class="text-sm text-gray-300 w-28 inline-block">Country</div>
            <ui-icon :name="country.icon" class="mr-2" v-if="country?.icon"></ui-icon>
            <span>{{ event.location.country }}</span>
        </div>

        <div v-if="event.category">
            <div class="text-sm text-gray-300 w-28 inline-block table-cell items-center">Category</div>
            <div class="flex items-center font-medium inline-block rounded table-cell">
                <div class="w-3 h-3 rounded-full inline-block mr-2 mt-1 float-left" :style="{ backgroundColor: category?.color }"></div>
                <div class="capitalize float-left">{{ category?.name }}</div>

                <span v-if="subcategories.length" v-for="subcategory in subcategories" :key="subcategory" class="capitalize">
                    &nbsp;→ {{ subcategory }}
                </span>
            </div>
        </div>

        <div v-if="event.casualties">
            <div class="text-sm text-gray-300 w-28 inline-block">Casualties</div>
            <span>{{ event.casualties }}</span>
        </div>

        <div v-if="event.damages">
            <div class="text-sm text-gray-300 w-28 inline-block">Damages</div>
            <div class="inline-block capitalize">{{ event.damages }}</div>
        </div>

        <div v-if="event.startedOn">
            <div class="text-sm text-gray-300 w-28 inline-block">Event Date</div>
            <span>{{ $date(event.startedOn, 'dd MMMM yyyy') }}</span>
        </div>

        <div v-if="event.firstReportedAt">
            <div class="text-sm text-gray-300 w-28 inline-block">First reported At</div>
            <span>{{ $dateTime(event.firstReportedAt, 'dd MMMM yyyy HH:mm') }}</span>
        </div>

        <div v-if="event.topChannelReliability">
            <div class="text-sm text-gray-300 w-28 inline-block">
                Veracity Rating
            </div>
            <span>{{ event.topChannelReliability }}{{ event.credibility }}</span>
        </div>

        <div v-if="event.tags?.length" class="flex items-top">
            <div class="text-sm text-gray-300 w-28 inline-block">Tags</div>
            <div class="flex flex-wrap space-x-3">
                <div v-for="tag in event.tags" :key="tag.id" class="inline-flex items-center bg-gray-600 font-medium inline-block px-2 rounded">
                    <div class="w-3 h-3 rounded-full inline-block mr-2" :class="`bg-${tag?.color}`"></div>
                    <span>{{tag.name}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="py-4 px-5 space-y-4 border-t border-gray-500 w-full">
        <h3 class="font-semibold">Timeline</h3>
        <timeline-list />
    </div>
</template>

<script>
import TimelineList from '@/components/overview/details/partials/timeline-list'
import useDetailsStore from '@/stores/overview/details'
import useOverviewStore from '@/stores/overview/overview'
import useEventCategoriesStore from "@/stores/me/event-categories"
import CountryOptions from "@/stores/me/countries"
import { mapActions, mapState } from 'pinia'


export default {

    components: { TimelineList },

    computed: {
        ...mapState(useDetailsStore, [ 'event' ]),

        category() { return useEventCategoriesStore().find(this.event.category) },

        subcategories() {
            if (this.event.fullCategory) {
                const subcategories = this.event.fullCategory.split('.')

                if (subcategories.length > 1) {
                    subcategories.shift()
                    return subcategories.map(s => s.replaceAll('_', ' '))
                }
            }

            return []
        },

        country() { return CountryOptions.find(c => c.name === this.event.location.country) }

    },

    methods: {
        ...mapActions(useOverviewStore, [ 'hideSidebar' ])
    }
}
</script>