import api from '@/api'
import {useModal} from '@/helpers'

import {defineStore} from 'pinia'

export const useManagementModeratedEventsStore = defineStore({
    id: 'managementModeratedEvents',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        pollingInterval: null,

        critical: false,

        resolution: null,

        customWorkspacesEnabled: false,
        customWorkspaces: [],

        customUsersEnabled: false,
        customUsers: [],

        loadingPromise: null,

        overlayStore: null
    }),

    getters: {
        pendingModeratedEventsCount(state) {
            return this.items.filter(i => i.status === 'pending').length
        }
    },

    actions: {
        async initialize(overlayStore) {
            if (this.isInitialized) return
            
            this.overlayStore = overlayStore

            await this.load()

            this.startPolling()
        },

        async load(force = false, silent = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise && ! silent) return this.loadingPromise

            if (! silent) { this.isLoading = true }

            return this.loadingPromise = api.route('sentinel management moderated-events').get().json(res => {
                this.items = res.data

                if (! silent) {
                    this.isLoading = false
                    this.isInitialized = true

                    this.loadingPromise = null
                }
            })
        },

        async reload(silent = false) {
            return this.load(true, silent)
        },

        async all() {
            await this.load()
            return this.items
        },

        startPolling() {
            this.pollingInterval = setInterval(() => {
                this.reload(true)
            }, 10000)
        },

        stopPolling() {
            if (! this.pollingInterval) return

            clearInterval(this.pollingInterval)
        },

        scroll($state) {
            let lastItem = this.items[this.items.length - 1]

            if (! lastItem) return $state.complete()

            api.route('sentinel management moderated-events')
                .query({ before: lastItem.id })
                .get()
                .json(res => {
                    if (! res.data.length) {
                        return $state.complete()
                    }

                    res.data.forEach(i => {
                        this.items.push(i)
                    })

                    $state.loaded()
                })
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        toggleCritical() {
            this.critical = ! this.critical
        },

        toggleCustomWorkspaces() {
            this.customUsersEnabled = false
            this.customWorkspacesEnabled = ! this.customWorkspacesEnabled
        },

        toggleCustomUsers() {
            this.customWorkspacesEnabled = false
            this.customUsersEnabled = ! this.customUsersEnabled
        },

        async process(moderatedEvent, status) {
            moderatedEvent.status = 'processing'

            await api.route('sentinel management moderated-events process', moderatedEvent.id)
                .formData({
                    _method: 'put',
                    status: status || this.resolution,
                    'data[critical]': this.critical,
                    'data[customWorkspaceIds][]': this.customWorkspacesEnabled ? this.customWorkspaces.map(i => i.id) : [],
                    'data[customUserIds][]': this.customUsersEnabled ? this.customUsers.map(i => i.id) : []
                }, true)
                .post()
                .json(res => {
                    moderatedEvent.status = res.status
                    this.overlayStore().showList()
                    useModal().hide('moderated-events-event-alert-edit')
                })
        }
    }
})

export default useManagementModeratedEventsStore
