import usePerspectiveEditModalStore from './modals/perspective-edit'
import useMapStore from '@/stores/overview/map'
import useMyStore from '@/stores/me/my'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { default as api, asyncResource } from '@/api'
import { useRoute, useRouter } from '@/helpers'
import searchFilters from '@/helpers/search-filters'

import { parseISO, isAfter, subHours, subMinutes } from 'date-fns'
import filter from 'just-filter'
import { defineStore } from 'pinia'
import useDetailsStore from "@/stores/overview/details";

export const useStreamStore = defineStore({
    id: 'overviewEvents',

    state: () => ({
        perspective: null,
        filters: searchFilters(),

        events: asyncResource({
            request: (api, store, payload) => api.route('sentinel events', {
                bare: true,
                filters: store.filters.toJson(),
                sorting: store.filters.value('semantic') ? 'relevance' : 'date-desc',
                limit: 5000,
                includeTotal: 1,
                publishedPerspective: store.publishedToken,
                ...payload
            }),
            // TODO pagination disabled for client demo - fix this
            paginated: false
        }),

        newEvents: asyncResource({
            request: (api, store) => api.route('sentinel events', {
                bare: true,
                filters: store.filters.toJson(),
                sorting: store.filters.value('semantic') ? 'relevance' : 'date-asc',
                after: store.firstItem?.sort,
                limit: 5000,
                includeTotal: 1,
                publishedPerspective: store.publishedToken
            })
        }),

        search: { query: null, language: null, types: [] },

        isSavingPerspective: false,
        toolbarIsShown: true,
        filtersAreShown: true,

        publishedFilters: searchFilters(),
        publishedToken: null,

        pollingInterval: null,

        mapLayers: []
    }),

    getters: {
        firstItem(store) {
            if (store.events.data) return store.events.data[0]
        },

        saveButtonIsShown() {
            return this.hasUnsavedChanges && ! this.publishedToken
        },

        hasUnsavedChanges() {
            return JSON.stringify(this.perspective?.filters) != this.filters.toJson()
                || JSON.stringify(this.perspective?.meta) != JSON.stringify({
                    mapLayers: this.mapLayers.map(l => ({ id: l.id, show: l.show, filter: l.filter })),
                    mapCenter: useMapStore().getCenter(),
                    mapZoom: useMapStore().getZoom()
                })
        }
    },

    actions: {
        async initialize(to, defaultFilters = {}) {
            if (this.isInitialized) return

            this.search.language = useMyStore().searchLanguage

            this.fromQuery(to?.query || {}, {}, defaultFilters)
            useDetailsStore().setFilters(this.filters)
            this.filters.onChange = (action, payload) => {
                useDetailsStore().setFilters(this.filters)

                this.load()
                this.replaceRoute()
            }

            if (to.name != 'overview.event') {
                this.load()
            }
            
            this.isInitialized = true
        },

        async load() {
            this.stopPolling()

            await this.events.fetch(this, {
                onResponse: () => useMapStore().update(this.events.data)
            })

            this.startPolling()
        },
        
        async loadEvent(id, updateEvents = true) {
            let event = this.events?.data?.find(e => e.id == id)

            if (event) return event

            event = await api.route('sentinel events detail', [ id ]).get().json(res => res.data)

            if (event && updateEvents) {
                this.events.data = [ event ]

                useMapStore().update(this.events.data)
            }

            return event
        },

        clear() {
            this.events.reset()
            this.newEvents.reset()
            this.stopPolling()
        },

        reset() {
            this.clear()

            this.perspective = null
            this.search.query = null

            this.filters.onChange = () => {}
            this.filters.clear()

            this.mapLayers = []

            this.isInitialized = false
        },
        
        startPolling() {
            this.stopPolling()

            this.pollingInterval = setInterval(async () => {
                // TODO disabling polling for semantic search
                if (this.filters.value('semantic')) return

                let newEvents = await this.newEvents.fetch(this)

                if (! newEvents?.length) return

                this.events.data.forEach(item => item.isNew = false)
                newEvents.forEach(item => item.isNew = true)

                this.events.data = this.trimEvents([ ...newEvents.reverse(), ...this.events.data ])

                useMapStore().update(this.events.data)
            }, 30000)
        },

        stopPolling() {
            if (! this.pollingInterval) return

            clearInterval(this.pollingInterval)
        },

        trimEvents(events) {
            let dateFilter = this.filters.value('date')

            if (dateFilter?.type == 'reported') {
                let cutoffDate = dateFilter.date.unit == 'hours'
                    ? subHours(new Date, dateFilter.date.reported) : subMinutes(new Date, dateFilter.date.reported)
                return events.filter(e => isAfter(parseISO(e.lastReportedAt), cutoffDate))
            }

            return events
        },

        applySearchQuery() {
            if (this.search.query) {
                this.filters.set('text', { ...this.search })
            } else {
                this.filters.remove('text')
            }
        },

        applySearchLanguage(language) {
            this.search.language = language

            if (this.search.query) this.applySearchQuery()
        },
        
        toggleSearchType(type) {
            this.search.types.includes(type)
                ? this.search.types = this.search.types.filter(t => t != type)
                : this.search.types.push(type)

            if (this.search.query) this.applySearchQuery()
        },

        clearSearch() {
            this.search.query = ''
            this.filters.remove('text')
        },

        clearFilters() {
            if (this.publishedToken) this.filtersAreShown = false

            if (this.filters.isEmpty()) return

            this.clearSearch()
            this.filters.clear()
        },

        applyPerspective(perspective, query = {}) {
            if (! perspective || this.perspective?.id == perspective.id) return

            this.perspective = perspective

            this.filters.clear()

            let overrideFilters = Object.entries(query).map(([ key, value ]) => {
                if (key.match(/^override-filter\[(.*?)\]$/)) {
                    return [ key.replace(/^override-filter\[(.*?)\]$/, 'filter[$1]'), value ]
                }
            }).filter(Boolean)

            this.filters.fromPerspective(perspective.filters)
            this.filters.fromQuery(Object.fromEntries(overrideFilters))

            this.search.query = this.filters.value('text')?.query

            this.replaceRoute()
        },

        async savePerspective() {
            if (! this.perspective) {
                return this.perspective = await usePerspectiveEditModalStore().open(this.perspective, this)
            }

            if (! this.perspective.name || this.isSavingPerspective) return

            this.isSavingPerspective = true

            await api.route(this.perspective.id ? 'me perspectives update' : 'me perspectives store', [ this.perspective.id ])
                .formData({
                    _method: this.perspective.id ? 'put' : 'post',
                    name: this.perspective.name,
                    type: 'sentinel',
                    filters: this.filters.toJson(),
                    meta: JSON.stringify({
                        mapLayers: this.mapLayers.map(l => ({ id: l.id, show: l.show, filter: l.filter })),
                        mapCenter: useMapStore().getCenter(),
                        mapZoom: useMapStore().getZoom(),
                        mapStyle: useMapStore().mapStyle
                    })
                })
                .post()
                .json(res => this.perspective = res.data)

            this.isSavingPerspective = false

            await useMyPerspectivesStore().reload()

            this.perspective = useMyPerspectivesStore().find(this.perspective.id)

            this.replaceRoute()
        },

        async deletePerspective() {
            try {
                await useMyPerspectivesStore().delete(this.perspective)
            } catch (e) {
                return
            }

            this.perspective = null

            this.replaceRoute()
        },

        replaceRoute() {
            if (! this.isInitialized) return

            let query = filter(useRoute().query, key => {
                return ! (key.match(/filter\[.+?\]/) || [ 'perspective', 'topic', 'sorting' ].includes(key))
            })

            useRouter().replace(
                { query: { ...query, ...this.toQuery() } },
                null,
                error => { if (error.name != 'NavigationDuplicated') throw error }
            )
        },

        fromQuery(query, forcedFilters = {}, defaultFilters = {}) {
            this.filters.setDefaults(defaultFilters)
            this.filters.fromQuery(query)
            this.filters.set(forcedFilters, true)

            this.search = { ...this.search, ...this.filters.value('text') }
        },
        
        toQuery() {
            return {
                ...(this.perspective ? { perspective: this.perspective.id.toString() } : {}),
                ...this.filters.toQuery()
            }
        },

        async initializeAsGuest(token, perspective) {
            if (this.isInitialized) return

            this.publishedToken = token
            this.filtersAreShown = false

            this.perspective = perspective

            this.publishedFilters.fromPerspective(this.perspective.filters)
            this.publishedFilters.readOnly = {
                'date': true, 'sources': true, 'category': true, 'geoFence': true, 'semantic': true, 'tags': true, 'language': true, 'veracity': true
            }

            return this.initialize()
        },
        
        showToolbar() {
            this.toolbarIsShown = true
        },

        hideToolbar() {
            this.toolbarIsShown = false
        },

        setMapLayers(mapLayers) {
            this.mapLayers = mapLayers
        }
    }
})

export default useStreamStore
