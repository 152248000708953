<template>
    <div class="h-full relative">
        <div v-if="store.isLoading" class="absolute inset-0 z-10 flex items-center justify-center bg-gray-100 bg-opacity-10 backdrop-blur-sm rounded-md">
            <div class="bg-gray-200 bg-opacity-80 text-gray-900 font-medium px-6 py-4 rounded-lg shadow-sm flex items-center">
                <ui-spinner type="clip" class="mr-2"></ui-spinner>
                Updating chart content...
            </div>
        </div>

        <div class="h-full" v-if="store.isRenderable">
            <highcharts-analysis class="hc h-full" :options="store.analysisOptions" ref="analysis"></highcharts-analysis>
        </div>

        <div v-else-if="! store.isLoading" class="h-full flex flex-col items-center justify-center text-gray-300">
            <p class="font-medium">Nothing to show yet.</p>
            <p>Please check back later or try a different time range.</p>
        </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts'
import { Chart as HighchartsAnalysis } from 'highcharts-vue'
import exportingInit from 'highcharts/modules/exporting'
import offlineExportingInit from 'highcharts/modules/offline-exporting'
import exportDataInit from 'highcharts/modules/export-data'
import accessibilityInit from 'highcharts/modules/accessibility'

Highcharts.setOptions({
    exporting: {
        buttons: { contextButton: { enabled: false } },
        fallbackToExportServer: false,
        libURL: '',
        sourceHeight: 500,
        sourceWidth: 1000,
        url: ''
    }
})

export default {
    components: { HighchartsAnalysis },

    methods: {
        print(...args) {
            if (this.$refs.analysis) this.$refs.analysis.chart.print(...args)
        },

        export(...args) {
            if (args[0] === 'xls') return this.$refs.analysis.chart.downloadXLS()
            if (args[0] === 'csv') return this.$refs.analysis.chart.downloadCSV()

            if (this.$refs.analysis) this.$refs.analysis.chart.exportChartLocal(...args)
        },

        reflow() {
            if (this.$refs.analysis) this.$refs.analysis.chart.reflow()
        }
    },

    mounted() {
        this.intersectionObserver = new IntersectionObserver(entries => {
            if (entries.some(e => e.isIntersecting)) {
                this.reflow()
            }
        })
        this.intersectionObserver.observe(this.$el)
    },

    unmounted() {
        this.intersectionObserver?.disconnect()
    }
}
</script>
