<template>
    <div class="py-4 px-5 space-y-2">
        <ul class="list-disc pl-6" v-if="event?.mentions?.length">
            <li v-for="mention in event.mentions" :key="mention"><b>{{ mention.name }}</b> ({{ mention.type }}) is
                <i>{{ mention.role }}</i>.
            </li>
        </ul>

        <div class="text-2xl text-center mt-16" v-else>
            No mentions found for this event
        </div>
    </div>
</template>


<script>
import useDetailsStore from "@/stores/overview/details"

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useDetailsStore, ['event'])
    },
}
</script>
