import useAppStore from '@/stores/app'
import useDetailsStore from '@/stores/overview/details'
import useGeoFeatureEditModalStore from '@/stores/overview/modals/geo-feature-edit'
import useMapStore from './map'
import useStreamStore from '@/stores/overview/stream'
import useMyGeoFeaturesStore from '@/stores/me/geo-features'
import useMyNotificationsStore from '@/stores/me/notifications'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import api from '@/api'
import { useRouter } from '@/helpers'
import filterRuleset, { filterRulesetIncludes } from '@/helpers/filter-ruleset'

import { defineStore } from 'pinia'

export const useOverviewStore = defineStore({
    id: 'overview',

    state: () => ({
        mapLayers: [],
        shownFeatures: [],

        geoFeatureBeingCreated: null,
        selectedMapFeature: null,

        isInitialized: false
    }),

    actions: {
        initialize(to) {
            if (this.isInitialized) return

            useMapStore().overviewStore = useOverviewStore
            useStreamStore().initialize(to, { date: { type: 'reported', date: { reported: 30, unit: 'minutes' } } })

            if (to.name == 'overview.event') {
                this.inspectEvents({ event: to.params.eventId, center: [-250, 0] })
            }

            useStreamStore().filters.value('geoFence')?.map(v => this.showGeoLayer(v.id, {
                show: v.include.length || v.exclude.length ? { include: v.include, exclude: v.exclude } : true,
                filter: v.include.length || v.exclude.length ? { include: v.include, exclude: v.exclude } : true,
                geofence: true
            }))

            this.applyPerspective(useMyPerspectivesStore().find(to?.params.perspectiveId), to?.query)

            // useMyNotificationsStore().registerAction('show-event', meta => {
            //     useStreamStore().hideToolbar()
            //     useAppStore().setSidebarOptions({ compact: true, backdrop: false })
            //     useDetailsStore().setOptions({ compact: true })
            //     this.inspectEvents({ event: meta.eventId, center: true })
            //
            //     useMyNotificationsStore().onClose = () => {
            //         useStreamStore().showToolbar()
            //         useAppStore().setSidebarOptions({ compact: false, backdrop: true })
            //         useDetailsStore().setOptions({ compact: false })
            //     }
            //
            //     return { select: true }
            // })

            this.isInitialized = true
        },

        initializeAsGuest(token, perspective) {
            if (this.isInitialized) return

            useStreamStore().initializeAsGuest(token, perspective)
            useDetailsStore().initializeAsGuest(token)

            useOverviewStore().applyPerspective(perspective)

            useDetailsStore().setFilters(useStreamStore().filters)

            this.isInitialized = true
        },

        reset() {
            this.clearMap({ geofence: false })

            useAppStore().hideSidebar()
            this.hideDetails()

            useStreamStore().reset()

            this.isInitialized = false
        },

        async inspectEvents(options) {
            options = { center: false, ...options }

            if (! options.event && ! options.cluster?.length) return

            if (typeof options.event == 'string') options.event = await useStreamStore().loadEvent(options.event)

            if (options.center) {
                useMapStore().focusEvent(
                    options.cluster?.[0] || options.event,
                    options.center === true ? [0, 0] : options.center
                )
            }

            this.showDetails({ event: options.event?.id, cluster: options.cluster?.map(e => e.id) })
        },

        toggleDetails() {
            useDetailsStore().isShown ? this.hideDetails() : this.showDetails()
        },

        showDetails(options = {}) {
            useDetailsStore().open(options)
        },

        hideDetails() {
            useDetailsStore().close()
            useMapStore().setActiveMarker(null)
        },

        async applyPerspective(perspective) {
            if (! perspective) return

            useMapStore().eraseAll(false)

            useStreamStore().applyPerspective(perspective)

            useMapStore().setZoom(perspective.meta.mapZoom)
            useMapStore().setCenter(perspective.meta.mapCenter)
            useMapStore().setMapStyle(perspective.meta.mapStyle)

            perspective.meta.mapLayers?.map(v => this.showGeoLayer(v.id, {
                show: v.show,
                filter: v.filter,
                geofence: true
            }))
        },

        isGeoLayerShown(layerId) {
            return this.mapLayers.find(l => l.id == layerId)
        },

        isGeoLayerFiltered(layerId) {
            return this.mapLayers.find(l => l.id == layerId)?.filter
        },

        async toggleGeoLayer(layerId) {
            this.isGeoLayerShown(layerId) ? this.hideGeoLayer(layerId) : this.showGeoLayer(layerId)
        },

        async showGeoLayer(layerId, options = {}) {
            options = { show: true, filter: false, focus: false, geofence: true, ...options }

            let layer = { id: layerId, show: options.show, filter: options.filter }
            let geojson = await api.route('sentinel geo-features layers geojson', [ layer.id ])
                .post({ include: layer.show?.include, exclude: layer.show?.exclude }).json()

            const newFeatures = geojson.features.filter(newFeature =>
                !this.shownFeatures.some(existingFeature => existingFeature.properties.id === newFeature.properties.id)
            )

            this.shownFeatures = [ ...this.shownFeatures, ...newFeatures ]

            if (this.isGeoLayerShown(layerId)) this.hideGeoLayer(layerId)
            useMapStore().drawCollection(geojson, layer.id)

            this.mapLayers.push(layer)

            if (options.focus) useMapStore().focus(geojson)
            if (options.geofence) this.updateGeoFenceFilter()

            this.propagateMapLayers()
        },

        showBoundary(result) {
            const geoFilter = useStreamStore().filters.value('geoFence') || []
            const layerId = result.gid ? 'areas' : result.layerId
            const featureId = result.gid || result.id

            const filter = geoFilter.find(v => v.id == layerId)

            if (filter) {
                filter.include.push(featureId)
            } else {
                geoFilter.push({ id: layerId, include: [ featureId ], exclude: [] })
            }

            geoFilter.map(v => this.showGeoLayer(v.id, {
                show: v.include.length ? { include: v.include, exclude: [] } : true,
                filter: v.include.length ? { include: v.include, exclude: [] } : true,
                geofence: true,
                focus: true
            }))
        },

        async hideGeoLayer(layerId, options = {}) {
            options = { geofence: true, ...options }

            this.mapLayers = this.mapLayers.filter(l => l.id != layerId)

            useMapStore().eraseCollection(layerId)

            if (options.geofence) this.updateGeoFenceFilter()

            this.propagateMapLayers()
        },

        modifyGeoLayer(layerId, change, options = {}) {
            options = { filter: false, focus: false, geofence: true, ...options }

            let layer = this.mapLayers.find(l => l.id == layerId) || { show: false, filter: false }

            layer.show = filterRuleset(layer.show, change)
            if (options.filter) layer.filter = filterRuleset(layer.filter, change)

            if (layer.show) {
                this.showGeoLayer(layerId, { show: layer.show, filter: layer.filter, focus: options.focus, geofence: options.geofence })
            } else {
                this.hideGeoLayer(layerId)
            }

            this.propagateMapLayers()
        },

        deleteGeoLayer(layer) {
            this.hideGeoLayer(layer)

            useMyGeoFeaturesStore().deleteLayer(layer)
        },

        isGeoFeatureShown(feature) {
            let layer = this.mapLayers.find(l => l.id == feature.layerId)

            return filterRulesetIncludes(layer?.show, feature.id)
        },

        isGeoFeatureFiltered(feature) {
            let layer = this.mapLayers.find(l => l.id == feature.layerId)

            return filterRulesetIncludes(layer?.filter, feature.id)
        },

        toggleGeoFeature(feature) {
            this.isGeoFeatureShown(feature) ? this.hideGeoFeature(feature) : this.showGeoFeature(feature)
        },

        async showGeoFeature(feature, options = { filter: false, focus: false, geofence: true }) {
            feature && this.modifyGeoLayer(feature.layerId, { include: feature.id }, options)
        },

        async hideGeoFeature(feature) {
            this.shownFeatures = [ ...this.shownFeatures.filter(f => f.properties.id != feature.id) ]
            this.modifyGeoLayer(feature.layerId, { exclude: feature.id }, { filter: true })
        },

        deleteGeoFeature(feature) {
            this.hideGeoFeature(feature)
            useMyGeoFeaturesStore().deleteFeature(feature)
        },

        async updateGeoFeatureColor(feature, color) {
            await useMyGeoFeaturesStore().updateFeature(feature, { color })

            if (this.isGeoFeatureShown(feature)) this.showGeoFeature(feature, { filter: this.isGeoFeatureFiltered(feature) })
        },

        setSelectedMapFeature(feature) {
            this.selectedMapFeature = feature
            this.geoFeatureBeingCreated = null
        },

        hideSelectedMapFeature() {
            if (this.selectedMapFeature.properties.id) {
                this.hideGeoFeature(this.selectedMapFeature.properties)
            } else {
                useMapStore().erase(this.selectedMapFeature.id)
            }

            this.selectedMapFeature = null
        },

        async saveSelectedMapFeature() {
            let feature = await useGeoFeatureEditModalStore().open({
                geometry: this.selectedMapFeature.geometry,
                ...this.selectedMapFeature.properties
            })

            useMyGeoFeaturesStore().reloadLayers()

            useMapStore().eraseLatest()

            this.showGeoFeature(feature, { filter: this.selectedMapFeature.properties.filter })

            this.geoFeatureBeingCreated = null
            this.selectedMapFeature = null
            this.updateGeoFenceFilter()
        },

        createPolygon(layer, filter = false) {
            useAppStore().hideSidebar()
            useMapStore().drawPolygon({ layerId: layer?.id, color: 'purple', type: 'polygon', filter })
            this.geoFeatureBeingCreated = 'polygon'
        },

        createCircle(layer, filter = false) {
            useAppStore().hideSidebar()
            useMapStore().drawCircle({ layerId: layer?.id, color: 'purple', type: 'circle', filter })
            this.geoFeatureBeingCreated = 'circle'
        },

        createPoint(layer) {
            useAppStore().hideSidebar()
            useMapStore().drawPoint({ layerId: layer?.id, color: 'purple', type: 'point' })
            this.geoFeatureBeingCreated = 'point'
        },

        stopCreatingGeoFeature() {
            useMapStore().stopDrawing()
            this.geoFeatureBeingCreated = null
        },

        clearMap(options = {}) {
            options = { geofence: true, ...options }

            this.mapLayers.forEach(layer => this.hideGeoLayer(layer.id, options))
            this.shownFeatures = []
            useMapStore().eraseAll()

            this.propagateMapLayers()
        },

        newPerspective(route = {}) {
            this.reset()

            useRouter().push({ name: 'overview', ...route })
        },

        updateGeoFenceFilter() {
            let filteredLayers = this.mapLayers.filter(l => l.filter === true || l.filter?.include?.length || l.filter?.exclude?.length)

            if (filteredLayers.length) {
                const fenceFilters = filteredLayers.map(l => ({
                    id: l.id,
                    include: l.filter?.include,
                    exclude: l.filter?.exclude
                }))

                useStreamStore().filters.set('geoFence', fenceFilters)
            } else {
                useStreamStore().filters.remove('geoFence')
            }
        },

        propagateMapLayers() {
            useDetailsStore().setMapLayers(this.mapLayers)
            useStreamStore().setMapLayers(this.mapLayers)
        }
    }
})

export default useOverviewStore
