import { defineStore } from 'pinia'
import api from '@/api'
import useChannelMapStore from "@/stores/channels/channel-map"
import { useRouter } from "@/helpers"


export const useChannelDetailStore = defineStore('channel-detail', {

    state: () => ({
        loading: false,
        show: false,

        channel: null,
        channels: null,

        reports: [],
        reportsPage: 1,

        shownTab: 'overview',

        sidebarLoading: false,
        showSidebar: false,

        stats: null,
        loadingStats: false,
    }),

    actions: {
        async showChannelDetail(channelId) {
            this.show = true
            this.loading = true
            useChannelMapStore().loading = true
            this.reset()

            this.loadStats(channelId)

            const channelDetailData = await api.route('sentinel channels detail', { channelId })
                .get()
                .json()

            this.channel = channelDetailData.data
            this.loadReports()

            useRouter().push({ name: 'channels.detail', params: { channelId } })

            useChannelMapStore().showChannelOnMap(this.channel)
            useChannelMapStore().loading = false
            this.loading = false
        },

        hideDetail() {
            this.show = false
            this.channel = null
            this.channels = []

            useChannelMapStore().hideChannelDetailLayer()
            useChannelMapStore().cancelMarkerHighlight()
            this.reset()
        },

        detailBack() {
            useChannelMapStore().hideChannelDetailLayer()
            this.reset()
        },

        reset() {
            this.channel = null
            this.reports = []
            this.reportsPage = 1

            this.stats = null
            this.shownTab = 'overview'
        },

        async loadReports(scroll) {
            const channelReportsData = await api
                .route('sentinel channels reports', {
                    channelId: this.channel?.gid,
                    page: this.reportsPage,
                })
                .get()
                .json()


            if (channelReportsData.data.length === 0) {
                scroll && scroll.complete()
                return
            }

            this.reports = [ ... this.reports, ...channelReportsData.data ]
            scroll && scroll.loaded()
            this.reportsPage = this.reportsPage + 1
        },

        async loadChannelsByPoint(point) {
            this.channels = null
            this.show = true
            this.loading = true

            this.reset()
            const channels = await api
                .route('sentinel channels point', { point })
                .get()
                .json()

            if (channels.data.length === 1) {
                this.showChannelDetail(channels.data[0].gid)
                return
            }

            this.channels = channels.data
            this.loading = false
        },

        async loadStats(channelId) {
            this.stats = null
            this.loadingStats = true
            const stats = await api
                .route('sentinel channels stats', { channelId: channelId })
                .get()
                .json()

            this.loadingStats = false
            this.stats = stats.data
        },
    },
})

export default useChannelDetailStore
