<template>
    <div v-if="inputs.delivery" class="space-y-1.5 text-gray-200 w-full">
        <div v-for="option in shownOptions" @click="store.toggleDeliveryOption(option.id)" class="w-full px-5 py-2 text-gray-300 border rounded-lg text-lg group" :class="inputs.delivery[option.id].enabled ? 'border-gerulata-green-300' : 'border-gray-500'" :key="option.id">
            <div class="flex items-center cursor-pointer hover:text-gray-100">
                <div>
                    <ui-icon :name="option.icon" class="text-3xl"></ui-icon>
                </div>
                <div class="px-4">
                    <h1 class="text-lg font-medium">{{option.name}}</h1>
                    <p class="text-sm text-gray-400 group-hover:text-gray-100">{{option.description}}</p>
                </div>
                <div class="ml-auto">
                    <ui-switch :value="inputs.delivery[option.id].enabled"></ui-switch>
                </div>
            </div>

            <template v-if="inputs.delivery[option.id].enabled">
                <div v-if="option.id == 'email'" class="mt-2 space-y-2.5" @click.stop>
                    <div class="flex items-center gap-x-2">
                        <ui-icon name="info" class="text-gray-400 shrink-0"></ui-icon>
                        <div class="text-sm leading-tight text-gray-400">
                            The notification will be sent to the email address configured in the recipient's account settings. You can also <a @click.prevent="showEmailExtraRecipients = true" href="#" class="underline">add extra recipients</a> outside your organization.
                        </div>
                    </div>

                    <div v-if="showEmailExtraRecipients || inputs.delivery[option.id].extraRecipients">
                        <label for="extraRecipients" class="block text-sm text-gray-300 font-medium mb-1">
                            Additional Recipients
                        </label>

                        <div>
                            <ui-input type="text" name="extraRecipients" id="extraRecipients" placeholder="(optional)" size="sm" v-model="inputs.delivery[option.id].extraRecipients"></ui-input>
                            <div class="mt-1 text-xs text-gray-400">
                                You can specify up to 3 additional email addresses separated by commas.
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="option.id == 'sms'" class="mt-2 space-y-2.5" @click.stop>
                    <div class="flex items-center gap-x-2">
                        <ui-icon name="info" class="text-gray-400 shrink-0"></ui-icon>
                        <div class="text-sm leading-tight text-gray-400">
                            The notification will be sent to the phone number configured in the recipient's account settings. You can also <a @click.prevent="showSmsExtraRecipients = true" href="#" class="underline">add extra recipients</a> outside your organization.
                        </div>
                    </div>

                    <div v-if="showSmsExtraRecipients || inputs.delivery[option.id].extraRecipients">
                        <label for="extraRecipients" class="block text-sm text-gray-300 font-medium mb-1">
                            Additional Recipients
                        </label>

                        <div>
                            <ui-input type="text" name="extraRecipients" id="extraRecipients" placeholder="(optional)" size="sm" v-model="inputs.delivery[option.id].extraRecipients"></ui-input>
                            <div class="mt-1 text-xs text-gray-400">
                                You can specify up to 3 additional phone numbers separated by commas.
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="option.id == 'slack'" class="mt-2" @click.stop>
                    <label for="slack-oauthToken" class="block text-sm font-medium text-gray-300">
                        OAuth Token
                    </label>

                    <ui-input id="slack-oauthToken" size="sm" v-model="inputs.delivery[option.id].oauthToken"></ui-input>

                    <div class="flex space-x-3 mt-1 pb-1.5">
                        <div class="flex-1">
                            <label for="slack-channel" class="block text-sm font-medium text-gray-300">
                                Channel
                            </label>

                            <ui-input id="slack-channel" size="sm" v-model="inputs.delivery[option.id].channel"></ui-input>
                        </div>

                        <div class="flex-1">
                            <label for="slack-username" class="block text-sm font-medium text-gray-300">
                                Username
                            </label>

                            <ui-input id="slack-username" size="sm" placeholder="(optional)" v-model="inputs.delivery[option.id].username"></ui-input>
                        </div>
                    </div>
                </div>

                <div v-if="option.id == 'microsoft-teams'" class="mt-2" @click.stop>
                    <label for="microsoft-teams-webhookUrl" class="block text-sm font-medium text-gray-300">
                        Webhook URL
                    </label>

                    <ui-input id="microsoft-teams-webhookUrl" size="sm" v-model="inputs.delivery[option.id].webhookUrl"></ui-input>
                </div>

                <div v-if="option.id == 'mattermost'" class="mt-2" @click.stop>
                    <label for="mattermost-webhookUrl" class="block text-sm font-medium text-gray-300">
                        Webhook URL
                    </label>

                    <ui-input id="mattermost-webhookUrl" size="sm" v-model="inputs.delivery[option.id].webhookUrl"></ui-input>

                    <div class="flex space-x-3 mt-1 pb-1.5">
                        <div class="flex-1">
                            <label for="mattermost-channel" class="block text-sm font-medium text-gray-300">
                                Channel
                            </label>

                            <ui-input id="mattermost-channel" size="sm" placeholder="(optional)" v-model="inputs.delivery[option.id].channel"></ui-input>
                        </div>

                        <div class="flex-1">
                            <label for="mattermost-username" class="block text-sm font-medium text-gray-300">
                                Username
                            </label>

                            <ui-input id="mattermost-username" size="sm" placeholder="(optional)" v-model="inputs.delivery[option.id].username"></ui-input>
                        </div>
                    </div>
                </div>

                <div v-if="option.id == 'signal'" class="mt-2 space-y-2.5" @click.stop>
                    <div class="flex items-center gap-x-2">
                        <ui-icon name="info" class="text-gray-400 shrink-0"></ui-icon>
                        <div class="text-sm leading-tight text-gray-400">
                            The notification will be sent to the phone number configured in the recipient's account settings. You can also <a @click.prevent="showSignalExtraRecipients = true" href="#" class="underline">add extra recipients</a> outside your organization.
                        </div>
                    </div>

                    <div v-if="showSignalExtraRecipients || inputs.delivery[option.id].extraRecipients">
                        <label for="extraRecipients" class="block text-sm text-gray-300 font-medium mb-1">
                            Additional Recipients
                        </label>

                        <div>
                            <ui-input type="text" name="extraRecipients" id="extraRecipients" placeholder="(optional)" size="sm" v-model="inputs.delivery[option.id].extraRecipients"></ui-input>
                            <div class="mt-1 text-xs text-gray-400">
                                You can specify up to 3 additional phone numbers separated by commas.
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="option.id == 'webhook'" class="mt-2" @click.stop>
                    <div>
                        <label for="webhookUrl" class="block text-sm text-gray-300 font-medium mb-1">
                            Webhook URL
                        </label>

                        <div>
                            <ui-input type="text" id="webhookUrl" size="sm" v-model="inputs.delivery[option.id].webhookUrl"></ui-input>
                            <div class="mt-1 text-xs text-gray-400">
                                The URL where the webhook will be sent to.
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <div v-if="! showAllOptions" class="text-center pt-4">
        <a @click.prevent="showAllOptions = true" class="text-center text-gray-400 hover:text-gray-100 text-sm" href="#">
            Show more options
        </a>
    </div>
</template>

<script>
import useMyStore from '@/stores/me/my'

import { mapState } from 'pinia'

export default {
    props: [ 'store' ],

    data: () => ({
        showAllOptions: false,
        showEmailExtraRecipients: false,
        showSmsExtraRecipients: false,
        showSignalExtraRecipients: false
    }),

    computed: {
        ...mapState(useMyStore, [ 'currentWorkspace' ]),

        inputs() { return this.store.inputs },

        shownOptions() {
            return this.showAllOptions ? this.store.deliveryOptions : this.store.deliveryOptions.filter(option => ! option.hidden)
        }
    }
}
</script>
