<template>
    <div class="max-h-screen absolute top-0 right-0 w-full max-w-md flex flex-col py-4 pl-6 pr-4 z-50" :class="{ 'pointer-events-none': ! toasts.length }" v-show="isShown" ref="toaster">
        <div class="h-10 w-full flex items-start justify-center absolute top-4" v-if="toasts.length > 2">
            <a href="#" @click.prevent="dismissAllToasts" class="flex items-center justify-center bg-gray-200 text-gray-600 rounded-xl py-1 px-2.5 leading-0 font-medium text-sm">
                <ui-icon name="x" class="mr-1"></ui-icon>
                <span>Dismiss all notifications</span>
            </a>
        </div>

        <transition-group
            tag="div"
            class="flex flex-col items-end overflow-y-auto pb-10" :class="{ 'pt-10': toasts.length > 2, 'pb-10': ! isScrolledToTheBottom }"
            enter-active-class="transition ease-out duration-300"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            appear
            @scroll="handleScroll">
            <notification-card v-for="toast in toasts" :key="toast.id" :notification="toast"></notification-card>
        </transition-group>

        <div class="absolute bottom-4 flex items-end justify-center h-10 w-full" v-if="! isScrolledToTheBottom">
            <div class="flex items-center justify-center bg-gray-200 rounded-full h-8 w-8">
                <ui-icon name="arrow-down" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationCard from '@/components/me/notifications/partials/notification-card'

import useMyNotifications from '@/stores/me/notifications'
import useSupportChatStore from '@/stores/support/chat'

import { sanitizeHTML } from '@/helpers/html'

import { format } from 'date-fns'
import { mapActions, mapState } from 'pinia'

export default {
    components: { NotificationCard },

    data: () => ({
        isScrolledToTheBottom: true,
        currentDate: +(new Date)
    }),

    mounted() {
        this.isScrolledToTheBottom = this.$refs.toaster.scrollHeight < window.innerHeight

        setInterval(() => this.currentDate = +(new Date), 60000)
    },

    computed: {
        ...mapState(useMyNotifications, [ 'toasts' ]),

        isShown() {
            return ! useMyNotifications().isOverlayShown && ! useSupportChatStore().isShown
        }
    },

    methods: {
        ...mapActions(useMyNotifications, [ 'dismissToast', 'dismissAllToasts' ]),

        sanitizeHtml(html) {
            return sanitizeHTML(html)
        },

        handleScroll(el) {
            this.isScrolledToTheBottom = (el.target.offsetHeight + el.target.scrollTop + 40) >= el.target.scrollHeight
        },

        formatToastDate(date) {
            let diffInSeconds = ((new Date) - date) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(date, 'H:mm')
            } else {
                return format(date, 'LLL do')
            }
        }
    }
}
</script>
