<template>
    <div class="w-full max-h-[calc(100vh-95px)] pr-4 py-4" :class="{ 'max-w-xl': ! options.compact, 'max-w-md': options.compact }" v-if="isShown">
        <div class="h-full w-[550px] rounded-md bg-gradient-to-b shadow-xl from-gray-600/80 to-gray-700/80 backdrop-blur border border-gray-500/70 pointer-events-auto flex flex-col">
            <div class="flex flex-col w-full h-full items-center justify-center" v-if="isLoading">
                <div class="full">
                    <ui-spinner></ui-spinner>
                </div>
            </div>

            <event-content v-else-if="event"></event-content>
            <results-content v-else-if="events.data?.length"></results-content>

            <div class="flex flex-col h-full items-center justify-center text-gray-400" v-else>
                <ui-icon name="search" class="text-3xl"></ui-icon>
                <p class="mt-2 text-center">We didn't find any results.</p>
                <p class="mt-2 text-sm">Check your spelling and try again, or try a different set of filters.</p>
            </div>
        </div>
    </div>
</template>

<script>
import EventContent from './event'
import ResultsContent from './results'

import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    components: {
        EventContent, ResultsContent
    },

    computed: {
        ...mapState(useDetailsStore, [ 'event', 'events', 'isLoading', 'isShown', 'options' ])
    }
}
</script>
