<template>
    <div class="fixed relative w-full rounded w-full z-50 top-0 left-0" v-if="show">
        <div class="absolute w-full top-0 h-1 rounded shim bg-gerulata-green-700 overflow-hidden"></div>
    </div>
</template>

<script>

export default {
    props: {
        show: { type: Boolean, default: false }
    },

}
</script>
