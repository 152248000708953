<template>
    <div class="pl-1.5">
        <h1 class="text-2xl my-2">Event:</h1>
        <json-tree :data="event" :level="3"></json-tree>

        <h1 class="text-2xl my-2">Reports:</h1>
        <json-tree :data="reports.data" :level="3"></json-tree>

        <h1 class="text-2xl my-2">Channels:</h1>
        <json-tree :data="channels.data" :level="3"></json-tree>

        <h1 class="text-2xl my-2">Activity:</h1>
        <json-tree :data="activity.data" :level="3"></json-tree>

        <h1 class="text-2xl my-2">Metrics:</h1>
        <json-tree :data="metrics?.data" :level="3"></json-tree>
    </div>
</template>

<script>
import JsonTree from 'vue-json-tree'

import useDetailsStore from "@/stores/overview/details";

import { mapState } from 'pinia'

export default {
    components: {
        JsonTree
    },

    computed: {
        ...mapState(useDetailsStore, [ 'event', 'reports', 'activity', 'metrics', 'channels' ])
    }
}
</script>

<style>
.json-tree {
    @apply text-amber-500;
}

.json-tree-root {
    @apply bg-gray-600;
}

.json-tree-paired,
.json-tree-row:hover {
    @apply bg-gray-700;
}

.json-tree-value-string {
    @apply text-lime-300;
}

.json-tree-value-number {
    @apply text-cyan-500;
}
</style>
