<template>
    <div class="min-h-48 flex items-center justify-center pt-2">
        <settings-digest v-if="store.alert.type == 'events.digest'" :store="store"></settings-digest>
        <settings-spike-detection v-else-if="store.alert.type == 'events.spike-detection'" :store="store"></settings-spike-detection>

        <p v-else class="text-gray-400">
            This alert type does not have any additional settings.
        </p>
    </div>
</template>

<script>
import SettingsDigest from './edit-settings-digest'
import SettingsSpikeDetection from './edit-settings-spike-detection'

export default {
    components: {
        SettingsDigest, SettingsSpikeDetection
    }
}
</script>
