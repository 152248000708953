import { defineStore } from 'pinia'
import { markRaw } from 'vue'

import useManagementModeratedEventsOverlayStore from '@/stores/management/moderated-events/overlay'
import useMyNotificationsStore from '@/stores/me/notifications'
import useSupportChatStore from '@/stores/support/chat'

export const useAppStore = defineStore({
    id: 'app',

    state: () => ({
        sidebar: null,
        sidebarOptions: { compact: false, backdrop: true },
        
        sidebarStores: markRaw({
            'moderation': useManagementModeratedEventsOverlayStore,
            'notifications': useMyNotificationsStore,
            'support-chat': useSupportChatStore
        })
    }),
    
    getters: {
        sidebarStore: store => store.sidebarStores[store.sidebar]
    },

    actions: {
        toggleSidebar(sidebar) {
            this.sidebar == sidebar ? this.hideSidebar() : this.showSidebar(sidebar)
        },

        showSidebar(sidebar) {
            if (this.sidebar != sidebar) this.hideSidebar()

            this.sidebar = sidebar
            this.sidebarStore?.().open?.()
        },

        hideSidebar() {
            this.sidebarStore?.().close?.()
            this.sidebar = null
        },

        setSidebarOptions(options) {
            this.sidebarOptions = { ...this.sidebarOptions, ...options }
        }
    }
})

export default useAppStore
