<template>
    <div class="px-6 xl:px-16 max-w-7xl mx-auto my-8 pb-6 pl-24 xl:pl-32">
        <ui-async :guards="trigger" v-slot:default="{ isLoaded }">
            <div class="max-w-5xl mx-auto">
                <alert-trigger></alert-trigger>
            </div>
        </ui-async>
    </div>
</template>

<script>
import AlertTrigger from './trigger/trigger'

import useTriggerStore from '@/stores/alerts/trigger'

import { mapState } from 'pinia'

export default {
    components: {
        AlertTrigger
    },

    computed: {
        ...mapState(useTriggerStore, [ 'trigger' ])
    }
}
</script>
