<template>

    <div class="mx-auto w-full max-w-4xl bg-gray-600/80 backdrop-blur rounded-lg">
        <div class="border-b border-gray-700 px-4 py-5 sm:px-6">

            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-100">Custom Data Providers</h1>
                    <p class="mt-2 text-sm text-gray-300">Data providers are applications that stream data to Gerulata Sentinel for processing. To send data, the application needs a token for authorization. To learn more about custom data providers, <a class="text-gerulata-green-100" href="#">read the documentation.</a></p>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button type="button" class="block rounded-md bg-gerulata-green-100 px-3 py-2 text-center text-sm font-semibold text-gray-800 shadow-sm hover:bg-gerulata-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        New data provider...
                    </button>
                </div>
            </div>

            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-500 mb-6">
                            <thead>
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 sm:pl-0">Application Name</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Token</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Status</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Last Request At</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-600">
                            <tr v-for="person in people" :key="person.email">
                                <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    <div class="flex items-center">
                                        <div class="font-medium text-gray-100">{{ person.name }}</div>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <div class="text-gray-300 font-mono">{{ person.token }}</div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <span class="inline-flex items-center rounded-md bg-gerulata-green-100 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-green-600/20">Active</span>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-300">{{ person.lastContent }}</td>
                                <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300"
                                    >Edit<span class="sr-only">, {{ person.name }}</span></a
                                    >
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

// import UIIcon from "@/components/ui/icon.vue";

export default {
    // components: {UIIcon},
    data() {
        return {
            people: [
                {
                    name: 'Test Third-party Application',
                    token: 'x34_3sfkjf4i34ifuh4iu33sf9o0',
                    status: 'Active',
                    lastContent: 'Never',
                }
                // More people...
            ]
        }
    }
}
</script>