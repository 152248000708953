<template>
    <div class="w-full pointer-events-auto" v-if="toolbarIsShown">
        <div class="bg-gradient-to-b from-gray-600/80 to-gray-700/80 backdrop-blur border-b border-b-gray-500/70 px-3.5 py-2.5 shadow-xl">
            <div class="pb-2.5 px-3 text-center" v-if="perspective">
                <h1 class="text-gray-300 font-semibold">{{perspective.name}}</h1>
            </div>

            <div class="flex items-center space-x-3">
                <toolbar-search :store="overviewEventsStore"></toolbar-search>

                <ui-button color="green" class="rounded-md bg-blue-600 px-3 py-1.5" @click="savePerspective" v-if="saveButtonIsShown">Save</ui-button>

                <a href="#" @click.prevent="toggleDetails()">
                    <ui-icon name="sidebar" v-tooltip="'Toggle Sidebar'"></ui-icon>
                </a>
            </div>

            <div id="top-filters"></div>

            <div class="mt-2 flex items-center justify-between" v-if="publishedToken">
                <div class="flex flex-wrap items-center justify-center space-x-1.5 gap-y-1 pointer-events-none">
                    <date-filter :filters="publishedFilters"></date-filter>
                    <channels-filter :filters="publishedFilters"></channels-filter>
                    <categories-filter :filters="publishedFilters"></categories-filter>
                    <geo-filter :filters="publishedFilters"></geo-filter>
                    <semantic-filter :filters="publishedFilters"></semantic-filter>
                    <tags-filter :filters="publishedFilters"></tags-filter>
                    <language-filter :filters="publishedFilters"></language-filter>
                    <veracity-filter :filters="publishedFilters"></veracity-filter>
                </div>

                <a @click.prevent="filtersAreShown = true" href="#" class="text-sm text-gray-300 ml-3">
                    Add filters
                </a>
            </div>

            <div class="mt-2 flex items-center justify-between" v-if="filtersAreShown">
                <div class="flex flex-wrap items-center justify-center space-x-1.5 gap-y-1">
                    <date-filter :filters="filters"></date-filter>
                    <channels-filter :filters="filters"></channels-filter>
                    <categories-filter :filters="filters"></categories-filter>
                    <geo-filter :filters="filters"></geo-filter>
                    <semantic-filter :filters="filters"></semantic-filter>
                    <tags-filter :filters="filters"></tags-filter>
                    <language-filter :filters="filters"></language-filter>
                    <veracity-filter :filters="filters"></veracity-filter>
                </div>

                <a @click.prevent="clearAllFilters" href="#" class="text-sm text-gray-300 ml-3">
                    Reset filters
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ToolbarSearch from './search.vue'
import DateFilter from './filters/date'
import ChannelsFilter from './filters/channels'
import CategoriesFilter from './filters/categories'
import GeoFilter from './filters/geo'
import SemanticFilter from './filters/semantic'
import TagsFilter from './filters/tags'
import LanguageFilter from './filters/language'
import VeracityFilter from './filters/veracity'

import useStreamStore from '@/stores/overview/stream'
import useOverviewStore from '@/stores/overview/overview'

import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    components: {
        ToolbarSearch,
        DateFilter,
        ChannelsFilter,
        CategoriesFilter,
        GeoFilter,
        SemanticFilter,
        TagsFilter,
        LanguageFilter,
        VeracityFilter
    },

    computed: {
        ...mapStores(useStreamStore),
        ...mapState(useStreamStore, [ 'filters', 'perspective', 'publishedFilters', 'publishedToken', 'saveButtonIsShown', 'toolbarIsShown' ]),
        ...mapWritableState(useStreamStore, [ 'filtersAreShown' ])
    },

    methods: {
        ...mapActions(useStreamStore, [ 'clearFilters', 'savePerspective' ]),
        ...mapActions(useOverviewStore, [ 'toggleDetails', 'clearMap' ]),

        clearAllFilters() {
            this.clearFilters()
            this.clearMap()
        }
    }
}
</script>
