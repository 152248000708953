<template>
    <loading-bar :show="loading"></loading-bar>

    <div class="relative w-full">
        <div class="flex h-screen w-full">
            <div class="h-screen w-full bg-neutral-900" id="channel-map"></div>
        </div>

        <div class="absolute right-0 inset-0 z-40 pointer-events-none flex py-6 pl-16 w-full">
            <detail></detail>
        </div>

        <div class="absolute top-5 left-24">
            <search></search>
        </div>
    </div>
</template>

<script>

import 'mapbox-gl/dist/mapbox-gl.css'

import { mapState } from 'pinia'
import useChannelMapStore from '@/stores/channels/channel-map'
import LoadingBar from "@/components/overview/map/loading-bar.vue"
import Detail from "@/components/channels/detail.vue"
import Search from "@/components/channels/search.vue"


export default {
    components: { LoadingBar, Detail, Search },

    computed: {
        ...mapState(useChannelMapStore, [ 'loading' ]),
    },

    methods: {
        // selectChannel() {
        //     useQuickSearchStore().show({
        //         families: [ 'sentinelChannels' ],
        //         limit: 50,
        //         onSelect: async result => {
        //             await useChannelDetailStore().showChannelDetail(result.gid)
        //             useQuickSearchStore().hide()
        //         }
        //     })
        // },
    },

    async mounted() {
        await useChannelMapStore().initMap(this.$route.params?.channelId)
    }
}
</script>

<style>
/*canvas {*/
/*    cursor: auto !important; !* Prevent Deck.gl from overriding the cursor *!*/
/*}*/

</style>
