import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import mapboxgl from 'mapbox-gl'
import { Deck } from '@deck.gl/core'
import { MapboxLayer } from '@deck.gl/mapbox'
import { ArcLayer } from '@deck.gl/layers'
import useChannelDetailStore from "@/stores/channels/channel-detail"
import useMyQuickSearchStore from "@/stores/me/quick-search"
import useMyStore from "@/stores/me/my"
import api from '@/api'


export const useChannelMapStore = defineStore('channel-map', {
    state: () => ({
        map: null,
        deckInstance: null,
        channelsData: null,
        currentView: null,
        loading: false,

        searchResults: [],
        selectedMarker: null,
    }),

    actions: {
        async initMap(channelId = false) {
            this.map = markRaw(new mapboxgl.Map({
                accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
                container: 'channel-map',
                style: import.meta.env.VITE_MAPBOX_STYLE,
                center: [ 17.1077, 48.1486 ],
                zoom: 3,
                antialias: true,
                minZoom: 2,
                maxZoom: 18,
                bearing: 0,
                pitch: 45,
                projection: { name: 'mercator' },
            }))

            this.map.once('load', async () => {
                this.initDeckGl()
                await this.loadChannelsLocations()
                channelId && useChannelDetailStore().showChannelDetail(channelId)
            })

            this.map.on('mouseenter', 'sentinel-channel-locations', () => {
                this.map.getCanvas().style.cursor = 'pointer'
            })

            this.map.on('mouseleave', 'sentinel-channel-locations', () => {
                this.map.getCanvas().style.cursor = ''
            })

            this.map.on('click', 'sentinel-channel-locations', (e) => {
                let features = this.map.queryRenderedFeatures(e.point)

                if (features.length) {
                    const coordinates = features[0].geometry.coordinates
                    const point = features[0].properties.point
                    this.map.flyTo({ center: coordinates, offset: [-200, 0] })

                    if (point) {
                        useChannelDetailStore().loadChannelsByPoint(point)

                        this.map.setPaintProperty('sentinel-channel-locations', 'circle-color', [
                            'case',
                            ['==', ['get', 'point'], point],
                            '#FF0000',
                            '#49B0FF'
                        ]);
                    }
                }
            })
        },

        cancelMarkerHighlight() {
            this.map.setPaintProperty('sentinel-channel-locations', 'circle-color', '#49B0FF')
        },

        initDeckGl() {
            this.deckInstance = markRaw(new Deck({
                gl: this.map.painter.context.gl,
                layers: [],
                initialViewState: {
                    longitude: 17.1077,
                    latitude: 48.1486,
                    zoom: 3,
                    pitch: 45,
                    bearing: 0,
                },
                controller: true
            }))

            // TODO find better way to avoid deck cursor override
            this.deckInstance._updateCursor = () => {}

            if (!this.map.getLayer('deck-gl-layer')) {
                const deckLayer = new MapboxLayer({ id: 'deck-gl-layer', deck: this.deckInstance })
                this.map.addLayer(deckLayer)
            }
        },

        async loadChannelsLocations() {
            this.loading = true

            const channelsData = await api.route('sentinel channels locations', {}).get().json()
            this.channelsData = channelsData.data

            if (!this.map.getSource('sentinel-channels')) {
                this.map.addSource('sentinel-channels', { type: 'geojson', data: this.channelsData })
            }

            if (!this.map.getLayer('sentinel-channel-locations')) {
                this.map.addLayer({
                    id: 'sentinel-channel-locations',
                    type: 'circle',
                    source: 'sentinel-channels',
                    paint: {
                        'circle-radius': [
                            'interpolate',
                            ['linear'],
                            ['get', 'count'],
                            0, 3,
                            10, 6,
                            50, 12,
                            100, 20,
                        ],
                        'circle-color': '#49B0FF',
                    },
                })

                if (this.currentView === 'detail') {
                    this.map.setLayoutProperty('sentinel-channel-locations', 'visibility', 'none')
                }
            }

            this.loading = false
        },

        removeDeckLayers() {
            if (!this.map) return

            if (this.deckInstance) {
                this.deckInstance.setProps({ layers: [] })
            }
        },

        showChannelOnMap(channel) {
            this.removeDeckLayers()

            if (this.selectedMarker) {
                this.selectedMarker.remove()
                this.selectedMarker = null
            }

            if (this.map.getLayer('sentinel-channel-locations')) {
                this.map.setLayoutProperty('sentinel-channel-locations', 'visibility', 'none')
            }

            const arcs = channel.reportLocations.map(report => ({
                sourcePosition: [parseFloat(channel.lng), parseFloat(channel.lat)],
                targetPosition: [parseFloat(report.lng), parseFloat(report.lat)],
                count: report.count,
            }))

            const arcLayer = new ArcLayer({
                id: 'arc-layer',
                data: arcs,
                getSourcePosition: d => d.sourcePosition,
                getTargetPosition: d => d.targetPosition,
                getSourceColor: [73, 176, 255],
                getTargetColor: [6, 239, 85],
                getWidth: d => Math.sqrt(d.count),
            })

            this.deckInstance.setProps({ layers: [ arcLayer ] })
            this.map.flyTo({ center: [ channel.lng, channel.lat ], offset: [-200, 0] })

            if (!this.selectedMarker) {
                this.selectedMarker = new mapboxgl.Marker().setLngLat([ channel.lng, channel.lat ]).addTo(this.map)
            }
        },

        hideChannelDetailLayer() {
            this.map.setLayoutProperty('sentinel-channel-locations', 'visibility', 'visible')

            if (this.selectedMarker) {
                this.selectedMarker.remove()
                this.selectedMarker = null
            }

            this.removeDeckLayers()
        },

        async searchChannels(search) {
            if (search.length === 0) {
                this.searchResults = []
                return
            }

            if (search.length < 3) return

            const generalSearch = await api.url(`${import.meta.env.VITE_QUICK_SEARCH_URL}/multi_search`, true)
                .signal(new AbortController())
                .headers({ 'X-TYPESENSE-API-KEY': useMyStore().currentWorkspace.keys.quickSearchGeneral })
                .options({ credentials: 'same-origin' })
                .json({
                    searches: [{
                        collection: 'sentinelChannels',
                        q: search,
                        query_by: 'title,titleInternational',
                        sort_by: '_text_match:desc',
                        group_by: 'type',
                        per_page: 100,
                        group_limit: 3
                    }].filter(v => v)
                })
                .post()
                .json()

            this.searchResults = [
                ...useMyQuickSearchStore().familyResults(generalSearch, 'sentinelChannels').grouped_hits.flatMap(group => group.hits.map(result => ({ ...result.document, resultType: 'channel', family: 'sentinelChannels' }))),
            ]
        },

        reset() {
            this.channelsData = null
            this.loading = false
            this.searchResults = []
            this.cancelMarkerHighlight()
        }
    }
})

export default useChannelMapStore
