import useMyNotificationsStore from '@/stores/me/notifications'

import api from '@/api'
import { sortByDate } from '@/helpers/sorting'

import { defineStore } from 'pinia'

export const useSupportChatStore = defineStore({
    id: 'supportChat',

    state: () => ({
        isShown: false,
        isInitialized: false,
        isCreatingNewThread: false,
        isShowingAllThreads: false,
        isUploadingFile: false,
        isResolvingThread: false,
        isLoadingMessages: false,

        threads: [],
        thread: null,

        message: '',

        timeout: null,

        topics: [
            "I'm having an issue with content or search",
            "I'm having an issue with targets or monitoring",
            "I'm having an issue with analyses",
            "I'm having an issue with dashboards",
            "I'm having an issue with connections explorer",
            "I'm having an issue with my account",
            "I would like to suggest a new feature",
            "I'm getting an unexpected error",
            "Other issue"
        ]
    }),

    getters: {
        shownThreads: store => {
            return sortByDate(store.isShowingAllThreads ? store.threads : store.threads.filter(t => ! t.resolvedAt))
        }
    },

    actions: {
        async initialize() {
            if (this.isInitialized) return

            await this.load()
            this.poll()

            this.isInitialized = true
        },

        toggle() {
            this.isShown ? this.hide() : this.show()
        },

        open() {
            this.isShown = true
            this.isShowingAllThreads = false

            if (this.isInitialized && ! this.threads.length) this.newThread()

            this.poll()
        },

        close() {
            this.isShown = false
        },

        load() {
            return api.route('support chat threads')
                .get()
                .json(res => {
                    this.threads = res.data

                    if (! this.isShown && this?.threads.some(t => t.unseenAgentMessagesCount)) this.triggerNotification()
                })
        },

        newThread() {
            this.isCreatingNewThread = true
        },

        createThread(topic) {
            api.route('support chat threads store')
                .json({ name: topic })
                .post()
                .json(res => {
                    this.load().then(() => this.showThread(this.threads.find(t => t.id == res.data.id)))
                    this.message = ''
                })
        },

        showThread(thread) {
            this.thread = thread

            this.isLoadingMessages = true

            this.loadThreadMessages().then(() => this.isLoadingMessages = false)
        },

        loadThreadMessages() {
            return api.route('support chat threads details', { id: this.thread.id })
                .get()
                .json(res => {
                    if (! this.thread) return

                    this.thread.messages = res.data.messages

                    this.markThreadAsRead()
                })
        },

        resetThread() {
            this.thread = null

            this.isCreatingNewThread = false
            this.isLoadingMessages = false
            this.isResolvingThread = false
        },

        markThreadAsRead() {
            api.route('support chat threads mark-as-read', { id: this.thread.id })
                .post()
                .res()
        },

        sendMessage() {
            api.route('support chat threads send-message', { id: this.thread.id })
                .formData({
                    body: this.message,
                    currentUrl: window.location.href,
                    userAgent: window.navigator.userAgent
                })
                .post()
                .json(res => {
                    this.thread.messages.push(res.data)
                })

            this.message = ''
        },

        uploadFile($event) {
            this.isUploadingFile = true

            api.route('support chat threads send-message', { id: this.thread.id })
                .formData({
                    file: $event.target.files[0],
                    currentUrl: window.location.href,
                    userAgent: window.navigator.userAgent
                })
                .post()
                .json(res => {
                    this.thread.messages.push(res.data)

                    this.isUploadingFile = false
                })
        },

        resolveThread(rating) {
            api.route('support chat threads resolve', { id: this.thread.id })
                .json({ rating })
                .post()
                .res(() => {
                    this.resetThread()
                    this.load()
                })
        },

        triggerNotification() {
            useMyNotificationsStore().pushToast({
                uniqueId: 'new-support-message',
                type: 'info',
                title: 'New support message',
                text: 'You have a new support message.',
                action: 'Show',
                onAction: toast => {
                    this.toggle()
                }
            })
        },

        poll() {
            clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {
                this.load()

                if (this.thread) this.loadThreadMessages()

                this.poll()
            }, this.isShown ? 5000 : 2 * 60000)
        },

        showAllThreads() {
            this.isShowingAllThreads = true
        }
    }
})

export default useSupportChatStore
