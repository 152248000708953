<template>
    <div class="relative">
        <input :value="store.searchQuery" @input="event => store.search(event.target.value)" type="text" class="bg-transparent border border-gray-700 focus:border-gray-600 text-gray-300 focus:text-gray-100 rounded-md flex items-center justify-center text-sm h-8 px-8" placeholder="Search...">
        <div class="absolute top-0 left-0 h-8 flex items-center px-3 text-gray-300">
            <ui-icon name="search"></ui-icon>
        </div>
        <div v-if="store.searchQuery || store.filterable" class="absolute top-0 right-0 h-8 flex items-center gap-x-1.5 px-3 text-gray-300">
            <a v-if="store.searchQuery" href="#" @click.prevent="store.search()">
                <ui-icon name="x"></ui-icon>
            </a>
            <ui-dropdown v-if="store.filterable" width="w-auto">
                <template v-slot:trigger>
                    <a href="#">
                        <ui-icon name="filter" :class="{ 'text-gerulata-green-100': store.hasFilters }"></ui-icon>
                    </a>
                </template>

                <template v-slot:content>
                    <slot name="filter"></slot>
                </template>
            </ui-dropdown>
        </div>
    </div>
</template>
