<template>
    <div v-if="trigger" class="bg-gray-700 border border-gray-500/70 shadow-xl flex flex-col min-h-0 h-auto w-full rounded-xl">
        <div class="flex items-center px-6 py-6">
            <div class="flex-1 flex items-center gap-x-1.5">
                <ui-avatar :item="trigger.alert" class="w-5 h-5 shrink-0"></ui-avatar>

                <h1 class="font-medium">
                    {{trigger.alert.name}}
                </h1>

                <a @click.prevent="copyLink" class="flex items-center h-full text-gray-300 hover:text-gray-100 font-medium gap-x-1.5" :href="link" v-tooltip="'Copy link'">
                    <ui-icon name="link"></ui-icon>
                </a>
            </div>

            <a v-if="modal" href="#" class="text-xl text-gray-300 hover:text-gray-100 leading-none" @click.prevent="$modal.hide('alert-trigger')">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>

        <div class="flex-1 min-h-0 overflow-y-auto rounded-b-xl pb-16 px-16">
            <trigger-digest v-if="trigger.alert.type == 'events.digest'"></trigger-digest>
            <trigger-every-match v-if="trigger.alert.type == 'events.every-match'"></trigger-every-match>
            <trigger-spike-detection v-if="trigger.alert.type == 'events.spike-detection'"></trigger-spike-detection>
        </div>
    </div>
</template>

<script>
import TriggerDigest from '@/components/alerts/trigger/digest'
import TriggerEveryMatch from '@/components/alerts/trigger/every-match'
import TriggerSpikeDetection from '@/components/alerts/trigger/spike-detection'

import useAlertsTriggerStore from '@/stores/alerts/trigger'

import { mapState } from 'pinia'

export default {
    components: {
        TriggerDigest, TriggerEveryMatch, TriggerSpikeDetection
    },

    computed: {
        ...mapState(useAlertsTriggerStore, [ 'trigger', 'modal' ]),

        link() {
            return this.$router.resolve({ name: 'alerts.trigger', params: { alertId: this.trigger.alert.id, id: this.trigger.id } }).href
        }
    },

    methods: {
        copyLink() {
            navigator.clipboard.writeText(window.location.origin + this.link)
        }
    }
}
</script>
