import clone from 'just-clone'
import filterValues from 'just-filter'
import mapValues from 'just-map-values'

export class SearchFilters {
    constructor(options = {}) {
        this.values = options.values || {}
        this.defaults = options.defaults || {}
        this.readOnly = options.readOnly || {}
        this.onChange = options.onChange || (() => {})
    }

    value(filter) {
        return this.values[filter]
    }

    isEmpty(includingReadOnly = true) {
        return includingReadOnly
            ? ! Object.keys(this.values).length
            : Object.keys(this.values).length == Object.keys(this.readOnly).length
    }

    isNotEmpty(includingReadOnly = true) {
        return ! this.isEmpty(includingReadOnly)
    }

    isReadOnly(filter) {
        return this.readOnly[filter]
    }

    set(filter, value, readOnly) {
        if (! (filter instanceof Object)) {
            return this.set({ [filter]: value }, readOnly)
        }

        this.values = { ...this.values, ...filter }
        this.readOnly[filter] = value

        this.values = filterValues(this.values, (key, value) => value !== undefined)
        this.readOnly = filterValues(this.readOnly, (key, value) => value !== undefined)

        this.onChange('set', { filter, value })

        return this
    }

    setDefaults(defaults) {
        this.set(this.defaults = defaults)

        return this
    }

    remove(filter) {
        if (! (filter instanceof Array)) {
            return this.remove([ filter ])
        }

        if (filter.every(f => this.values[f] === undefined)) return

        this.set(Object.fromEntries(filter.map(filter => [ filter, undefined ])))

        return this
    }

    clear() {
        this.values = clone(this.defaults)
        this.readOnly = []

        this.onChange('clear', {})

        return this
    }

    toPerspective() {
        return mapValues(this.values, (value, filter) => this.serializeFor('api', filter, value))
    }

    toJson() {
        return JSON.stringify(this.toPerspective())
    }

    fromPerspective(filters) {
        Object.entries(filters).forEach(([ filter, value ]) => {
            if (! value) return

            this.set(filter, this.unserializeFrom('api', filter, value), this.constructor.definitions[filter]?.readOnly)
        })

        return this
    }

    toQuery() {
        return Object.fromEntries(
            Object.entries(this.values).map(([filter, value]) => [ `filter[${filter}]`, this.serializeFor('uri', filter, value) ])
        )
    }

    fromQuery(query) {
        Object.entries(query).forEach(([ key, value ]) => {
            let matches = key.match(/^filter\[(.*?)\]$/)

            if (! matches || ! value) return

            this.set(matches[1], this.unserializeFrom('uri', matches[1], value))
        })

        return this
    }

    serializeFor(target, filter, value) {
        let definition = this.constructor.definitions[filter]
        let serializeMethod = target == 'api' ? 'apiSerialize' : 'uriSerialize'

        let serializeFunction = definition?.[serializeMethod] || definition?.['serialize'] || (v => v)

        let serializeValue = target == 'uri'
                ? (v => serializeFunction(v, this).toString())
                : (v => serializeFunction(v, this))
        let serializeCollection = target == 'uri'
                ? (c => c.map(v => serializeValue(v).replace(/;/g, '')).join(';'))
                : (c => c.map(v => serializeValue(v)))

        return definition?.multipleValues ? serializeCollection(value) : serializeValue(value, this)
    }

    unserializeFrom(target, filter, value) {
        let definition = this.constructor.definitions[filter]
        let unserializeMethod = target == 'api' ? 'apiUnserialize' : 'uriUnserialize'

        let unserializeValue = definition?.[unserializeMethod] || definition?.['unserialize'] || (v => v)
        let unserializeCollection = target == 'uri'
                ? (c => c.split(';').map(v => unserializeValue(v, this)))
                : (c => c.map(v => unserializeValue(v, this)))

        return definition?.multipleValues ? unserializeCollection(value).filter(v => v) : unserializeValue(value, this)
    }

    clone(options = {}) {
        return new SearchFilters({
            values: options.values ?? this.values, readOnly: options.readOnly ?? this.readOnly, onChange: options.onChange ?? this.onChange
        })
    }

    static definitions = {
        'category': {
            multipleValues: true
        },
        'date': {
            apiSerialize: value => value.type == 'past' || value.type == 'reported' || value.type == 'upcoming'
                    ? value
                    : { type: value.type, date: { gte: value.date.gte instanceof Date ? value.date.gte.toISOString() : '', lte: value.date.lte instanceof Date ? value.date.lte.toISOString() : '' } },
            apiUnserialize: value => value.type == 'past' || value.type == 'reported' || value.type == 'upcoming'
                    ? value
                    : { type: value.type, date: { gte: value.date.gte ? new Date(value.date.gte) : null, lte: value.date.lte ? new Date(value.date.lte) : null } },
            uriSerialize: value => value.type == 'past' || value.type == 'reported' || value.type == 'upcoming'
                    ? `${value.type}:${value.date[value.type]}|${value.date.unit ?? ''}`
                    : `${value.type}:${value.date.gte instanceof Date ? value.date.gte.toISOString() : ''}|${value.date.lte instanceof Date ? value.date.lte.toISOString() : ''}`,
            uriUnserialize: value => {
                let [ type, ...date ] = value.split(':')
                    date = date.join(':').split('|')
                    return type == 'past' || type == 'reported' || type == 'upcoming'
                        ? { type, date: { [type]: date[0], unit: date[1] ?? null } }
                        : { type, date: { gte: date[0] ? new Date(date[0]) : null, lte: date[1] ? new Date(date[1]) : null } }
            }
        },
        'geoFence': {
            uriSerialize: value => `${value.id}|${value.include?.join(',') || ''}|${value.exclude?.join(',') || ''}`,
            uriUnserialize: value => {
                let [ id, include, exclude ] = value.split('|')
                return { id, include: include.split(',').filter(Boolean), exclude: exclude.split(',').filter(Boolean) }
            },
            multipleValues: true
        },
        'language': {
            multipleValues: true
        },
        'semantic': {
            uriSerialize: value => `${value.query}|${value.sensitivity}`,
            uriUnserialize: value => {
                let tokens = value.split('|')
                return { query: tokens.slice(0, -1).join('|'), sensitivity: tokens[tokens.length - 1] }
            }
        },
        'tags': {
            uriSerialize: value => value.mode + '|' + value.tags.join(','),
            uriUnserialize: value => {
                let tokens = value.split('|')
                return { mode: tokens[0], tags: tokens[1].split(',') }
            }
        },
        'channels': {
            uriSerialize: value => `${value.exclude ? '-' : ''}${value.type}|${value.id}|${value.name}`,
            uriUnserialize: value => {
                let [ type, id, name ] = value.split('|')
                let exclude = false

                if (type[0] == '-') {
                    type = type.substring(1)
                    exclude = true
                }

                return { type, id, name, exclude }
            },
            multipleValues: true
        },
        'channelCount': {
            uriSerialize: value => `${value.gte}`,
            uriUnserialize: (value, filter) => {
                return { gte: value }
            }
        },
        'channelType': {
            multipleValues: true
        },
        'text': {
            uriSerialize: value => `${value.query}|${value.language}|${value.types ? value.types.join(',') : ''}`,
            uriUnserialize: value => {
                let tokens = value.split('|')
                return {
                    query: tokens.slice(0, -2).join('|'),
                    language: tokens[tokens.length - 2],
                    types: tokens[tokens.length - 1].split(',')
                }
            }
        }
    }
}

export const searchFilters = options => new SearchFilters(options)

export default searchFilters
