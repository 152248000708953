import useEventCategoriesStore from '@/stores/me/event-categories'
import useMarkerStore from "@/stores/overview/marker"
import usePresentationStore from "@/stores/overview/presentation"
import useMeasurementStore from "@/stores/overview/measurements"
import mapStyles from './map-styles'
import bbox from '@turf/bbox'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from 'maplibre-gl-draw-circle'
import { defineStore } from 'pinia'
import { markRaw } from 'vue'

const mapThemes = {
    'default': import.meta.env.VITE_MAPBOX_STYLE,
    'blue': "mapbox://styles/blackthorn42/clhthxie3024x01pg07vk4ref",
    'satellite': 'mapbox://styles/blackthorn42/cjvrvf3qa0m4k1cm735jfratn'
}

const ReadOnlySimpleSelectMode = {
    ...MapboxDraw.modes.simple_select,

    onClick(state, e) {
        return
    }
}

export const useMapStore = defineStore({
    id: 'map',

    state: () => ({
        map: null,
        layers: [],

        events: [],

        defaultSettings: {},

        zoom: 10,

        drawControl: null,
        activeMarkerId: null,

        isCreatingShape: false,
        createdShapeProperties: {},

        geoLayers: [],

        mapStyle: 'default',

        overviewStore: null
    }),

    getters: {
        geoJSONData(store) {
            return {
                'type': 'FeatureCollection',
                'features': (store.events || []).map(event => {

                    const coords = event.location.centerPoint.replace("POINT(", "").replace(")", "").split(" ")

                    return {
                        'type': 'Feature',
                        'properties': {
                            'id': event.id,
                            'category': event.category,
                            'area': event.location.boundingRectangleArea,
                            'isNew': !!event.isNew,
                        },
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [coords[0], coords[1]]
                        },
                    }
                })
            }
        },
    },

    actions: {
        update(events) {
            this.events = events

            if (this.map?.getSource('events')) {
                this.map.getSource('events').setData(this.geoJSONData)
                useMarkerStore().updateClusters(this.map)
            }

            const newEvents = events ? events.filter(e => e.isNew) : []

            if (newEvents.length) {
                useMarkerStore().setPulsingLayersVisibility(this.map, true)

                setTimeout(() => {
                    newEvents.forEach(e => {
                        const newEvent = this.events.find(event => event.id === e.id)

                        if (newEvent) {
                            newEvent.isNew = false
                        }
                    })

                    if (this.map?.getSource('events')) {
                        this.map.getSource('events').setData(this.geoJSONData)
                    }

                    useMarkerStore().setPulsingLayersVisibility(this.map, false)
                }, 15 * 1000)
            } else {
                useMarkerStore().setPulsingLayersVisibility(this.map, false)
            }
        },

        createMap(settings = {}) {
            this.defaultSettings = settings

            this.map = markRaw(new mapboxgl.Map({
                accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
                container: settings.container ?? 'map',
                style: mapThemes[this.mapStyle],
                center: settings.center ?? [17.1077, 48.1486],
                zoom: settings.zoom ?? 10,
                minZoom: settings.minZoom ?? 3,
                maxZoom: settings.maxZoom ?? 18,
                bearing: settings.bearing ?? 0,
                pitch: settings.pitch ?? 45,
                projection: {
                    name: 'mercator'
                }
            }))

            this.map.on('style.load', () => {
                if (!this.drawControl) {
                    this.createMapDraw()
                }

                this.createMapMarkerLayers()
                useMarkerStore().updateClusters(this.map)
            })

            this.map.on('zoom', () => {
                this.zoom = this.map.getZoom()
                useMarkerStore().updateClusters(this.map)
            })
        },

        async createMapMarkerLayers() {
            await useEventCategoriesStore().initialize()

            if (!this.map.getSource('events')) {
                this.map.addSource('events', {
                    type: 'geojson',
                    data: this.geoJSONData,
                    cluster: true,
                    generateId: true,
                    clusterMaxZoom: 25,
                    clusterRadius: 80,
                    clusterProperties: useMarkerStore().clusterProperties
                })
            }

            useMarkerStore().createPulseAnimationLayers(this.map)
            useMarkerStore().createClusterLayers(this.map)
            useMarkerStore().createEventLayers(this.map)
            useMeasurementStore().createMeasurementLayers(this.map)

            this.map.on('click', (e) => useMarkerStore().clusterClick(e, this.map))
            this.map.on('click', 'unclustered-point-circle', (e) => useMarkerStore().markerClick(e, this.map))

            this.map.on('mouseenter', 'unclustered-point-circle', () => {
                this.map.getCanvas().style.cursor = 'pointer'
            })

            this.map.on('mouseleave', 'unclustered-point-circle', () => {
                this.map.getCanvas().style.cursor = ''
            })

            this.map.on('draw.create', event => {
                Object.entries(this.createdShapeProperties).forEach(([ key, value ]) => {
                    this.drawControl.setFeatureProperty(event.features[0].id, key, value)
                })
            })

            this.map.on('draw.selectionchange', event => {
                this.overviewStore().setSelectedMapFeature(event.features[0])
            })

            this.map.once('idle', () => useMarkerStore().updateClusters(this.map))
            this.map.on('dragend', () => useMarkerStore().updateClusters(this.map))
            this.map.on('click', e => useMeasurementStore().measure(e, this.map))
            this.map.on('mousemove', e => useMeasurementStore().measurementCursors(e, this.map))

            useMarkerStore().loadMarkerIcons(this.map)
        },

        togglePresentationMode() {
            usePresentationStore().togglePresentation(this.map)
        },

        toggleMeasurementMode() {
            useMeasurementStore().toggleMeasurementMode(this.map)
        },

        setActiveMarker(id) {
            useMarkerStore().setActiveMarker(id, this.map)
        },

        setActiveMarkerByEventId(eventId) {
            const feature = this.eventToFeature(eventId)

            if (feature) {
                useMarkerStore().setActiveMarker(feature.id, this.map)
            }
        },

        createMapDraw() {
            this.drawControl = markRaw(new MapboxDraw({
                displayControlsDefault: false,
                userProperties: true,
                modes: {
                    ...MapboxDraw.modes,
                    draw_circle  : CircleMode,
                    drag_circle  : DragCircleMode,
                    direct_select: DirectMode,
                    // simple_select: SimpleSelectMode,
                    simple_select: ReadOnlySimpleSelectMode
                },
                styles: mapStyles
            }))

            this.map.addControl(this.drawControl)
        },

        draw(feature, focus = false) {
            this.drawControl.add(feature)

            if (focus) this.map.fitBounds(bbox(feature.geometry), { padding: 50 })
        },

        drawAndFocus(feature) {
            return this.draw(feature, true)
        },

        drawPolygon(properties = {}) {
            this.setMapDrawMode('draw_polygon')
            this.createdShapeProperties = properties
        },

        drawCircle(properties = {}) {
            this.setMapDrawMode('drag_circle')
            this.createdShapeProperties = properties
        },

        drawPoint(properties = {}) {
            this.setMapDrawMode('draw_point')
            this.createdShapeProperties = properties
        },

        drawCollection(features, layerId) {
            this.eraseCollection(layerId)
            this.geoLayers[layerId] = this.drawControl.add(features)
        },

        stopDrawing() {
            this.setMapDrawMode('simple_select')
        },

        eraseCollection(layerId) {
            this.drawControl.delete(this.geoLayers[layerId])
        },

        focus(geojson) {
            this.map.fitBounds(bbox(geojson), { padding: 50 })
        },

        focusEvent(event, offset) {
            const coords = event.location.centerPoint.replace("POINT(", "").replace(")", "").split(" ")
            this.focusPoint([coords[0], coords[1]], offset)

            if (this.map.getSource('events') && this.map.isSourceLoaded('events')) {
                this.setActiveMarkerByEventId(event.id)
            } else {
                // if events are not loaded yet, wait 100ms and try again
                setTimeout(() => { this.setActiveMarkerByEventId(event.id) }, 100)
            }
        },

        focusPoint(point, offset = [ 0, 0 ]) {
            this.map.flyTo({ center: point, offset })
        },

        erase(featureId) {
            this.drawControl.delete(featureId)
        },

        eraseAll() {
            this.drawControl?.deleteAll()
        },

        eraseLatest() {
            let shapes = this.drawControl.getAll().features
            this.drawControl.delete(shapes[shapes.length - 1].id)
        },

        hasFeature(featureId) {
            return this.drawControl.getAll().features.find(f => f.properties.id == featureId)
        },

        setMapDrawMode(mode, callback) {
            this.drawControl.changeMode(mode)
        },

        removeMapDrawFeature() {
            this.drawControl.trash()
        },

        getCenter() {
            return this.map.getCenter()
        },

        setCenter(center) {
            if (! center) return

            this.map.flyTo({ center })
        },

        getZoom() {
            return this.map.getZoom()
        },

        zoomIn() {
            this.map.getZoom() < this.map.getMaxZoom() ? this.map.zoomIn() : null
        },

        zoomOut() {
            this.map.getZoom() > this.map.getMinZoom() ? this.map.zoomOut() : null
        },

        setZoom(zoom) {
            if (!zoom || zoom < this.map.getMinZoom() || zoom > this.map.getMaxZoom()) { return }

            this.map.setZoom(zoom)
        },

        resetPitch() {
            this.map.setPitch(this.defaultSettings.pitch ?? 45)
            this.map.resetNorth()
        },

        fitBounds(padding = 50, pitch = 45) {
            if (! this.geoJSONData.features.length) return

            const bounds = [[
                this.geoJSONData.features.map(f => f.geometry.coordinates[0]).reduce((a, b) => Math.min(a, b)),
                this.geoJSONData.features.map(f => f.geometry.coordinates[1]).reduce((a, b) => Math.min(a, b)),
                ], [
                this.geoJSONData.features.map(f => f.geometry.coordinates[0]).reduce((a, b) => Math.max(a, b)),
                this.geoJSONData.features.map(f => f.geometry.coordinates[1]).reduce((a, b) => Math.max(a, b))
            ]]

            this.map.fitBounds(bounds, {
                padding,
                pitch
            })
        },

        featuresToEvents(features) {
            return features.map(f => this.events.find(e => e.id == f.properties.id)).filter(Boolean)
        },

        eventToFeature(eventId) {
            const features = this.map.querySourceFeatures('events').filter(event => event.properties.id == eventId)
            return features.length ? features[0] : null
        },

        setMapStyle(theme) {
            if (!this.map || !theme || !mapThemes[theme]) return

            if (theme !== this.mapStyle) {
                this.mapStyle = theme
                // TODO timeout is used for postponed style loading - because of saved style in perspective and initial loading
                setTimeout(() => { this.map.setStyle(mapThemes[theme]) }, 350)
            }
        }
    }
})

export default useMapStore
