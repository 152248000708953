<template>
    <div class="space-y-4">
        <div>
            <label for="metric" class="block text-sm text-gray-300 font-medium mb-1">
                Metric
            </label>

            <div>
                <ui-select id="metric" :options="metric" v-model="store.inputs.settings.metric" form></ui-select>
                <div class="mt-1 text-xs text-gray-400">
                    Select the metric, where you want to look for spikes.
                </div>
            </div>
        </div>

        <div>
            <label for="frequency" class="block text-sm text-gray-300 font-medium mb-1">
                Frequency
            </label>

            <div>
                <ui-select id="frequency" :options="frequency" v-model="store.inputs.settings.frequency" form></ui-select>
                <div class="mt-1 text-xs text-gray-400">
                    You can choose to check for spikes every hour or once a day.
                </div>
                <div class="mt-1 text-xs text-gray-400">
                    If you choose hourly, we will always check the last hour of content, compared with previous 12 hours.
                </div>
                <div class="mt-1 text-xs text-gray-400">
                    If you choose daily, we will always check the last day of content, compared with previous 7 days.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        frequency: [
            { id: 'hourly', name: 'Hourly' },
            { id: 'daily', name: 'Daily' }
        ],

        metric: [
            { id: 'results', name: 'Results Count' },
            { id: 'interactions', name: 'Interactions' },
            { id: 'views', name: 'Views' }
        ]
    })
}
</script>