<template>
    <a @click.prevent="$my.switchWorkspace(workspace)" class="block hover:bg-gray-600 hover:ring-1 hover:ring-gray-600 ring-inset" href="#">
        <div class="flex items-center relative p-4 pl-5 pb-3">
            <div class="shrink-0 relative">
                <ui-avatar :item="workspace" :icon="avatarIcon" class="w-8 h-8"></ui-avatar>
            </div>

            <div class="flex-1 min-w-0 px-4">
                <h1 class="font-medium truncate leading-tight text-gray-200" v-tooltip="workspace.name">
                    {{workspace.name}}
                </h1>
                <div class="text-gray-300 text-xs truncate" v-tooltip="workspace.description">
                    {{workspace.description}}
                </div>
            </div>

            <div v-if="workspace.type == 'default'" class="flex items-center space-x-1">
                <div class="flex -space-x-1 relative z-0 overflow-hidden">
                    <ui-avatar :item="user" class="relative inline-block h-5 w-5 rounded-full ring-1 ring-gray-800" :class="`z-${50 - index * 10}`" v-tooltip="user.name" :key="user.id" v-for="user, index in users.slice(0, 6)"></ui-avatar>
                </div>
                <div class="font-medium text-xs text-gray-300" v-tooltip="additionalUsersTooltip" v-if="users.length > 6">
                    +{{users.length - 6}}
                </div>
            </div>

            <div class="flex items-center ml-5">
                <a v-if="workspace.type == 'global'" class="text-gerulata-green-100 hover:text-gerulata-green-300 text-2xl cursor-default" v-tooltip="'Everyone in the organization has acceess to this workspace.'">
                    <ui-icon name="help-circle"></ui-icon>
                </a>
                <a v-else-if="workspace.type == 'personal'" class="text-gerulata-green-100 hover:text-gerulata-green-300 text-2xl cursor-default" v-tooltip="'Only you have access to this workspace.'">
                    <ui-icon name="help-circle"></ui-icon>
                </a>
                <a v-else-if="! workspace.owned" class="text-gerulata-green-500 text-xl cursor-default" v-tooltip="`Owned by ${workspace.owner.name}`" @click.prevent.stop href="#">
                    <ui-icon name="settings"></ui-icon>
                </a>
                <a v-else class="text-gerulata-green-100 hover:text-gerulata-green-300 text-xl" v-tooltip="'Settings'" @click.prevent.stop="editWorkspace(workspace)" href="#">
                    <ui-icon name="settings"></ui-icon>
                </a>
            </div>
        </div>
    </a>
</template>

<script>
import useWorkspaceInfoStore from '@/stores/me/modals/workspace-info'

import { sortAlphabetically } from '@/helpers/sorting'

import { mapActions } from 'pinia'

export default {
    props: [ 'workspace' ],
    
    computed: {
        avatarIcon() {
            if (this.workspace.type == 'global') return 'globe-simple'
            if (this.workspace.type == 'personal') return 'user'
            return undefined
        },
        
        users() {
            return sortAlphabetically(this.workspace.users)
        },
        
        additionalUsersTooltip() {
            return this.users.slice(6).map(user => user.name).join(', ')
        }
    },

    methods: {
        ...mapActions(useWorkspaceInfoStore, { editWorkspace: 'open' })
    }
}
</script>
