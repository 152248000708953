<template>
    <div class="flex w-full h-full justify-end pr-4 relative">
        <div class="flex flex-col items-center rounded-md space-y-3 pointer-events-auto absolute bottom-4">
            <div v-if="!presentationMode" class="flex flex-col items-center space-y-2 w-9 shadow-md bg-gray-950 text-2xl px-3 py-1.5 rounded-3xl">
                <a href="#" v-tooltip="'Zoom In'" title="Zoom In" @click.prevent="zoomIn">
                    <ui-icon name="zoom-in" class="text-gray-300"></ui-icon>
                </a>

                <ui-range direction="btt" tooltip="none" @change="z => setZoom(z)" v-model="internalZoom" :height="75" :min="map?.getMinZoom() ?? 3" :max="map?.getMaxZoom() ?? 18"></ui-range>

                <a href="#" v-tooltip="'Zoom Out'" title="Zoom Out" @click.prevent="zoomOut">
                    <ui-icon name="zoom-out" class="text-gray-300"></ui-icon>
                </a>
            </div>

            <div v-if="!presentationMode" class="flex flex-col items-center space-y-2 w-9 shadow-md bg-gray-950 text-2xl px-3 py-1.5 rounded-3xl">
                <a href="#" v-tooltip="'Reset Pitch'" title="Reset Pitch" @click.prevent="resetPitch">
                    <ui-icon name="tilt" class="text-gray-300"></ui-icon>
                </a>

                <a href="#" v-tooltip="'Fit Bounds'" title="Fit Bounds" @click.prevent="fitBounds">
                    <ui-icon name="fullscreen" class="text-gray-300"></ui-icon>
                </a>
            </div>

<!--            <div v-if="!presentationMode || !fullPresentationMode()" class="flex items-center justify-center h-10 w-9 shadow-md bg-gray-950 text-1xl p-1.5 rounded-full">-->
<!--                <a href="#" v-tooltip.left="presentationMode ? 'Stop presentation' : 'Start presentation'" :title="presentationMode ? 'Stop presentation' : 'Start presentation'" @click.prevent="togglePresentationMode">-->
<!--                    <ui-icon v-if="!presentationMode" name="play-solid" class="text-gray-300"></ui-icon>-->
<!--                    <ui-icon v-if="presentationMode" name="x" class="text-gray-300"></ui-icon>-->
<!--                </a>-->
<!--            </div>-->

            <div v-if="presentationMode && !fullPresentationMode()" class="flex items-center justify-center h-10 w-9 shadow-md bg-gray-950 text-1xl p-1.5 rounded-full">
                <a href="#" v-tooltip="'Share presentation'" title="Share presentation mode" @click.prevent="shareMapPresentation">
                    <ui-icon name="globe" class="text-gray-300"></ui-icon>
                </a>
            </div>

            <div v-if="!presentationMode" class="flex flex-col items-center space-y-2 w-9 shadow-md bg-gray-950 text-2xl px-3 py-1.5 rounded-3xl" :class="measurementMode ? 'bg-lime-900' : ''">
                <a href="#" v-tooltip.left="'Measurements'" title="Measurements" @click.prevent="toggleMeasurementMode">
                  <ui-icon name="ruler" class="text-gray-300"></ui-icon>
                </a>
            </div>

            <div v-if="!presentationMode" class="flex flex-col items-center space-y-2 w-9 shadow-md bg-gray-950 text-2xl px-3 py-1.5 rounded-3xl">
                <a href="#" v-tooltip.left="'Map Layers'" title="Map Layers" @click.prevent="() => { showStyleComponent = !showStyleComponent }">
                    <ui-icon name="layers" class="text-gray-300"></ui-icon>
                </a>
            </div>
<!--             bg-gradient-to-b from-gray-600/80 to-gray-700/80 backdrop-blur border border-gray-500/70 px-3.5 py-2.5 shadow-xl-->
            <div v-if="measurementMode" class="bg-gradient-to-b from-gray-600/80 to-gray-700/80 backdrop-blur shadow-xl fixed left-20 bottom-5 p-2 rounded-md border-2 border-lime-300 text-center">
                <div class="text-white text-2xl">Measured distance: {{ measuredDistance }} km</div>
                <div class="text-gray-300 text-sm">Press ESC to quit measurements mode</div>
            </div>

            <div v-if="showStyleComponent" class="absolute flex-row bottom-0 right-10 bg-gray-950 px-2.5 py-2 w-14 rounded-md">
                <ui-icon name="x" title="Close" class="text-gray-300 absolute top-0 left-0 bg-gray-950 -ml-1.5 -mt-1.5 cursor-pointer rounded-md" @click="() => { showStyleComponent = false }"></ui-icon>
                <img src="@/assets/map/satellite.png" v-tooltip.left="'Satellite view'" title="Satellite view" alt="Satellite view" :class="mapStyle !== 'satellite' ? 'opacity-75' : 'border border-gray-500'" class="w-10 h-10 cursor-pointer mb-2 rounded-md hover:opacity-100" @click="setMapStyle('satellite')">
                <img src="@/assets/map/dark.png" v-tooltip.left="'Default dark'" title="Default dark" alt="Default dark" :class="mapStyle !== 'default' ? 'opacity-75' : 'border border-gray-500'" class="w-10 h-10 cursor-pointer mb-2 rounded-md hover:opacity-100" @click="setMapStyle('default')">
                <img src="@/assets/map/blue.png" v-tooltip.left="'Blue terrain'" title="Blue terrain" alt="Blue terrain" :class="mapStyle !== 'blue' ? 'opacity-75' : 'border border-gray-500'" class="w-10 h-10 cursor-pointer rounded-md hover:opacity-100" @click="setMapStyle('blue')">
            </div>
        </div>
    </div>
</template>

<script>
import useMapStore from '@/stores/overview/map'
import usePresentationStore from "@/stores/overview/presentation"
import useMeasurementStore from "@/stores/overview/measurements"

import { mapActions, mapState } from 'pinia'


export default {
    data: () => ({
        internalZoom: 10,
        showStyleComponent: false
    }),

    mounted() {
        this.internalZoom = this.zoom

        // Quit measurement mode on ESC press
        document.addEventListener('keyup', evt => {
            if (evt.keyCode === 27 && this.measurementMode) {
                this.toggleMeasurementMode()
            }
        });
    },

    computed: {
        ...mapState(useMapStore, [ 'map', 'zoom', 'mapStyle' ]),
        ...mapState(usePresentationStore, [ 'presentationMode' ]),
        ...mapState(useMeasurementStore, [ 'measurementMode', 'measuredDistance' ])
    },

    methods: {
        ...mapActions(useMapStore, [
            'zoomIn',
            'zoomOut',
            'setZoom',
            'resetPitch',
            'fitBounds',
            'togglePresentationMode',
            'toggleMeasurementMode',
            'setMapStyle'
        ]),
        ...mapActions(usePresentationStore, [ 'shareMapPresentation' ]),

        fullPresentationMode() {
            return window.location.search?.includes('presentation=1')
        },

        enableFullscreen() {
            const element = document.getElementById('app')

            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            }
        }
    },

    watch: {
        'zoom'(zoom) {
            this.internalZoom = zoom
        }
    }
}
</script>
