<template>
    <nav class="w-64 px-6 py-9 -ml-0.5 space-y-5 border-t border-r border-b border-gray-600 rounded-r">
        <div class="space-y-5">
            <h2 class="font-medium text-gray-100 text-sm">Personal</h2>

            <router-link :to="{ name: 'settings.account' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
                <ui-icon name="user" class="mr-1.5"></ui-icon>
                <span class="text-base">Account</span>
            </router-link>

        </div>

        <div class="space-y-5" v-if="$can('manage-organization')">
            <h2 class="font-medium text-gray-100 text-sm">Organization</h2>

            <router-link :to="{ name: 'settings.users' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
                <ui-icon name="users" class="mr-1.5"></ui-icon>
                <span class="text-base">Users & Permissions</span>
            </router-link>

            <router-link :to="{ name: 'management.billing' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
              <ui-icon name="bank-note" class="mr-1.5"></ui-icon>
              <span class="text-base">Billing</span>
            </router-link>

            <router-link :to="{ name: 'management.audit-log' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
              <ui-icon name="file-shield" class="mr-1.5"></ui-icon>
              <span class="text-base">Audit Log</span>
            </router-link>

        </div>

        <div class="space-y-5" v-if="$can('sentinel:moderate-alerts')">
            <h2 class="font-medium text-gray-100 text-sm">Alerts</h2>

            <!--<router-link :to="{ name: 'settings.subscriptions' }"-->
            <!--             class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"-->
            <!--             active-class="text-gerulata-green-100">-->
            <!--    <ui-icon name="subscription" class="mr-1.5"></ui-icon>-->
            <!--    <span class="text-base">Alert Subscriptions</span>-->
            <!--</router-link>-->

            <router-link :to="{ name: 'management.alerts' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
                <ui-icon name="bell" class="mr-1.5"></ui-icon>
                <span class="text-base">Alerts</span>
            </router-link>

            <!--<router-link :to="{ name: 'management.moderated-events' }"-->
            <!--             class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"-->
            <!--             active-class="text-gerulata-green-100">-->
            <!--    <ui-icon name="navigation.moderated-events" class="mr-1.5"></ui-icon>-->
            <!--    <span class="text-base">Moderation</span>-->
            <!--</router-link>-->
        </div>

        <div class="space-y-5" v-if="$can('manage-organization')">
            <h2 class="font-medium text-gray-100 text-sm">Data Providers</h2>
            <router-link :to="{ name: 'management.gerulata-monitor-provider' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
                <ui-icon name="web-connect" class="mr-1.5"></ui-icon>
                <span class="text-base">Gerulata Monitor</span>
            </router-link>

            <router-link :to="{ name: 'management.custom-data-provider' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
                <ui-icon name="database-connect" class="mr-1.5"></ui-icon>
                <span class="text-base">Custom Data</span>
            </router-link>
        </div>

        <div class="space-y-5" v-if="$can('manage-organization')">
            <h2 class="font-medium text-gray-100 text-sm">Credibility</h2>
            <router-link :to="{ name: 'management.source-credibility' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
                <ui-icon name="navigation.moderated-events" class="mr-1.5"></ui-icon>
                <span class="text-base">Source Credibility</span>
            </router-link>
        </div>

        <div class="space-y-5" v-if="$can('manage-organization')">
            <h2 class="font-medium text-gray-100 text-sm">Integrations</h2>
            <router-link :to="{ name: 'management.api-tokens' }"
                         class="mx-auto flex items-center text-xl transition duration-150 ease-in-out hover:text-white"
                         active-class="text-gerulata-green-100">
                <ui-icon name="key" class="mr-1.5"></ui-icon>
                <span class="text-base">API Tokens</span>
            </router-link>
        </div>

    </nav>
</template>

<script>
import useOverviewStore from '@/stores/overview/overview'

import {mapActions} from 'pinia'

export default {
    methods: {
        ...mapActions(useOverviewStore, ['togglePerspectives'])
    }
}
</script>
