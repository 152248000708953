<template>
    <div class="w-96" v-click-away="reset">
        <div class="absolute top-0 left-4 h-9 flex items-center pointer-events-none text-xl z-40">
            <ui-icon name="search" class="text-gray-500"></ui-icon>
        </div>

        <input type="text" ref="input" v-model="query" @input="search" @keydown.esc="reset"
               autocomplete="off" spellcheck="false"
               @focus="isFocused = true"
               @blur="handleBlur"
               @click="handleClick"
               :class="[
                  'transition-all duration-300 ease-in-out h-9 pl-10 pr-2 py-3 rounded-md border border-gray-500 focus:border-gerulata-green-100 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 sm:text-sm z-30 bg-gray-800 text-gray-200  rounded-lg py-2 focus:outline-none',
                  isFocused ? 'w-96 text-gray-50' : 'w-1 cursor-pointer text-gray-50'
                ]"
               class="text-gray-200"
                placeholder="Search...">

        <div class="max-h-96 overflow-y-scroll bg-gray-800 mt-1 rounded-md" v-if="searchResults?.length">
            <div :key="index" v-for="result, index in searchResults">
                <sentinel-channel-result :result="result" :focused="result == searchResults" :ref="`result-${index}`" @click="resultClick(result)"></sentinel-channel-result>
            </div>
        </div>
    </div>
</template>

<script>

import 'mapbox-gl/dist/mapbox-gl.css'

import { mapActions, mapWritableState } from 'pinia'
import useChannelMapStore from '@/stores/channels/channel-map'
import SentinelChannelResult from "@/components/quick-search/results/sentinel-channel-result"
import useChannelDetailStore from "@/stores/channels/channel-detail";


export default {
    components: {
        SentinelChannelResult
    },

    data() {
        return {
            query: '',
            isFocused: false
        }
    },

    computed: {
        ...mapWritableState(useChannelMapStore, [ 'searchResults' ]),
    },

    methods: {
        ...mapActions(useChannelMapStore, [ 'searchChannels' ]),

        search() {
            this.searchChannels(this.query)
        },

        async resultClick(result) {
            this.reset()
            this.query = result.titleInternational || result.title

            await useChannelDetailStore().showChannelDetail(result.gid)
            this.query = ''
            this.handleBlur()
        },

        reset() {
            // this.query = ''
            this.searchResults = []
        },

        handleClick() {
            if (!this.isFocused) {
                this.$refs.inputField.focus();
            }
        },

        handleBlur() {
            if (!this.query.trim()) {
                this.isFocused = false;
            }
        },
    }
}
</script>
