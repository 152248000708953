<template>
    <div class="w-full divide-y divide-gray-700 border-t border-b border-gray-700">
        <template v-if="store.isLoading" v-for="v, i in Array.from({ length: rows || 10 })">
            <slot name="placeholder" :index="i" :cards-store="store"></slot>
        </template>

        <transition-group
            v-else-if="items?.length"
            :key="store.source"
            enter-active-class="duration-500"
            enter-from-class="transform opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-500"
            leave-from-class="opacity-100"
            leave-to-class="transform opacity-0"
            appear
        >
            <div v-for="item,i in items" :key="item.id">
                <slot name="item" :item="item" :cards-store="store"></slot>
            </div>
        </transition-group>

        <div v-if="! store.isLoading && ! items.length" class="text-center text-gray-300 py-3">
            <slot name="empty">
                No results.
            </slot>
        </div>
    </div>

    <ui-infinite-loading class="py-5" :identifier="store.source().lastReloadTime" @infinite="store.source().loadMore($event)" v-if="store.source().paginated && items.length"></ui-infinite-loading>
</template>

<script>
export default {
    computed: {
        items() {
            if (this.rows) {
                return this.store.items.slice(0, this.rows)
            }

            return this.store.items
        },

        rows() {
            return this.store.layout?.settings?.rows
        }
    }
}
</script>
