import useAuthLoginForm from '@/stores/auth/login-form'

import api from '@/api'

import { defineStore } from 'pinia'

export const useAuthActivatePage = defineStore({
    id: 'authActivatePage',

    state: () => ({
        user: null,
        activationToken: null,
        invalidToken: false
    }),

    getters: {
        canUseEmailAndPassword() {
            return this.user?.features.includes('register-via-email')
        }
    },

    actions: {
        beforeEnter(to) {
            this.$reset()

            this.activationToken = to.params.token

            useAuthLoginForm().setActivationToken(to.params.token)

            this.loadUser(to.params.token)
        },

        async loadUser(token) {
            api.route('auth activation', { token }).query({ product: 'sentinel' }).get()
                .error(404, () => this.invalidToken = true)
                .json(res => {
                    this.user = res.data
                    useAuthLoginForm().setUser(this.user)
                })
        }
    }
})

export default useAuthActivatePage
