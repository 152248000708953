<template>
    <a href="#" class="block px-6 py-3 hover:bg-gray-600" :class="{ 'bg-gray-600': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <img :src="`/flags/${result.isoCode}.svg`" class="w-4" v-if="result.layerType == 'countries'">
<!--                <ui-icon :name="icon" class="text-xl" v-else></ui-icon>-->
                <ui-icon name="grid" class="text-xl" v-else></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-200">
                    <div class="truncate">{{result.title}}</div>
                </div>

                <div class="text-xs leading-4 text-gray-300 truncate">
                    Geography • {{result.layerName}}
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
import useOverviewStore from '@/stores/overview/overview'

export default {
    props: [ 'focused', 'result' ],

    computed: {
        icon() {
            if (this.result.layerType != 'custom') return `geo-layers.${this.result.layerType}`

            return { 'point': 'map-pin', 'polygon': 'grid' }[this.result.type] || 'grid'
        }
    },

    methods: {
        async open() {
            useOverviewStore().showGeoFeature({ id: this.result.id, layerId: this.result.layerId }, { focus: true })
        }
    }
}
</script>
