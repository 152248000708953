<template>
    <search-filter name="Area of interest" :ids="['geoFence']" :filters="filters">
        <template v-slot:applied>
            <ui-icon name="map-pin" class="mr-1"></ui-icon>
            <span class="font-medium">Area of interest</span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <ui-dropdown-link @click="hideGeoFeature(geoFeature.properties)" keep-open :key="geoFeature.properties.id" v-for="geoFeature in shownFeatures">
                    <template v-slot:default>
                        <div class="flex items-center group">
                            {{ geoFeature.properties.name }}
                            <ui-icon name="trash" class="text-gray-300 hidden group-hover:block ml-auto"></ui-icon>
                        </div>
                    </template>
                </ui-dropdown-link>

                <ui-dropdown-link @click="selectGeoFeature" keep-open>
                    {{value.length ? 'Add geography...' : 'Select geography...'}}
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link @click="selectArea">Select area on map...</ui-dropdown-link>
                <ui-dropdown-link @click="selectRadius">Select radius on map...</ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="-mt-2">
                <ui-dropdown-link @click="clearAll">Clear all geo-fences</ui-dropdown-link>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'
import useOverviewStore from '@/stores/overview/overview'
import useQuickSearchStore from '@/stores/me/quick-search'
import { mapState } from "pinia"

export default {
    props: [ 'filters' ],

    components: {
        SearchFilter
    },

    computed: {
        ...mapState(useOverviewStore, [ 'shownFeatures' ]),

        value() {
            return this.filters.value('geoFence') || []
        }
    },

    methods: {
        selectGeoFeature() {
            useQuickSearchStore().show({
                families: [ 'sentinelBoundaries', 'geoFeatures' ],
                limit: 50,
                onSelect: async result => {
                    useOverviewStore().showBoundary(result)
                }
            })
        },

        hideGeoLayer(geoLayer) {
            useOverviewStore().hideGeoLayer(geoLayer.id)
        },

        hideGeoFeature(geoFeature) {
            useOverviewStore().hideGeoFeature(geoFeature)
        },

        selectArea() {
            useOverviewStore().createPolygon(null, true)
        },

        selectRadius() {
            useOverviewStore().createCircle(null, true)
        },

        clearAll() {
            useOverviewStore().clearMap()
        }
    }
}
</script>