<template>
    <router-link :to="{ name: 'overview.event', params: { eventId: event.id } }" target="_blank" class="block" v-if="mode == 'card'">
        <div class="rounded-lg bg-gray-700 border border-gray-600 shadow-sm hover:shadow-lg hover:scale-105 transition cursor-pointer relative group">
            <div class="flex items-center gap-x-3 p-4 pb-3">
                <div class="w-9 h-9 bg-gray-300 rounded-full flex justify-center items-center" :style="{ backgroundColor: category?.color }" v-tooltip="categoryTooltip">
                    <img :src="category?.icon" class="w-6 opacity-80">
                </div>

                <div>
                    <h1 class="font-medium text-lg truncate" v-tooltip="event.title">{{event.title}}</h1>
                    <div class="flex gap-x-2">
                        <div v-if="event.startedOn" class="text-gray-300 flex items-center gap-x-1 text-sm">
                            <ui-icon name="calendar"></ui-icon>
                            <span>{{ $date(event.startedOn, 'dd MMMM yyyy') }}</span>
                        </div>
                        <div v-if="event.location.address" class="text-gray-300 flex items-center gap-x-1 text-sm">
                            <ui-icon name="location"></ui-icon>
                            <span>{{ event.location.address }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 pt-0 pb-3">
                {{event.description}}
            </div>
            <div v-if="event.image" class="p-4 pt-0 flex justify-center">
                <div class="rounded overflow-hidden h-52">
                    <img :src="event.image" class="w-full h-full object-contain">
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import useEventCategoriesStore from "@/stores/me/event-categories"

export default {
    props: { event: {}, mode: { default: 'card' } },

    computed: {
        category() {
            return useEventCategoriesStore().find(this.event.category)
        },

        categoryTooltip() {
            return useEventCategoriesStore().find(this.event.fullCategory).fullName
        }
    }
}
</script>
