<template>
    <a @click.prevent="showDetails(trigger)" href="#" class="block" v-if="mode == 'card'">
        <div class="rounded-lg bg-gray-700 border border-gray-600 shadow-sm hover:shadow-lg hover:scale-105 transition cursor-pointer relative group p-4 py-3">
            <div class="flex items-center relative" v-tooltip="trigger.alert.description">
                <ui-avatar :item="trigger.alert" class="w-6 h-6 shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-2">
                    <h1 class="text-xl font-medium truncate text-gray-100">
                        {{trigger.alert.name}}
                    </h1>
                </div>

                <div class="shrink-0">
                    <contextual-menu :trigger="trigger" plain></contextual-menu>
                </div>
            </div>

            <div class="flex items-center justify-between mt-2">
                <div>
                    <div class="font-medium text-sm flex items-center gap-x-1.5">
                        <div v-if="deliveries.length == 1" class="flex-1 flex items-center gap-x-1" :class="{ 'opacity-40': ! trigger.receipts[deliveries[0].id] }">
                            <ui-avatar :item="deliveries[0]" class="h-4 w-4"></ui-avatar>
                            {{deliveries[0].name}}
                        </div>
                        <div v-else class="flex -space-x-1 relative z-0 overflow-hidden  cursor-default">
                            <ui-avatar v-for="recipient, index in deliveries.slice(0, 6)" :item="recipient" class="relative inline-block h-4 w-4 rounded-full ring-1 ring-gray-700" :class="{ [`z-${50 - index * 10}`]: true, 'opacity-40': ! trigger.receipts[recipient.id] }" v-tooltip="recipient.name" :key="recipient.id"></ui-avatar>
                        </div>
                        <div v-if="deliveries.length > 6" class="font-medium text-xs text-gray-400 cursor-default" v-tooltip="additionalDeliveriesTooltip">
                            +{{deliveries.length - 6}}
                        </div>
                    </div>
                </div>

                <div class="text-xs text-gray-400">
                    {{createdAt}}
                </div>
            </div>
        </div>
    </a>

    <a @click.prevent="showDetails(trigger)" href="#" class="block hover:bg-gradient-to-r from-transparent via-gray-800 to-transparent transition" v-else-if="mode == 'row'">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0">
                <div class="w-2.5 h-2.5 shrink-o rounded-full" :class="trigger.unread ? 'bg-gerulata-green-100' : 'bg-gray-700'"></div>

                <div class="min-w-0 px-2">
                    <h1 class="font-medium truncate leading-tight">
                        {{$dateTime(trigger.createdAt)}}
                    </h1>
                </div>
            </div>

            <div class="flex items-center space-x-3 px-3">
                <div class="w-36">
                    <div class="font-medium text-sm flex items-center gap-x-1.5">
                        <div v-if="receipts.length == 1" class="flex-1 flex items-center gap-x-1" v-tooltip="`${receipts[0].user.name}: ${$dateTime(receipts[0].readAt)}`">
                            <ui-avatar :item="receipts[0].user" class="h-5 w-5"></ui-avatar>
                            {{receipts[0].user.name}}
                        </div>
                        <div v-else class="flex -space-x-1 relative z-0 overflow-hidden  cursor-default">
                            <ui-avatar v-for="receipt, index in receipts.slice(0, 6)" :item="receipt.user" class="relative inline-block h-5 w-5 rounded-full ring-1 ring-white" :class="`z-${50 - index * 10}`" v-tooltip="`${receipt.user.name}: ${$dateTime(receipt.readAt)}`" :key="receipt.user.id"></ui-avatar>
                        </div>
                        <div v-if="receipts.length > 6" class="font-medium text-xs text-gray-800 cursor-default" v-tooltip="additionalReceiptsTooltip">
                            +{{receipts.length - 6}}
                        </div>
                    </div>
                </div>

                <div class="w-36">
                    <div class="font-medium text-sm flex items-center gap-x-1.5">
                        <div v-if="deliveries.length == 1" class="flex-1 flex items-center gap-x-1">
                            <ui-avatar :item="deliveries[0]" class="h-5 w-5"></ui-avatar>
                            {{deliveries[0].name}}
                        </div>
                        <div v-else class="flex -space-x-1 relative z-0 overflow-hidden  cursor-default">
                            <ui-avatar v-for="recipient, index in deliveries.slice(0, 6)" :item="recipient" class="relative inline-block h-5 w-5 rounded-full ring-1 ring-gray-900" :class="`z-${50 - index * 10}`" v-tooltip="recipient.name" :key="recipient.id"></ui-avatar>
                        </div>
                        <div v-if="deliveries.length > 6" class="font-medium text-xs text-gray-200 cursor-default" v-tooltip="additionalDeliveriesTooltip">
                            +{{deliveries.length - 6}}
                        </div>
                    </div>
                </div>

                <div class="w-24">
                    <div class="font-medium text-sm">
                        <span v-if="! trigger.archivedAt" class="rounded-full bg-blue-900 text-blue-200 py-0.5 px-2">
                            Available
                        </span>
                        <span v-else class="rounded-full bg-orange-900 text-orange-200 py-0.5 px-2">
                            Archived
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex items-center px-3">
                <slot name="actions">
                    <contextual-menu :trigger="trigger" plain></contextual-menu>
                </slot>
            </div>
        </div>
    </a>
</template>

<script>
import ContextualMenu from '@/components/alerts/menus/trigger'

import useAlertsDetailsStore from '@/stores/alerts/trigger'
import useMyStore from '@/stores/me/my'

import { format, parseISO } from 'date-fns'
import { mapActions } from 'pinia'

export default {
    props: { trigger: {}, mode: { default: 'card' } },

    components: { ContextualMenu },

    data: () => ({
        currentDate: +(new Date)
    }),

    computed: {
        deliveries() {
            return useMyStore().currentWorkspace.users.filter(user => this.trigger.deliveries.includes(user.id))
        },

        additionalDeliveriesTooltip() {
            return this.deliveries.slice(6).map(recipient => recipient.name).join(', ')
        },

        receipts() {
            return Object.entries(this.trigger.receipts).map(([ userId, readAt ]) => {
                return { user: useMyStore().currentWorkspace.users.find(u => u.id == userId), readAt }
            }).filter(r => r.user)
        },

        additionalReceiptsTooltip() {
            return {
                content: this.receipts.slice(6).map(receipt => `${receipt.user.name}: ${this.$dateTime(receipt.readAt)}`).join('<br>'),
                html: true
            }
        },

        createdAt() {
            let date = this.trigger.createdAt instanceof Date ? this.trigger.createdAt : parseISO(this.trigger.createdAt)
            let diffInSeconds = (this.currentDate - date) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(date, 'H:mm')
            } else {
                return format(date, 'LLL do H:mm')
            }
        },
    },

    methods: {
        ...mapActions(useAlertsDetailsStore, { showDetails: 'open' })
    },

    mounted() {
        setInterval(() => this.currentDate = +(new Date), 60000)
    }
}
</script>
