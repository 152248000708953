<template>
    <div>
        <div>
            <div class="h-screen relative bg-gray-900">
                <app-navigation class="absolute left-0 inset-y-0 z-50" v-if="! $my.isGuest && ! presentationMode"></app-navigation>
                <app-sidebar></app-sidebar>

                <div class="flex-1 h-full overflow-y-auto" id="app-content">
                    <router-view></router-view>
                </div>

                <notifications-toaster></notifications-toaster>
            </div>
        </div>

        <overview-perspective-edit-modal></overview-perspective-edit-modal>
        <overview-perspective-publish-modal></overview-perspective-publish-modal>
        <overview-geofeature-import-modal></overview-geofeature-import-modal>
        <overview-geo-feature-edit-modal></overview-geo-feature-edit-modal>
        <overview-geo-layer-edit-modal></overview-geo-layer-edit-modal>
<!--        <overview-report-bad-event-modal></overview-report-bad-event-modal>-->

        <help-overlay></help-overlay>
        <notifications-overlay></notifications-overlay>
        <quick-search-overlay></quick-search-overlay>
        <workspaces-modal></workspaces-modal>

        <alert-create-modal></alert-create-modal>
        <alert-edit-modal></alert-edit-modal>
        <alert-trigger-modal></alert-trigger-modal>
        <auth-session-lost-modal></auth-session-lost-modal>
        <debug-modal></debug-modal>
        <image-preview-modal></image-preview-modal>
        <tags-manage-modal></tags-manage-modal>
        <workspace-info-modal></workspace-info-modal>

        <modals-container></modals-container>

        <div class="bg-blue-700 text-white rounded-lg shadow-md border flex px-4 py-0.5 absolute right-40 bottom-4 z-40" v-if="$my.isImpersonating">
            <div class="h-10 flex items-center">
                <p>You're impersonating <span class="font-medium">{{$my.user.email}}</span>. <a class="underline font-medium" href="#" @click.prevent="$my.stopImpersonating">Vent</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavigation from './AppNavigation'
import HelpOverlay from '@/components/ui/help-overlay'
import NotificationsOverlay from '@/components/me/notifications/overlay'
import NotificationsToaster from '@/components/me/notifications/toaster'
import QuickSearchOverlay from '@/components/quick-search/overlay'

import AlertCreateModal from '@/components/alerts/modals/alert-create'
import AlertEditModal from '@/components/alerts/modals/alert-edit'
import AlertTriggerModal from '@/components/alerts/modals/trigger'
import AppSidebar from './AppSidebar'
import AuthSessionLostModal from './components/auth/modals/session-lost'
import DebugModal from '@/components/ui/debug-modal'
import ImagePreviewModal from './components/overview/modals/image-preview'
import OverviewPerspectiveEditModal from './components/overview/modals/perspective-edit'
import OverviewPerspectivePublishModal from './components/overview/modals/perspective-publish'
import OverviewGeofeatureImportModal from './components/overview/modals/geofeature-import'
import OverviewGeoFeatureEditModal from './components/overview/modals/geo-feature-edit'
import OverviewGeoLayerEditModal from './components/overview/modals/geo-layer-edit'
// import OverviewReportBadEventModal from './components/overview/modals/report-bad-event'
import TagsManageModal from './components/me/tags/manage-modal'
import WorkspacesModal from '@/components/me/workspaces/modal'
import WorkspaceInfoModal from '@/components/me/workspaces/modals/workspace-info'

import { ModalsContainer } from 'vue-final-modal'

import useMapStore from "@/stores/overview/map"

import { trackScrolling } from '@/helpers/scroll'
import { mapState } from "pinia"

export default {
    components: {
        AppNavigation, HelpOverlay, NotificationsOverlay, NotificationsToaster, QuickSearchOverlay,
        AlertCreateModal, AlertEditModal, AlertTriggerModal, AppSidebar, AuthSessionLostModal, DebugModal,
        ImagePreviewModal, OverviewPerspectiveEditModal, OverviewPerspectivePublishModal, OverviewGeofeatureImportModal,
        OverviewGeoFeatureEditModal, OverviewGeoLayerEditModal, TagsManageModal, WorkspacesModal, WorkspaceInfoModal,
        // OverviewReportBadEventModal
        ModalsContainer
    },

    mounted() {
        trackScrolling()
    },

    computed: {
        ...mapState(useMapStore, [ 'presentationMode' ]),
    }
}
</script>
