<template>
    <transition
        class="transition duration-150"
        enter-from-class="opacity-0"
        enter-active-class="opacity-full"
        leave-active-class="opacity-0"
    >
        <div class="fixed bottom-0 right-0 max-w-md pl-6 w-full h-full p-4 py-0 z-40 flex flex-col justify-end bg-gradient-to-r from-transparent to-[#11141955]" v-show="isOverlayShown">
            <div class="overflow-y-auto flex-1 py-4 pb-20" ref="notifications">
                <div class="flex flex-col justify-start min-h-full">
                    <div class="space-y-4">
                        <notification-card v-for="notification in shownNotifications" :key="notification.id" :notification="notification" no-dismiss></notification-card>
                    </div>

                    <div v-if="! shownNotifications.length" class="py-6 flex justify-center bg-gray-100/75 backdrop-blur-sm rounded-xl">
                        <div class="text-gray-200 font-medium px-4 py-2">
                            {{isShowingAllNotifications ? 'You have no notifications.' : 'You have no notifications Today.'}}
                        </div>
                    </div>

                    <div v-if="isShowingAllNotifications && shownNotifications.length" class="bg-white shadow-lg rounded-lg border border-gray-200 py-4 mt-4">
                        <ui-infinite-loading @infinite="loadMore"></ui-infinite-loading>
                    </div>

                    <div class="flex justify-center mt-4">
                        <a v-if="! isShowingAllNotifications" href="#" @click.prevent="showAllNotifications()" class="bg-gray-200 text-gray-600 rounded-full py-1 px-4 font-medium text-xs mb-4">
                            Show older notifications
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import NotificationCard from '@/components/me/notifications/partials/notification-card'

import useMyNotificationsStore from '@/stores/me/notifications'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        NotificationCard
    },

    computed: {
        ...mapState(useMyNotificationsStore, [
            'isOverlayShown', 'isShowingAllNotifications', 'loadMore', 'shownNotifications'
        ])
    },

    methods: {
        ...mapActions(useMyNotificationsStore, [
            'showAllNotifications'
        ])
    }
}
</script>
