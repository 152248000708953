import Main from './Main.vue'
import AlertsIndex from './components/alerts/index'
import AlertsTrigger from './components/alerts/trigger'
import AuthLogin from './components/auth/login'
import AuthActivate from './components/auth/activate'
import AuthPasswordRecoveryRequest from './components/auth/password-recovery-request'
import AuthPasswordRecoveryReset from './components/auth/password-recovery-reset'
import App from './App.vue'
import ChannelsIndex from '@/components/channels/index'
import ChannelsScreen from '@/components/channels/screen'
import LayersIndex from '@/components/layers/index'
import ManagementAlerts from '@/components/management/alerts'
import ManagementApiTokens from '@/components/management/api-tokens'
import ManagementAuditLog from '@/components/settings/organization/audit-log'
import ManagementIndex from '@/components/management/index'
import ManagementManualImport from '@/components/management/manual-import'
import ManagementModeratedEvents from '@/components/management/moderated-events'
import ManagementSharing from '@/components/management/sharing'
import OverviewIndex from '@/components/overview/index'
import OverviewNavigation from '@/components/overview/navigation'
import OverviewScreen from '@/components/overview/screen'
import SettingsAccount from '@/components/settings/account'
import SettingsBilling from '@/components/management/billing'
import SettingsCustomData from '@/components/management/custom-provider'
import SettingsGerulataMonitor from '@/components/management/gerulata-monitor'
import SettingsScreen from '@/components/settings/screen'
import SettingsSidebar from '@/components/settings/sidebar'
import SettingsSourceCredibility from '@/components/settings/credibility/source-credibility'
import SettingsSubscriptions from '@/components/settings/subscriptions'
import SettingsUsers from '@/components/settings/users/users'

const pages = import.meta.glob('@/pages/*.js', { eager: true, import: 'default' })

export default [
    // Root redirect to default section (overview)
    { path: '', redirect: { name: 'overview' } },

    // Public routes
    {
        component: Main,
        meta: { guestOnly: true },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: { page: pages['/src/pages/auth-login'.js] },
                component: AuthLogin
            },
            {
                path: '/login/activate/:token',
                name: 'login.activate',
                meta: { page: pages['/src/pages/auth-activate'.js] },
                component: AuthActivate
            },
            {
                path: '/password-recovery/request',
                name: 'password-recovery.request',
                meta: { page: pages['/src/pages/auth-password-recovery-request'.js] },
                component: AuthPasswordRecoveryRequest
            },
            {
                path: '/password-recovery/reset/:token',
                name: 'password-recovery.reset',
                meta: { page: pages['/src/pages/auth-password-recovery-reset'.js] },
                component: AuthPasswordRecoveryReset
            }
        ]
    },

    // Application routes (requires authentication)
    {
        component: App,
        meta: { authenticatedOnly: true },
        children: [
            {
                path: '/alerts',
                children: [
                    {
                        path: '',
                        name: 'alerts',
                        component: AlertsIndex,
                        meta: { page: pages['/src/pages/alerts-index.js'] }
                    },
                    {
                        path: ':alertId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
                        name: 'alerts.trigger',
                        component: AlertsTrigger,
                        meta: { page: pages['/src/pages/alerts-trigger.js'] }
                    }
                ]
            },

            {
                path: '/overview',
                components: {
                    default: OverviewScreen,
                    navigation: OverviewNavigation
                },
                children: [
                    {
                        path: '',
                        name: 'overview',
                        component: OverviewIndex,
                        meta: { page: pages['/src/pages/overview.js'] }
                    },
                    {
                        path: 'perspective/:perspectiveId',
                        name: 'overview.perspective',
                        component: OverviewIndex,
                        meta: { page: pages['/src/pages/overview.js'] }
                    },
                    {
                        path: 'event/:eventId',
                        name: 'overview.event',
                        component: OverviewIndex,
                        meta: { page: pages['/src/pages/overview.js'] }
                    }
                ]
            },
            {
                path: '/channels',
                components: {
                    default: ChannelsScreen
                },
                children: [
                    {
                        path: '',
                        name: 'channels',
                        component: ChannelsIndex,
                        meta: { page: pages['/src/pages/channels.js'] }
                    },
                    {
                        path: ':channelId',
                        name: 'channels.detail',
                        component: ChannelsIndex,
                        meta: { page: pages['/src/pages/channels.js'] }
                    }
                ]
            },

            {
                path: '/layers',
                components: {
                    default: OverviewScreen,
                    navigation: OverviewNavigation
                },
                children: [
                    {
                        path: '',
                        name: 'layers',
                        component: LayersIndex
                    }
                ]
            },

            {
                path: '/settings',
                components: {
                    default: SettingsScreen,
                    sidebar: SettingsSidebar
                },
                children: [
                    {
                        path: 'account',
                        name: 'settings.account',
                        component: SettingsAccount,
                        meta: { page: pages['/src/pages/settings-account.js'] }
                    },
                    {
                        path: 'subscriptions',
                        name: 'settings.subscriptions',
                        component: SettingsSubscriptions,
                        meta: { page: pages['/src/pages/settings-subscriptions.js'] }
                    },
                    {
                        path: 'management/overview',
                        name: 'management.overview',
                        component: ManagementIndex,
                        meta: { page: pages['/src/pages/management-index.js'] }
                    },
                    {
                        path: 'settings/users',
                        name: 'settings.users',
                        component: SettingsUsers,
                        meta: { page: pages['/src/pages/settings-users.js'] }
                    },
                    {
                        path: 'management/billing',
                        name: 'management.billing',
                        component: SettingsBilling,
                        meta: { page: pages['/src/pages/management-index.js'] }
                    },
                    {
                        path: 'management/audit-log',
                        name: 'management.audit-log',
                        component: ManagementAuditLog,
                        meta: { page: pages['/src/pages/settings-audit-log.js'] }
                    },
                    {
                        path: 'management/gerulata-monitor',
                        name: 'management.gerulata-monitor-provider',
                        component: SettingsGerulataMonitor,
                        meta: { page: pages['/src/pages/settings-gerulata-monitor-provider.js'] }
                    },
                    {
                        path: 'management/custom-data',
                        name: 'management.custom-data-provider',
                        component: SettingsCustomData,
                        meta: { page: pages['/src/pages/settings-custom-data-provider.js'] }
                    },
                    {
                        path: 'management/api-tokens',
                        name: 'management.api-tokens',
                        component: ManagementApiTokens,
                        meta: { page: pages['/src/pages/management-index.js'] }
                    },
                    {
                        path: 'management/source-credibility',
                        name: 'management.source-credibility',
                        component: SettingsSourceCredibility,
                        meta: { page: pages['/src/pages/settings-credibility.js'] }
                    },
                    {
                        path: 'management/manual-import',
                        name: 'management.manual-import',
                        component: ManagementManualImport,
                        meta: { page: pages['/src/pages/management-index.js'] }
                    },
                    {
                        path: 'management/sharing',
                        name: 'management.sharing',
                        component: ManagementSharing,
                        meta: { page: pages['/src/pages/management-sharing.js'] }
                    },
                    {
                        path: 'management/alerts',
                        name: 'management.alerts',
                        component: ManagementAlerts,
                        meta: { page: pages['/src/pages/management-alerts.js'] }
                    },
                    {
                        path: 'management/moderated-events',
                        name: 'management.moderated-events',
                        component: ManagementModeratedEvents,
                        meta: { page: pages['/src/pages/management-moderated-events.js'] }
                    }
                ]
            }
        ]
    },

    {
        component: App,
        children: [
            {
                path: '/overview/published/:token',
                components: {
                    default: OverviewScreen,
                    navigation: OverviewNavigation
                },
                children: [
                    {
                        path: '',
                        name: 'overview.published',
                        component: OverviewIndex,
                        meta: { page: pages['/src/pages/overview-published.js'] }
                    }
                ]
            }
        ]
    },

    // Catch-all route for "not found" pages
    { path: '/:pathMatch(.*)*', redirect: { name: 'overview' } }
]
