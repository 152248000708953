<template>
    <div class="py-4 px-5 space-y-2">
        <div v-if="channel.type" class="mb-2">
            <div class="text-sm text-gray-300 w-28 inline-block">Platform</div>
            <ui-icon :name="platform?.icon" class="text-lg" v-if="platform?.icon"></ui-icon>
            {{ platform?.label }}
        </div>

        <div v-if="channel.title" class="table-row">
            <div class="text-sm text-gray-300 w-28 table-cell">Title (local)</div>
            <div>{{ channel.title }}</div>
        </div>

        <div v-if="channel.titleInternational != channel.title"></div>

        <div v-if="channel.titleInternational != channel.title" class="table-row">
            <div class="text-sm text-gray-300 w-28 table-cell">Title (int)</div>
            <div>{{ channel.titleInternational }}</div>
        </div>

        <div v-if="channel.basedInCountry">
            <div class="text-sm text-gray-300 w-28 inline-block">Based in</div>
            <ui-icon :name="country.icon" class="mr-1" v-if="country?.icon"></ui-icon>
            {{ channel.basedInCountry }}<span v-if="channel.basedInCity">,</span>

            <span v-if="channel.basedInCity" class="ml-2">{{ channel.basedInCity }}</span>
        </div>

        <div v-if="channel.reliability" class="flex items-center">
            <div class="text-sm text-gray-300 w-28 inline-block">Reliability</div>

            <div class="w-3 h-3 rounded-full inline-block mr-1" :style="{ backgroundColor: reliability[channel.reliability].color  }"></div>
            {{ channel.reliability }} ({{ reliability[channel.reliability].caption }})
        </div>

        <div v-if="channel.followers">
            <div class="text-sm text-gray-300 w-28 inline-block">Followers</div>
            {{ $number(channel.followers) }}
        </div>

        <div v-if="langs?.length" class="flex items-top">
            <div class="text-sm text-gray-300 w-28 inline-block">Labels</div>
            <div class="flex flex-wrap space-x-3">
                <div v-for="lang in langs" :key="lang" class="inline-flex items-center bg-gray-600 font-medium inline-block px-2 rounded">
                    <span class="capitalize">{{ lang }}</span>
                </div>
            </div>
        </div>

        <div v-if="labels?.length" class="flex">
            <div class="text-sm text-gray-300 w-28 inline-block">Labels</div>
            <div class="flex flex-wrap inline-block">
                <div v-for="label in labels" :key="label" class="inline-flex items-center mr-1 mb-1 bg-gray-600 font-medium inline-block px-2 rounded">
                    <div class="w-3 h-3 rounded-full inline-block mr-2 bg-blue-400"></div>
                    <span class="capitalize">{{ label }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="border-b border-gray-500 w-full"></div>

    <div class="py-4 px-5">
        <h2 class="text-2xl text-gray-100 font-medium">Metrics & Stats</h2>
        <ui-spinner class="text-sm" v-if="loadingStats"></ui-spinner>

        <div v-if="stats" class="space-y-2 mt-4">
            <div v-if="stats.contents || stats.contents === 0">
                <div class="text-sm text-gray-300 w-48 inline-block">Content Published</div>
                <span>{{ $number(stats.contents) }}</span>
            </div>

            <div v-if="stats.reports">
                <div class="text-sm text-gray-300 w-48 inline-block">Reports Published</div>
                <span>{{ $number(stats.reports) }}</span>
            </div>

            <div v-if="stats.contents">
                <div class="text-sm text-gray-300 w-48 inline-block">Reports Yield</div>
                <span>{{ Math.round((stats.reports / stats.contents) * 100) }}%</span>
            </div>

            <div v-if="stats.firstPublishedAt">
                <div class="text-sm text-gray-300 w-48 inline-block">First Content Published At</div>
                <span>{{ $dateTime(stats.firstPublishedAt, 'dd MMMM yyyy HH:mm') }}</span>
            </div>

            <div v-if="stats.lastPublishedAt">
                <div class="text-sm text-gray-300 w-48 inline-block">Last Content Published At</div>
                <span>{{ $dateTime(stats.lastPublishedAt, 'dd MMMM yyyy HH:mm') }}</span>
            </div>

            <div v-if="stats.lastPublishedAt">
                <div class="text-sm text-gray-300 w-48 inline-block">Median Processing Lag</div>
                <span>{{ secondsToReadable(stats.processingLag) }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from 'pinia'
import CountryOptions from "@/stores/me/countries"
import useOverviewStore from '@/stores/overview/overview'
import useChannelDetailStore from "@/stores/channels/channel-detail"
import useDetailsStore from "@/stores/overview/details"

export default {

    data: () => ({
        reliability: {
            'A': { caption: 'Reliable', color: 'green' },
            'B': { caption: 'Usually reliable', color: 'green' },
            'C': { caption: 'Fairly Reliable', color: 'yellow' },
            'D': { caption: 'Not usually reliable', color: 'yellow' },
            'E': { caption: 'Unreliable', color: 'red' },
            'F': { caption: 'Reliability unknown', color: 'gray' },
        },
    }),

    computed: {
        ...mapState(useChannelDetailStore, [ 'channel', 'stats', 'loadingStats' ]),
        ...mapState(useDetailsStore, [ 'platforms' ]),

        platform() {
            return this.platforms[this.channel.type] || null
        },

        labels() {
            const labels = this.channel.labels.slice(1, -1)
            if (!labels) return []
            return labels.split(',').map(item => item.trim().replaceAll('-', ' '))
        },

        langs() {
            const langs = this.channel?.languages?.slice(1, -1)
            if (!langs) return []
            return langs.split(',').map(item => item.trim())
        },

        country() {
            return CountryOptions.find(c => c.name === this.channel.basedInCountry)
        },
    },

    methods: {
        ...mapActions(useOverviewStore, [ 'hideSidebar' ]),

        secondsToReadable(seconds) {
            const roundedSeconds = Math.round(seconds)

            const hours = Math.floor(roundedSeconds / 3600)
            const minutes = Math.floor((roundedSeconds % 3600) / 60)
            const secs = roundedSeconds % 60

            return [
                hours.toString().padStart(2, '0'),
                minutes.toString().padStart(2, '0'),
                secs.toString().padStart(2, '0')
            ].join(':')
        }
    }
}
</script>