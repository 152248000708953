<template>
    <ui-modal :content-class="`bg-gray-700 border border-${color || 'orange'}-400/50 shadow-xl flex flex-col h-auto w-full rounded-xl px-7 py-6`" max-width="360px" unstyled v-slot="{ close }">
        <div :class="`text-center text-5xl text-${color || 'orange'}-400 mb-4`">
            <ui-icon :name="icon || 'alert-triangle'"></ui-icon>
        </div>

        <slot name="default">
            <div class="text-center">
                <h1 v-if="title" class="font-semibold text-lg mb-1">{{title}}</h1>
                <p v-if="body">{{body}}</p>
            </div>
        </slot>

        <div class="mt-6 space-y-3">
            <label v-if="muteable" class="text-sm text-gray-300 space-x-1.5">
                <ui-switch v-model="mute" size="sm"></ui-switch>
                <span>Don't show this alert for {{muteable.expires}} days.</span>
            </label>

            <div class="flex items-center gap-x-6">
                <slot name="actions">
                    <ui-button @click="cancel(close)" class="flex-1">Cancel</ui-button>
                    <ui-button @click="confirm(close)" color="green" class="flex-1">Confirm</ui-button>
                </slot>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import useMyStore from '@/stores/me/my'

import { addDays } from 'date-fns'

export default {
    props: [ 'body', 'color', 'icon', 'muteable', 'title' ],

    data: () => ({
        mute: false
    }),

    methods: {
        cancel(close) {
            this.$emit('cancel')
            close()
        },

        confirm(close) {
            if (this.mute) {
                useMyStore().updatePreferences(preferences => {
                    preferences.mutedAlerts[this.muteable.id] = addDays(new Date, this.muteable.expires)
                })
            }

            this.$emit('confirm')
            close()
        }
    }
}

// Generate tailwind classes: border-blue-400/50 border-orange-400/50 border-red-400/50 text-blue-400 text-orange-400 text-red-400
</script>