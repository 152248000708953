<template>
    <template v-if="range">
        <date-time-range v-if="type === 'datetime'" v-bind="$attrs" v-model:value="internalValue"></date-time-range>
        <calendar-range v-else v-bind="$attrs" v-model:value="internalValue"></calendar-range>
    </template>

    <template v-else-if="timePicker">
        <time-panel v-bind="$attrs" v-model:value="internalValue"></time-panel>
    </template>

    <template v-else>
        <date-time v-if="type === 'datetime'" v-bind="$attrs" v-model:value="internalValue"></date-time>
        <calendar v-else v-bind="$attrs" v-model:value="internalValue"></calendar>
    </template>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { zonedToUTC } from "@/helpers/datetime"

const Calendar = DatePicker.Calendar
const CalendarRange = DatePicker.CalendarRange
const DateTime = DatePicker.DateTime
const DateTimeRange = DatePicker.DateTimeRange
const TimePanel = DatePicker.TimePanel

export default {
    components: { Calendar, CalendarRange, DateTime, DateTimeRange, TimePanel },

    props: { 'type': {}, range: { type: Boolean, default: false }, timePicker: { type: Boolean, default: false }, 'modelValue': {} },

    computed: {
        internalValue: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', Array.isArray(val) ? val.map((v) => zonedToUTC(v)) : zonedToUTC(val)) }
        }
    }
}
</script>