<template>
    <loading-bar :show="events.pending"></loading-bar>
    <div class="relative">
        <div class="flex h-screen w-full">
            <div class="h-screen w-full bg-neutral-900" id="map"></div>
        </div>

        <div class="absolute inset-0 z-40 pointer-events-none flex pl-16">
            <div class="flex-1 min-w-0 flex flex-col justify-between">
                <div v-if="!presentationMode" class="flex flex-col space-y-4 w-full">
                    <events-toolbar></events-toolbar>
                </div>

                <div class="flex justify-between items-start h-full w-full relative">
                    <div class="flex p-4 p-4 w-full justify-center">
<!--                        <view-export-tags></view-export-tags>-->
                        <shape-toolbar></shape-toolbar>
                    </div>

                    <div class="min-w-32 text-right mt-5 mr-2" v-if="events?.data">
                        {{ $number(events.data.length) }} Results
                    </div>

                    <div class="flex h-full">
                        <map-controls></map-controls>
                        <details-sidebar v-if="!presentationMode"></details-sidebar>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="presentationMode" class="marquee-panel text-gray-100 z-50 fixed w-full bottom-0 left-0">
        <marquee-text :key="marqueeId" :repeat="10" :duration="30">{{ marqueeText }}</marquee-text>
    </div>
</template>

<script>
import DetailsSidebar from './details/sidebar'
import EventsToolbar from './toolbar/toolbar'
import MapControls from './map/controls'
import MarqueeText from 'vue-marquee-text-component'
import ShapeToolbar from './toolbar/shape'
import LoadingBar from '@/components/overview/map/loading-bar.vue'
import { useMapStore } from '@/stores/overview/map'
import useStreamStore from "@/stores/overview/stream"
import usePresentationStore from "@/stores/overview/presentation"

import { mapState } from 'pinia'
import 'mapbox-gl/dist/mapbox-gl.css'
// import ViewExportTags from "@/components/overview/toolbar/view-export-tags.vue"

export default {
    components: {
        // ViewExportTags,
        DetailsSidebar,
        EventsToolbar,
        LoadingBar,
        MapControls,
        MarqueeText,
        ShapeToolbar
    },

    computed: {
        ...mapState(usePresentationStore, [ 'presentationMode', 'marqueeText', 'marqueeId' ]),
        ...mapState(useStreamStore, [ 'events' ])
    },

    mounted() {
        useMapStore().createMap()
    }
}
</script>
