const options = [
    { name: 'Saudi Arabia', id: 'ar', icon: 'flags.sa' },
    { name: 'Belarus', id: 'be', icon: 'flags.by' },
    { name: 'Bulgaria', id: 'bg', icon: 'flags.bg' },
    { name: 'China', id: 'zh', icon: 'flags.cn' },
    { name: 'Czechia', id: 'cs', icon: 'flags.cz' },
    { name: 'Denmark', id: 'da', icon: 'flags.dk' },
    { name: 'Netherlands', id: 'nl', icon: 'flags.nl' },
    { name: 'United Kingdom', id: 'en', icon: 'flags.uk' },
    { name: 'Estonia', id: 'et', icon: 'flags.ee' },
    { name: 'Finland', id: 'fi', icon: 'flags.fi' },
    { name: 'France', id: 'fr', icon: 'flags.fr' },
    { name: 'Germany', id: 'de', icon: 'flags.de' },
    { name: 'Greece', id: 'el', icon: 'flags.gr' },
    { name: 'Israel', id: 'he', icon: 'flags.il' },
    { name: 'India', id: 'hi', icon: 'flags.in' },
    { name: 'Hungary', id: 'hu', icon: 'flags.hu' },
    { name: 'Ireland', id: 'ga', icon: 'flags.ie' },
    { name: 'Italy', id: 'it', icon: 'flags.it' },
    { name: 'South Korea', id: 'ko', icon: 'flags.kr' },
    { name: 'Latvia', id: 'lv', icon: 'flags.lv' },
    { name: 'Japan', id: 'ja', icon: 'flags.jp' },
    { name: 'Lithuania', id: 'lt', icon: 'flags.lt' },
    { name: 'Norway', id: 'no', icon: 'flags.no' },
    { name: 'Poland', id: 'pl', icon: 'flags.pl' },
    { name: 'Portugal', id: 'pt', icon: 'flags.pt' },
    { name: 'Romania', id: 'ro', icon: 'flags.ro' },
    { name: 'Russia', id: 'ru', icon: 'flags.ru' },
    { name: 'Serbia', id: 'sr', icon: 'flags.rs' },
    { name: 'Slovakia', id: 'sk', icon: 'flags.sk' },
    { name: 'Slovenia', id: 'sl', icon: 'flags.si' },
    { name: 'Spain', id: 'es', icon: 'flags.es' },
    { name: 'Sweden', id: 'sv', icon: 'flags.se' },
    { name: 'Turkey', id: 'tr', icon: 'flags.tr' },
    { name: 'Ukraine', id: 'uk', icon: 'flags.ua' },
    {},
    { name: 'South Africa', id: 'af', icon: '' },
    { name: 'Albania', id: 'sq', icon: '' },
    { name: 'Armenia', id: 'hy', icon: '' },
    { name: 'Azerbaijan', id: 'az', icon: '' },
    { name: 'Russia', id: 'ba', icon: '' },
    { name: 'Basque Country', id: 'eu', icon: '' },
    { name: 'Bosnia', id: 'bs', icon: '' },
    { name: 'France', id: 'br', icon: '' },
    { name: 'Myanmar', id: 'my', icon: '' },
    { name: 'Spain', id: 'ca', icon: '' },
    { name: 'Cambodia', id: 'km', icon: '' },
    { name: 'Russia', id: 'cv', icon: '' },
    { name: 'France', id: 'co', icon: '' },
    { name: 'Croatia', id: 'hr', icon: '' },
    { name: 'Global', id: 'eo', icon: '' },
    { name: 'Spain', id: 'gl', icon: '' },
    { name: 'Uganda', id: 'lg', icon: '' },
    { name: 'Georgia', id: 'ka', icon: '' },
    { name: 'India', id: 'gu', icon: '' },
    { name: 'Indonesia', id: 'id', icon: '' },
    { name: 'Iceland', id: 'is', icon: '' },
    { name: 'Global', id: 'ia', icon: '' },
    { name: 'Global', id: 'ie', icon: '' },
    { name: 'Kazakhstan', id: 'kk', icon: '' },
    { name: 'Kyrgyzstan', id: 'ky', icon: '' },
    { name: 'Kurdistan', id: 'ku', icon: '' },
    { name: 'Rome', id: 'la', icon: '' },
];

export default options
