<template>
    <div>
        <ui-async :guards="channel">
            <div class="bg-cover bg-no-repeat bg-opacity-90 rounded-t-md [text-shadow:_0_2px_6px_var(--tw-shadow-color)] shadow-gray-900"
                 :style="{ backgroundImage: channel.image ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${channel.image})` : 'none', opacity: 0.9 }"
            >
                <div class="items-center py-3 px-5">
                    <a href="#" @click.prevent="hideDetail" class="float-right">
                        <ui-icon name="x"></ui-icon>
                    </a>

                    <a v-if="channels?.length" href="#" class="float-left mr-1 font-bold" @click.prevent="detailBack" title="Back to event list">
                        <ui-icon name="arrow-left"></ui-icon>
                    </a>

                    <h1 class="text-sm font-medium">Channel</h1>
                </div>

                <div class="px-5 mt-5 flex items-center">
                    <div class="h-16 w-16">
                        <ui-avatar :name="channel.internationalTitle || channel.title" :image="channel?.avatarUrl"  class="h-16 w-16 rounded-full bg-gray-400"></ui-avatar>
                    </div>
                    <h2 class="px-5 text-3xl text-gray-100 font-medium">{{ channel.title }}</h2>
                </div>

                <div class="clear-both"></div>

                <div class="flex justify-between space-x-5 border-b border-gray-500 px-5 mt-5">
                    <div class="flex items-center space-x-5">
                        <a href="#" @click.prevent="shownTab = 'overview'" :class="shownTab == 'overview' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                            Overview
                        </a>
                        <a href="#" @click.prevent="shownTab = 'reports'" :class="shownTab == 'reports' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                            Reports
                        </a>
                    </div>

                    <div class="flex items-center space-x-5">
                        <a href="#" @click.prevent="shownTab = 'debug'" :class="shownTab == 'debug' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium" v-tooltip="'Debug'">
                            <ui-icon name="tool"></ui-icon>
                        </a>

                        <a href="#" @click.prevent="copyLink(channel.gid)" class="border-b-2 border-transparent" v-tooltip="'Copy channel link'">
                            <ui-icon name="share"></ui-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ui-async>
    </div>

    <div class="flex-1 min-h-0 overflow-auto">
        <overview-tab v-if="shownTab == 'overview'"></overview-tab>
        <reports-tab v-if="shownTab == 'reports'"></reports-tab>
        <debug-tab v-if="shownTab == 'debug'"></debug-tab>
    </div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'

import useChannelDetailStore from "@/stores/channels/channel-detail"
import OverviewTab from "@/components/channels/detail/overview-tab.vue"
import ReportsTab from "@/components/channels/detail/reports-tab.vue"
import DebugTab from "@/components/channels/detail/debug-tab.vue"
import useMyNotificationsStore from "@/stores/me/notifications"


export default {

    components: {
        OverviewTab, ReportsTab, DebugTab
    },

    computed: {
        ...mapState(useChannelDetailStore, [ 'channel', 'hideDetail', 'channels', 'detailBack' ]),
        ...mapWritableState(useChannelDetailStore, [ 'shownTab' ]),
    },

    methods: {
        copyLink(channelId) {
            navigator.clipboard.writeText(window.location.origin + '/channels/' + channelId)
            useMyNotificationsStore().pushToast({
                type: 'info',
                title: 'Channel link copied to clipboard',
                lifetime: 2
            })
        }
    }
}
</script>
