import api from '@/api'

import { defineStore } from 'pinia'

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

export const useMyBroadcasterStore = defineStore({
    id: 'myBroadcaster',

    state: () => ({
        isConnected: false,
        connectPromise: null,

        broadcaster: null,
    }),

    actions: {
        async initialize() {
            window.Pusher = Pusher

            await this.connect()
        },

        connect() {
            if (this.isConnected) return
            if (this.connectPromise) return this.connectPromise

            this.broadcaster = new Echo({
                broadcaster: 'pusher',
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            api.url('/api/broadcasting/auth').json({
                                socket_id: socketId,
                                channel_name: channel.name
                            }).post()
                                .json(response => {
                                    callback(false, response)
                                })
                                .catch(error => {
                                    callback(true, error)
                                });
                        }
                    }
                },
                key: import.meta.env.VITE_PUSHER_APP_KEY,
                cluster: 'mt1',
                wsHost: import.meta.env.VITE_PUSHER_HOST,
                wsPort: import.meta.env.VITE_PUSHER_PORT,
                wssPort: import.meta.env.VITE_PUSHER_PORT,
                forceTLS: false,
                encrypted: true,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
            });

            return this.connectPromise = new Promise((accept, reject) => {
                this.broadcaster.connector.pusher.connection.bind('connected', (event) => {
                    accept()
                    this.isConnected = true
                })
            })
        }
    }
})

export default useMyBroadcasterStore
