<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-600" :class="{ 'bg-gray-600': focused }" @click.prevent>
        <div class="flex items-center">

            <div class="shrink-0 relative">
                <img :src="`/flags/${result.countryCode.toLowerCase()}.svg`" class="w-4" v-if="result.countryCode">
                <ui-icon v-else name="map" class="text-xl"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-200">
                    <div class="truncate">
                        <span v-if="result.title">{{ result.title }}</span>
                        <span v-if="result.title != result.titleInternational">
                            <span v-if="result.title?.length">(</span>
                            {{ result.titleInternational }}
                            <span v-if="result.title?.length">)</span>
                        </span>
                    </div>
                </div>

                <div class="text-xs leading-4 text-gray-300 truncate capitalize">
                    {{ result.boundaryType }}
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: 'overview' }
        }
    },
}
</script>
