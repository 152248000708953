<template>
    <component :is="store.inline ? 'div' : 'ui-modal'" :class="store.inline ? 'bg-white flex rounded-md h-screen-4/6' : ''" unstyled :name="store.name" :adaptive="true" width="90%" height="90%" :max-width="false" @opened="opened" @closed="closed" @keydown="keyPressed">
        <div v-if="isOpen || store.inline" class="bg-white flex rounded-md h-full w-full">
            <div class="flex-1 bg-gray-900 outline-none rounded-l-md relative" tabindex="-1" ref="focusable">
                <img v-if="currentMedia.type == 'image'" class="w-full h-full object-contain" :src="currentMedia.src">

                <div v-if="currentMedia.type == 'image'" class="absolute inset-0 flex items-center justify-center">
                    <div class="relative" :style="imageOverlayStyle(imageOverlay)">
                        <div class="border-2 border-blue-500 absolute" :style="regionStyle(region)" :key="index" v-for="region, index in store.regions"></div>
                    </div>
                </div>

                <content-video v-if="currentMedia.type == 'video'" :images="store.media" :video="currentMedia"></content-video>
            </div>

            <div class="w-80 bg-white flex flex-col rounded-r-md" v-if="store.sidebar">
                <div class="flex items-center p-4 pr-6 pb-0" v-if="! store.inline">
                    <a href="#" class="text-3xl" :class="{'text-gray-700 hover:text-gray-900': ! isFirst, 'text-gray-200': isFirst}" @click.prevent="store.previous()">
                        <ui-icon name="chevron-left"></ui-icon>
                    </a>
                    <a href="#" class="ml-2 text-3xl" :class="{'text-gray-700 hover:text-gray-900': ! isLast, 'text-gray-200': isLast}" @click.prevent="store.next()">
                        <ui-icon name="chevron-right"></ui-icon>
                    </a>

                    <a href="#" class="ml-auto text-2xl text-gray-700 hover:text-gray-900" @click.prevent="$modal.hide(store.name)">
                        <ui-icon name="remove"></ui-icon>
                    </a>
                </div>

                <div class="flex-1 overflow-y-auto">
                    <slot name="sidebar"></slot>
                </div>
            </div>
        </div>
    </component>
</template>

<script>
//import ContentVideo from '@/components/content/stream-cards/partials/video'

import debounce from 'just-debounce-it'

export default {
//    components: { ContentVideo },

    data: () => ({
        isOpen: false,
        imageOverlay: {}
    }),

    computed: {
        currentMedia() { return this.store.currentMedia },

        isFirst() { return this.store.isFirst },

        isLast() { return this.store.isLast }
    },

    methods: {
        opened() {
            this.isOpen = true

            if (this.currentMedia.type === 'image') {
                this.$nextTick(() => {
                    this.$refs.focusable.focus()
                })

                this.recalculateImageOverlay()
            }
        },

        closed() {
            this.open = false
            this.store.$reset()
        },

        keyPressed(ev) {
            if (ev.key === 'Escape') return this.$modal.hide(this.store.name)
            if (ev.key === 'ArrowLeft') return this.store.previous()
            if (ev.key === 'ArrowRight') return this.store.next()
        },

        recalculateImageOverlay() {
            let mediaSize = {
                height: this.currentMedia.height,
                width: this.currentMedia.width
            }

            if (! this.$refs.focusable) return

            let containerSize = {
                height: this.$refs.focusable.offsetHeight,
                width: this.$refs.focusable.offsetWidth
            }

            let ratio = Math.min(containerSize.width / mediaSize.width, containerSize.height / mediaSize.height)

            this.imageOverlay = {
                height: mediaSize.height * ratio,
                width: mediaSize.width * ratio
            }
        },

        imageOverlayStyle(overlay) {
            return `width:${overlay.width}px;height:${overlay.height}px;`
        },

        regionStyle(region) {
            return `top:${region.top * 100}%;left:${region.left * 100}%;width:${region.width * 100}%;height:${region.height * 100}%`
        }
    },

    watch: {
        async currentMedia() {
            await this.$nextTick()
            this.recalculateImageOverlay()
        }
    },

    mounted() {
        window.addEventListener('resize', debounce(() => this.recalculateImageOverlay()), 50)
    }
}
</script>
