<template>
    <ui-dropdown width="w-80" class="w-80">
        <template v-slot:trigger>
            <div class="inline-flex items-center px-3 pr-2 h-9 text-sm bg-gray-800 border border-gray-500 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 focus:ring-gerulata-green-100 cursor-pointer rounded-md shadow-sm w-full">
                <span class="flex-1">
                    <template v-if="selectedOptions.length == 1">
                        {{selectedOptions[0].name}}
                    </template>
                    <template v-else-if="selectedOptions.length">
                        {{selectedOptions.length}} Types
                    </template>
                    <template v-else>
                        Filter by type
                    </template>
                </span>
                <ui-icon name="x" class="ml-2" @click.stop="clearType"></ui-icon>
                <ui-icon name="select" class="ml-2"></ui-icon>
            </div>
        </template>
        <template v-slot:content>
            <div class="px-2 py-1" @click.stop>
                <div class="relative w-full h-7">
                    <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                        <ui-icon name="search" class="text-sm"></ui-icon>
                    </div>
                    <input placeholder="Search..." type="search" autocomplete="off" class="block h-full w-full pl-7 rounded-sm bg-gray-800 border border-gray-500 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 focus:ring-gerulata-green-100 text-sm" ref="searchInput" v-model="search">
                </div>
            </div>

            <ui-dropdown-separator></ui-dropdown-separator>

            <div class="max-h-80 my-2 overflow-y-auto">
                <ui-dropdown-link @click="toggleType(option.id)" :selected="types.includes(option.id)" :checkbox="true" keep-open :key="option.id" v-for="option in shownOptions">
                    {{option.name}}
                </ui-dropdown-link>
            </div>
        </template>
    </ui-dropdown> 
</template>

<script>
import useAuditLogStore from '@/stores/settings/audit-log/audit-log'

import textSearch from '@/helpers/text-search'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        options() {
            return [
                { name: 'Authentication - Account Activated', id: 'auth.activated' },
                { name: 'Authentication - Logged In', id: 'auth.logged-in' },
                { name: 'Authentication - Logged Out', id: 'auth.logged-out' },
                { name: 'Authentication - Password Reset Requested', id: 'auth.password-reset' },
                { name: 'Events - Tag Added', id: 'sentinel.events.tag-added' },
                { name: 'Events - Tag Removed', id: 'sentinel.events.tag-removed' },
                { name: 'Monitoring Request - Created', id: 'monitoring-requests.created' },
                { name: 'Users - Created', id: 'management.users.created' },
                { name: 'Users - Deleted', id: 'management.users.deleted' },
                { name: 'Users - Updated', id: 'management.users.updated' },
                { name: 'Workspaces - Created', id: 'me.workspaces.created' },
                { name: 'Workspaces - Deleted', id: 'me.workspaces.deleted' },
                { name: 'Workspaces - Member Added', id: 'me.workspaces.member-added' },
                { name: 'Workspaces - Member Removed', id: 'me.workspaces.member-removed' },
                { name: 'Workspaces - Updated', id: 'me.workspaces.updated' },
            ]
        },
        
        shownOptions() {
            return textSearch(this.search, this.options, o => o.name)
        },
        
        selectedOptions() {
            return this.types.map(id => this.options.find(o => o.id == id))
        },
        
        ...mapState(useAuditLogStore, [ 'types' ])
    },
    
    data: () => ({
        search: ''
    }),
    
    methods: {
        ...mapActions(useAuditLogStore, [ 'clearType', 'toggleType' ])
    }
}
</script>