<template>
    <div class="w-11/12 rounded-xl p-3 leading-snug border shadow-sm" :class="{ 'mr-auto bg-gray-800 border-gray-600 prose-invert pb-1': message.from == 'agent', 'ml-auto bg-blue-900 border-blue-600 text-white prose-invert': message.from == 'customer', 'prose prose-sm ': ! message.file }">
        <div v-if="message.file" class="flex items-center gap-x-3">
            <img v-if="message.file.mime.startsWith('image')" :src="message.file.url" class="w-24 rounded shadow">
            <ui-icon v-else name="file-text" class="text-3xl" :class="{ 'text-gray-600': message.from == 'agent', 'text-gray-300': message.from == 'customer' }"></ui-icon>
            
            <div class="flex-1">
                <div class="text-sm">{{message.file.name}}</div>
                <div class="text-xs text-gray-300">{{message.file.size}}</div>
            </div>
            
            <a :href="message.file.url" target="_blank">
                <ui-icon name="download" class="text-lg text-gray-100"></ui-icon>
            </a>
        </div>
        
        <div v-else class="-my-4" v-html="text"></div>
        
        <div class="text-gray-500 text-2xs text-right mt-5" v-if="message.from == 'agent'">
            {{message.agentName}} &middot; {{messageDate}}
        </div>
    </div>
</template>

<script>
import { removeHTML, sanitizeHTML } from '@/helpers/html'

import { format, parseISO } from 'date-fns'
import { marked } from 'marked'

export default {
    props: {
        message: {
            type: Object,
            required: true,
        }
    },

    computed: {
        text() {
            return this.message.from == 'agent'
                ? sanitizeHTML(marked.parse(this.message.body))
                : `<p>${removeHTML(this.message.body).replaceAll(/\n/g, '<br>')}</p>`
        },
        
        messageDate() {
            let diffInSeconds = ((new Date) - parseISO(this.message.createdAt)) / 1000

            if (diffInSeconds < 60) {
                return 'now'
            } else if (diffInSeconds < 60 * 60) {
                return `${Math.round(diffInSeconds / 60)}m ago`
            } else if (diffInSeconds < 60 * 60 * 24) {
                return format(parseISO(this.message.createdAt), 'H:mm')
            } else {
                return format(parseISO(this.message.createdAt), 'LLL do')
            }
        }        
    }
}
</script>

<style>
.message-text h1 { font-size: 24px; line-height: 30px }
.message-text h2 { font-size: 20px; line-height: 26px }
.message-text h3 { font-size: 16px; line-height: 22px }

.message-text p:not(:last-child) { padding-bottom: 16px; }

.message-text p:after {
    content: "";
    display: inline-block;
    width: 0;
}

.message-text ul { list-style-type: disc; padding: 0 1rem }
.message-text ol { list-style-type: decimal; padding: 0 1rem }

.message-text li {
    padding-bottom: 2px;
}

.message-text a {
    font-weight: bold;
    text-decoration: underline;
}
</style>
