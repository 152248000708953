import DeleteConfirmation from '@/components/modals/delete-confirmation'

import api from '@/api'
import { alert } from '@/helpers/modals'

import { defineStore } from 'pinia'

export const useModalsDeleteConfirmationStore = defineStore({
    id: 'modalsDeleteConfirmation',

    state: () => ({
        entity: null,
        dependents: {}
    }),

    getters: {
        hasDependents(store) {
            return store.dependents?.length
        }
    },

    actions: {
        async open(entity, item, usedByRoute) {
            this.entity = entity
            this.dependents = []

            this.loadDependents(item, usedByRoute)

            return alert({
                component: DeleteConfirmation,
                props: { store: this },
                color: 'red'
            })
        },

        async loadDependents(item, usedByRoute) {
            if (! usedByRoute) return

            this.dependents = await api.route(usedByRoute, [ item.id ]).get().json()
        }
    }
})

export default useModalsDeleteConfirmationStore
