import api from '@/api'

import { defineStore } from 'pinia'
import { initializeApp as fbInitializeApp } from 'firebase/app'
import { getMessaging as fbGetMessaging, getToken as fbGetToken, onMessage as fbOnMessage } from 'firebase/messaging'
import { detect as detectBrowser } from 'detect-browser'
import { v4 as uuidv4 } from 'uuid'

export const useMyPushStore = defineStore({
    id: 'myPush',

    state: () => ({
        isInitialized: false
    }),

    actions: {
        async initialize() {
            this.setup()
        },

        setup() {
            if (this.isInitialized) return
            if (! import.meta.env.VITE_FIREBASE_API_KEY) return
            if (Notification.permission !== 'granted') return

            fbInitializeApp({
                apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
                authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
                projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
                storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
                appId: import.meta.env.VITE_FIREBASE_APP_ID
            })

            fbGetToken(fbGetMessaging(), {
                vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY
            }).then(token => {
                const browser = detectBrowser()
                if (! localStorage.getItem('deviceId')) { localStorage.setItem('deviceId', uuidv4()) }

                api.route('me devices store')
                    .formData({
                        deviceId: localStorage.getItem('deviceId'),
                        deviceType: `${browser.name} ${browser.version}`,
                        fcmToken: token
                    }).post()
            })

            fbOnMessage(fbGetMessaging(), payload => {
                const notification = new Notification(payload.notification.title, {
                    body: payload.notification.body,
                    icon: '/g.png'
                })

                notification.onclick = (e) => {
                    window.location.href = e.target.link
                }
            })
        }
    }
})

export default useMyPushStore
