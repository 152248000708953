<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-600" :class="{ 'bg-gray-600': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-avatar :name="result.title" :image="avatarImageUrl"  class="h-8 w-8 rounded-full bg-gray-400"></ui-avatar>
                <ui-icon :name="`badges.channel-${result.type}`" class="absolute rounded-full ring-1 ring-gray-600 -right-px -bottom-px text-base"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-200">
                    <div class="truncate">
                        {{ result.title }}
                        <span v-if="result.title != result.titleInternational">({{ result.titleInternational }})</span>
<!--                        <span class="capitalize text-gray-300">{{ result.type.replace('-', ' ') }}</span>-->
                    </div>
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-400"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        masterType() {
            return this.result.id.charAt(0) === 'G' ? 'group' : 'channel'
        },

        avatarImageUrl() {
            // TODO refactor this
            return this.result.avatarImageUrl ? `https://img.gerulata.com/${this.result.avatarImageUrl}` : null
        },

        to() {
            return { name: 'overview' }
        },

        typeName() {
            return {
                'facebook-group': 'Facebook Group',
                'facebook-page': 'Facebook Page',
                'facebook-user': 'Facebook User',
                'telegram-channel': 'Telegram Channel',
                'telegram-group': 'Telegram Group',
                'telegram-user': 'Telegram User',
                'twitter-user': 'Twitter User',
                'vkontakte-community': 'VKontakte Community',
                'vkontakte-user': 'VKontakte User',
                'web-feed': 'Web Feed',
                'web-site': 'Web Site',
                'youtube-channel': 'Youtube Channel'
            }[this.result.type] || 'Unknown type'
        }
    },
}
</script>
