<template>
    <div>
        <component :is="store.layout.component" :store="store">
            <template v-slot:item="{ item }">
                <slot name="item" :item="item" :cards-store="store">
                    <alert-card :alert="item" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'alerts'"></alert-card>
                    <alert-trigger-card :trigger="item" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'alert-triggers'"></alert-trigger-card>
                </slot>
            </template>

            <template v-slot:placeholder="{ item }">
                <slot name="placeholder" :item="item" :cards-store="store">
                    <placeholder-card v-if="store.layout.id == 'grid'"></placeholder-card>
                    <placeholder-row v-if="store.layout.id == 'table'"></placeholder-row>
                </slot>
            </template>

            <template v-slot:empty>
                <slot name="empty"></slot>
            </template>
        </component>
    </div>
</template>

<script>
import AlertCard from '@/components/alerts/cards/alert'
import AlertTriggerCard from '@/components/alerts/cards/trigger'
import PlaceholderCard from './placeholder'
import PlaceholderRow from './placeholder-row'

import breakpoint from '@/helpers/breakpoint'

export default {
    components: {
        AlertCard, AlertTriggerCard, PlaceholderCard, PlaceholderRow
    },

    data: () => ({
        breakpoint: breakpoint,
        layoutWidth: null
    }),

    methods: {
        updateLayouts() {
            this.store.refreshLayouts && this.store.refreshLayouts(this.$el.getBoundingClientRect().width)
        }
    },

    watch: {
        'breakpoint.w'() { this.updateLayouts() }
    },

    mounted() {
        this.updateLayouts()
    }
}
</script>
