import useEventCategoriesStore from '@/stores/me/event-categories'
import useChannelMapStore from "@/stores/channels/channel-map"

import { defineStore } from 'pinia'


export const useChannelsPage = defineStore({
    id: 'channelsPage',

    actions: {
        async afterEnter(to) {
            await useEventCategoriesStore().initialize()
        },

        async afterLeave(to, from) {
            useChannelMapStore().reset()
        }
    }
})

export default useChannelsPage
