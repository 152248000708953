import { createRouter, createWebHistory } from 'vue-router'
import queryString from 'query-string'

import useMyStore from '@/stores/me/my'

let router

export function setupRouter(app, routes) {
    router = createRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        stringifyQuery: query => {
            let val = queryString.stringify(query).replace(/%5B/g, '[').replace(/%5D/g, ']')
            return val ?? ''
        },
        routes
    })

    router.beforeEach(async (to, from, next) => {
        // Reset content scroll position
        document.querySelector('#app-content')?.scrollTo({ top: 0 })

        let authenticatedOnly = to.matched.some(m => m?.meta?.authenticatedOnly)
        let guestOnly = to.matched.some(m => m?.meta?.guestOnly)

        await useMyStore().initialize()

        if (authenticatedOnly && ! useMyStore().isAuthenticated) {
            useMyStore().intendedPath = to.fullPath
            return next({ name: 'login' })
        }

        if (guestOnly && useMyStore().isAuthenticated) {
            return next({ name: 'overview' })
        }

        let requiredLicenseFeatures = to.matched.filter(m => m?.meta?.requiredLicenseFeatures).reverse()?.[0]?.meta?.requiredLicenseFeatures

        if (requiredLicenseFeatures) {
            if (! useMyStore().hasFeatureTo(...requiredLicenseFeatures)) {
                return next({name: 'content'})
            }
        }

        let fromPages = from.matched.filter(m => m?.meta?.page).map(m => m.meta.page)
        let toPages = to.matched.filter(m => m?.meta?.page).map(m => m.meta.page)

        router.currentPage = toPages.length ? toPages[toPages.length - 1]() : null

        for (let fromPage of fromPages) {
            try {
                if (fromPage && fromPage().beforeLeave) await fromPage().beforeLeave(to, from)
            } catch (e) {
                if (e == 'stop-navigation') return next(false)
                throw e
            }
        }

        for (let toPage of toPages) {
            try {
                if (toPage && toPage().beforeEnter) await toPage().beforeEnter(to, from)
            } catch (e) {
                if (e == 'stop-navigation') return next(false)
                throw e
            }
        }

        next()
    })

    router.afterEach(async (to, from) => {
        document.title = 'Gerulata Sentinel'

        let fromPages = from.matched.filter(m => m?.meta?.page).map(m => m.meta.page)
        let toPages = to.matched.filter(m => m?.meta?.page).map(m => m.meta.page)

        for (let fromPage of fromPages) {
            try {
                if (fromPage && fromPage().afterLeave) await fromPage().afterLeave(to, from)
            } catch (e) {
                if (e == 'skip-navigation-middleware') return
                throw e
            }
        }

        for (let toPage of toPages) {
            try {
                if (toPage && toPage().afterEnter) await toPage().afterEnter(to, from)
            } catch (e) {
                if (e == 'skip-navigation-middleware') return
                throw e
            } finally {
                if (toPage().title) document.title = `${toPage().title} | Gerulata Sentinel`
            }
        }
    })

    app.use(router)

    return router
}

export default function useRouter() {
    return router
}

export function useRoute() {
    return router.currentRoute.value
}
