<template>
    <div class="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm py-16 z-[9999]" @keydown="keyDown" @keyup="keyUp" v-if="isShown" @click.prevent>
        <div class="w-full max-w-2xl mx-auto text-sm text-gray-300 font-semibold px-4 mb-1 text-right">
            Esc to close
        </div>

        <div class="w-full max-w-2xl mx-auto bg-gradient-to-b from-gray-600/80 to-gray-700/80 backdrop-blur border border-gray-500/70 rounded-md shadow-md max-h-full flex flex-col overflow-hidden p-4" v-click-away="hide">
            <div class="flex items-center">
                <form class="relative grow" @submit.prevent>
                    <div class="absolute top-0 left-4 h-9 flex items-center pointer-events-none text-xl z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="search" @input="loadDebounced" @keydown.esc="hide"
                        autocomplete="off" spellcheck="false"
                        class="block w-full h-9 pl-10 pr-28 py-3 rounded-md border border-gray-500 focus:border-gerulata-green-100 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400 sm:text-sm z-30 bg-gray-800 text-gray-200" placeholder="Search...">
                </form>
            </div>

            <div class="text-gray-300 text-center" v-if="inMaintenance">
                Quick-search service is currently under maintenance, please try again in a few moments.
            </div>

            <div class="flex items-center mt-3">
                <a href="#" @click.prevent="resetFilteredFamilies" class="inline-flex items-center text-sm font-medium leading-none rounded-md px-3 h-6" :class="! filteredFamilies.length ? 'bg-gerulata-green-700 text-gerulata-green-100' : 'text-gray-300 hover:text-gray-200'">
                    All <span v-if="showKeyboardShortcuts" class="ml-1 text-xs text-gray-500">^1</span>
                </a>
                <a v-for="family, index in supportedFamilies" :key="family.id" href="#" @click.prevent="setFilteredFamilies([family.id])" class="inline-flex items-center text-sm font-medium leading-none rounded-md px-3 h-6" :class="isFamilyIncludedInFilter(family.id) ? 'bg-gerulata-green-700 text-gerulata-green-100' : (families.includes(family.id) ? 'text-gray-300 hover:text-gray-100' : 'text-gray-500 cursor-default')">
                    {{family.name}} <span v-if="showKeyboardShortcuts" class="ml-1 text-xs text-gray-500">^{{index + 2}}</span>
                </a>
            </div>

            <div class="overflow-y-auto divide-y divide-gray-600 mt-3 -mx-4 -mb-4" ref="results" v-if="filteredResults.length > 0">
                <div :key="index" v-for="result, index in filteredResults">
                    <sentinel-channel-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)"  v-if="result.resultType == 'channel'"></sentinel-channel-result>
                    <geo-boundary-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)"  v-if="result.resultType == 'sentinelBoundaries'"></geo-boundary-result>
                    <geo-feature-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-if="result.resultType == 'geo-feature'"></geo-feature-result>
                    <perspective-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)"  v-if="result.resultType == 'perspective'"></perspective-result>
                </div>
            </div>

            <div v-if="search && ! filteredResults.length" class="mt-4 py-4 text-sm text-gray-300 text-center">
                We didn't find any results.
            </div>
        </div>

        <div class="w-full max-w-2xl mx-auto text-sm text-gray-300 font-semibold px-4 mt-1">
            <div>Press <span class="font-mono text-xs">/</span> or <span class="font-mono text-xs">{{ isMac ? 'CMD+P' : 'CTRL+P' }}</span> to quickly open quick-search from anywhere, hold {{ isMac ? 'CTRL' : 'ALT' }} to switch categories</div>
        </div>
    </div>
</template>

<script>
import SentinelChannelResult from './results/sentinel-channel-result'
import GeoBoundaryResult from "./results/geo-boundary-result.vue"
import PerspectiveResult from './results/perspective-result'

import useMyQuickSearchStore from '@/stores/me/quick-search'
import useMyWorkspacesStore from '@/stores/me/workspaces'
import GeoFeatureResult from "./results/geo-feature-result.vue"


import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: {
        SentinelChannelResult, GeoBoundaryResult, GeoFeatureResult, PerspectiveResult
    },

    data: () => ({
        focusedResult: null,
        focusedResultRef: null,
        showKeyboardShortcuts: false,

        supportedFamilies: [
            { id: 'sentinelChannels', name: 'Channels' },
            { id: 'sentinelBoundaries', name: 'Geo Boundaries' },
            { id: 'geoFeatures', name: 'Geo Features' },
            { id: 'perspectives', name: 'Perspectives' }
        ]
    }),

    computed: {
        ...mapState(useMyQuickSearchStore, [
            'isShown',
            // 'hasFilterableFamilies',
            // 'hasFilterableWorkspaces',
            'inMaintenance',
            'onSelect',
            'filteredResults',
            // 'resultsPending',
            'filteredFamilies',
            // 'workspace',
            'families'
        ]),
        ...mapState(useMyWorkspacesStore, [ 'globalWorkspace', 'personalWorkspace', 'regularWorkspaces' ]),
        ...mapWritableState(useMyQuickSearchStore, [ 'search' ])
    },

    methods: {
        ...mapActions(useMyQuickSearchStore, [
            'hide',
            'loadDebounced',
            'clear',
            'shouldSearch',
            'resetFilteredFamilies',
            'setFilteredFamilies',
            'isFamilyIncludedInFilter',
            'resetWorkspace',
            'setWorkspace'
        ]),

        keyDown(ev) {
            if ([ 'Control', 'Alt' ].includes(ev.key)) this.showKeyboardShortcuts = true

            if ([ 'ArrowUp', 'ArrowDown', 'Enter' ].includes(ev.key)) return this.handleVerticalNavigation(ev)
            if ((ev.ctrlKey || ev.altKey) && [ '1', '2', '3', '4', '5', '6', '7', '8' ].includes(ev.key)) return this.handleHorizontalNavigation(ev)
        },

        keyUp(ev) {
            if ([ 'Control', 'Alt' ].includes(ev.key)) this.showKeyboardShortcuts = false
        },

        handleVerticalNavigation(ev) {
            if (ev.key == 'Enter') {
                ev.preventDefault()
                return this.selectResult(this.focusedResult)
            }

            ev.preventDefault()

            let selectPrevious = ev.key == 'ArrowUp'
            let currentIndex = this.filteredResults.indexOf(this.focusedResult)

            let focusIndex = currentIndex === false
                ? (selectPrevious ? this.filteredResults.length - 1 : 0)
                : (selectPrevious ? currentIndex - 1 : currentIndex + 1)

            if (focusIndex >= this.filteredResults.length) focusIndex = 0
            if (focusIndex < 0) focusIndex = this.filteredResults.length - 1

            this.focusedResult = this.filteredResults[focusIndex]
            this.focusedResultRef = this.$refs[`result-${focusIndex}`][0]

            this.focusedResultRef.$el.scrollIntoView({ block: 'nearest' })
        },

        handleHorizontalNavigation(ev) {
            if (ev.key == '1') return this.resetFilteredFamilies()

            let family = this.supportedFamilies[parseInt(ev.key) - 2]?.id

            if (this.families.includes(family)) this.setFilteredFamilies([ family ])
        },

        selectResult(result) {
            if (! result) return

            if (this.onSelect && ! result.alwaysOpen) {
                this.onSelect(result)
            } else {
                this.$refs[`result-${this.filteredResults.indexOf(result)}`][0].open()
            }

            this.hide()
        }
    },

    watch: {
        isShown(val) {
            if (val) this.$nextTick(() => this.$refs.input.focus())
        }
    }
}
</script>
