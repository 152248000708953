<template>
    <ui-modal name="alert-create" title="New Alert" max-width="560">
        <div class="space-y-1.5 text-gray-200 w-full">
            <a href="#" class="flex items-center w-full px-5 py-2 cursor-pointer text-gray-300 hover:text-gray-100 border border-gray-600 rounded-lg text-lg group" @click.prevent="selectType(type.id)" :key="type.id" v-for="type in types">
                <div>
                    <ui-icon :name="type.icon" class="text-3xl"></ui-icon>
                </div>
                <div class="px-4">
                    <h1 class="text-lg font-medium">{{type.name}}</h1>
                    <p class="text-sm text-gray-400 group-hover:text-gray-100">{{type.description}}</p>
                </div>
                <div class="ml-auto">
                    <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                </div>
            </a>
        </div>
    </ui-modal>
</template>

<script>
import useAlertCreateStore from '@/stores/alerts/modals/alert-create'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useAlertCreateStore, [ 'types' ])
    },

    methods: {
        ...mapActions(useAlertCreateStore, [ 'selectType' ])
    }
}
</script>
