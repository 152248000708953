<template>

    <div class="mx-auto w-full max-w-4xl bg-gray-600/80 backdrop-blur rounded-lg">
        <div class="border-b border-gray-700 px-4 py-5 sm:px-6">
            <h3 class="text-lg font-semibold leading-6 text-gray-100">Gerulata Monitor</h3>
            <p class="mt-1 text-sm text-gray-300">Gerulata Monitor is a near-real time, massive scale web &amp; social media monitoring system.</p>
        </div>

        <div>
            <div class="mx-auto max-w-7xl">
                <div class="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
                    <div class="px-4 py-6 sm:px-6 lg:px-8">
                        <p class="text-sm font-medium leading-6 text-gray-400">Status</p>
                        <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl rounded-md bg-gerulata-green-100 w-2 h-100 inline-block">&nbsp;</span>
                            <span class="text-4xl font-semibold tracking-tight text-white">Healthy</span>
                        </p>
                    </div>
                    <div class="px-4 py-6 sm:px-6 lg:px-8">
                        <p class="text-sm font-medium leading-6 text-gray-400">Base Sources</p>
                        <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-white">11,072</span>
                        </p>
                    </div>
                    <div class="px-4 py-6 sm:px-6 lg:px-8">
                        <p class="text-sm font-medium leading-6 text-gray-400">Custom Sources</p>
                        <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-white">2</span>
                        </p>
                    </div>
                    <div class="px-4 py-6 sm:px-6 lg:px-8">
                        <p class="text-sm font-medium leading-6 text-gray-400">Uptime</p>
                        <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-white">99.9970</span>
                            <span class="text-sm text-gray-400">%</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- -->
        <div class="px-4 sm:px-6 lg:px-8 my-12">

            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-100">Monitoring Requests</h1>
                    <p class="mt-2 text-sm text-gray-300">List of custom monitoring requests in your organization.</p>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button type="button" class="block rounded-md bg-gerulata-green-100 px-3 py-2 text-center text-sm font-semibold text-gray-800 shadow-sm hover:bg-gerulata-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">New monitoring request</button>
                </div>
            </div>

            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-500 mb-6">
                            <thead>
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 sm:pl-0">Source</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Platform</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Status</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-300">Last Content At</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-600">
                            <tr v-for="person in people" :key="person.email">
                                <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    <div class="flex items-center">
                                        <div class="h-11 w-11 flex-shrink-0">
                                            <img class="h-11 w-11 rounded-full" :src="person.image" alt="" />
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium text-gray-100">{{ person.name }}</div>
                                            <div class="mt-1 text-gray-300">{{ person.username }}</div>
                                        </div>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <div class="text-gray-300">{{ person.platform }}</div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <span class="inline-flex items-center rounded-md bg-gerulata-green-100 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-green-600/20">Active</span>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-300">{{ person.lastContent }}</td>
                                <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <a href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300"
                                    >Edit<span class="sr-only">, {{ person.name }}</span></a
                                    >
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

// import UIIcon from "@/components/ui/icon.vue";

export default {
    // components: {UIIcon},
    data() {
        return {
            people: [
                {
                    name: 'Al-Jazeera News',
                    username: '@aljazeera-official',
                    platform: 'Telegram Channel',
                    status: 'Active',
                    lastContent: '2 minutes ago',
                    image:
                        'https://yt3.googleusercontent.com/YKrh5sIYpesEIHbjfgqwFjFx3ZgIjM_zak2z0LO8QENSgrmgKBes1m65POj9jkmzm0qoKwgl=s900-c-k-c0x00ffffff-no-rj',
                },
                {
                    name: 'Rybar',
                    username: '@rybar',
                    platform: 'Telegram Channel',
                    status: 'Active',
                    lastContent: '5 hours ago',
                    image:
                        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIwIiBoZWlnaHQ9IjMyMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI%2BPGRlZnM%2BPGxpbmVhckdyYWRpZW50IGlkPSJnIiB4MT0iMCUiIHgyPSIwJSIgeTE9IjAlIiB5Mj0iMTAwJSI%2BPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmY2Q2YSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmYTg1YyIvPjwvbGluZWFyR3JhZGllbnQ%2BPC9kZWZzPjxzdHlsZT50ZXh0e2ZvbnQ6NjAwIDQ0cHggLWFwcGxlLXN5c3RlbSxCbGlua01hY1N5c3RlbUZvbnQsJ1NlZ29lIFVJJyxSb2JvdG8sSGVsdmV0aWNhLEFyaWFsLHNhbnMtc2VyaWYsJ0FwcGxlIENvbG9yIEVtb2ppJywnU2Vnb2UgVUkgRW1vamknLCdTZWdvZSBVSSBTeW1ib2wnOy13ZWJraXQtdXNlci1zZWxlY3Q6bm9uZTt1c2VyLXNlbGVjdDpub25lfTwvc3R5bGU%2BPHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIGZpbGw9InVybCgjZykiLz48dGV4dCB0ZXh0LWFuY2hvcj0ibWlkZGxlIiB4PSI1MCIgeT0iNjYiIGZpbGw9IiNmZmYiPtCgPC90ZXh0Pjwvc3ZnPg%3D%3D',
                },
                // More people...
            ]
        }
    }
}
</script>