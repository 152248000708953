<template>
    <div class="py-4 px-5">
        <a href="#" @click.prevent="hideDetail" class="float-right">
            <ui-icon name="x"></ui-icon>
        </a>
        <div class="text-2xl">Channels</div>
    </div>

    <div class="py-4 px-5 space-y-4 border-t border-gray-500 w-full overflow-x-auto" >
        <channels-list :channels="channels" :channel-click="c => showChannelDetail(c.gid)" />
    </div>
</template>

<script>

import { mapState, mapActions } from "pinia"
import useChannelDetailStore from '@/stores/channels/channel-detail'
import ChannelsList from "@/components/overview/details/partials/channels-list.vue"

export default {
    components: {
        ChannelsList
    },

    computed: {
        ...mapState(useChannelDetailStore, [ 'channels' ])
    },

    methods: {
        ...mapActions(useChannelDetailStore, [ 'hideDetail', 'showChannelDetail' ])
    }
}
</script>
