import hotkeys from 'hotkeys-js'

import useMyQuickSearchStore from '@/stores/me/quick-search'

export default function setupShortcuts() {
    hotkeys('/, command+p, ctrl+p', ev => {
        ev.preventDefault()
        useMyQuickSearchStore().show({ hasFilterableWorkspaces: false, perspectiveStarters: true, tips: true })
    })

    hotkeys('shift+/, command+shift+p, ctrl+shift+p', ev => {
        ev.preventDefault()
        useMyQuickSearchStore().show({ hasFilterableWorkspaces: false, perspectiveStarters: true, tips: true, workspace: false })
    })
}
