<template>
    <search-filter name="Channel" :ids="['channels', 'channelCount', 'channelType']" :filters="filters" @opened="reset" :keep-open="keepOpen">
        <template v-slot:applied>
            <ui-icon name="navigation.targets" class="mr-1"></ui-icon>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} channels
            </span>
            <span class="font-medium max-w-sm truncate" v-else-if="value.length == 1">
                {{value.length ? value[0].name : value.name}}
            </span>
            <span class="font-medium max-w-sm truncate" v-else-if="typesValue.length">
                {{typesValue.length}} types
            </span>
            <span class="font-medium max-w-sm truncate" v-else>
                Reported by multiple channels
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <ui-dropdown-link :selected="onlyMultipleChannels" @click="toggleOnlyMultipleChannels" keep-open>
                    Reported by multiple channels
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link :selected="selectedTypes.length" submenu submenu-width="w-60" keep-open submenu-align="left">
                    Platform...

                    <template v-slot:submenu>
                        <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                            Platform
                        </div>

                        <ui-dropdown-link v-for="type in types" :key="type.id" @click="toggleType(type)" :icon="type?.icon" checkbox :selected="selectedTypes.includes(type.id)">{{type.name}}</ui-dropdown-link>
                    </template>
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link :selected="selectedChannelsToInclude.length" submenu submenu-width="w-60" keep-open submenu-align="left">
                    Only reports by...

                    <template v-slot:submenu >
                        <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                            Only reports by
                        </div>

                        <p class="text-xs px-4 pb-2 text-gray-400">
                            Show only events reported by at least one of these channels.
                        </p>

                        <ui-dropdown-link @click="deselectChannels(channel)" keep-open icon="none" :key="`${channel.family}-${channel.id}`" v-for="channel in selectedChannelsToInclude">
                            <template v-slot:icon>
                                <div class="w-4 flex justify-center items-center text-center">
                                    <ui-avatar type="target-list" :item="channel" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                </div>
                            </template>
                            <template v-slot:default>
                                <div class="flex items-center group">
                                    {{channel.name}}
                                    <ui-icon name="trash" class="text-gray-300 hidden group-hover:block ml-auto"></ui-icon>
                                </div>
                            </template>
                        </ui-dropdown-link>

                        <ui-dropdown-link icon="plus-circle" @click="selectChannelToInclude" keep-open>
                            Add Channel...
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown-link>

                <ui-dropdown-link :selected="selectedChannelsToExclude.length" submenu submenu-width="w-60" keep-open submenu-align="left">
                    Exclude reports by...

                    <template v-slot:submenu>
                        <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                            Exclude reports by
                        </div>

                        <p class="text-xs px-4 pb-2 text-gray-400">
                            Show only events with reports by at least one channel different than following.
                        </p>

                        <div class="max-h-80 overflow-y-auto">
                            <ui-dropdown-link @click="deselectChannels(channel)" keep-open icon="none" :key="`${channel.family}-${channel.id}`" v-for="channel in selectedChannelsToExclude">
                                <template v-slot:icon>
                                    <div class="w-4 flex justify-center items-center text-center">
                                        <ui-avatar type="target-list" :item="channel" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                    </div>
                                </template>
                                <template v-slot:default>
                                    <div class="flex items-center group">
                                        {{channel.name}}
                                        <ui-icon name="trash" class="text-gray-300 hidden group-hover:block ml-auto"></ui-icon>
                                    </div>
                                </template>
                            </ui-dropdown-link>
                        </div>

                        <ui-dropdown-link icon="plus-circle" @click="selectChannelToExclude" keep-open>
                            Add Channel...
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center text-gray-300" @click="filters.remove(['channels', 'channelCount', 'channelType']); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="green" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers/sorting'

export default {
    components: { SearchFilter },

    props: [ 'filters' ],
    
    data: () => ({
        selectedChannels: [],
        onlyMultipleChannels: false,
        selectedTypes: [],

        types: [
            // TODO add all categories we want to support; or load it from db maybe?
            { id: 18, name: 'Facebook Pages', icon: 'badges.target-facebook-page' },
            { id: 19, name: 'Facebook Profile', icon: 'badges.target-facebook-user' },
            { id: 49, name: 'Instagram Profile', icon: 'badges.target-instagram-profile' },
            { id: 81, name: 'Telegram Channels', icon: 'badges.target-telegram-channel' },
            { id: 83, name: 'Telegram Users', icon: 'badges.target-telegram-user' },
            { id: 97, name: 'TikTok Users', icon: 'badges.target-tiktok-user' },
            { id: 145, name: 'X Users', icon: 'badges.channel-x-user' },
            { id: 113, name: 'VKontakte Communities', icon: 'badges.target-vkontakte-community' },
            { id: 114, name: 'VKontakte Users', icon: 'badges.target-vkontakte-user' },
            { id: 129, name: 'Web Feeds', icon: 'badges.target-web-feed' },
            { id: 193, name: 'Web Sites', icon: 'badges.target-web-site' },
            { id: 161, name: 'Youtube Channels', icon: 'badges.target-youtube-channel' }
        ],

        keepOpen: false
    }),

    computed: {
        value() {
            return this.filters.value('channels') || []
        },

        selectedChannelsToInclude() {
            return sortAlphabetically(this.selectedChannels.filter(val => ! val.exclude))
        },

        selectedChannelsToExclude() {
            return sortAlphabetically(this.selectedChannels.filter(val => val.exclude))
        },

        typesValue() {
            return this.filters.value('channelType') || []
        }
    },

    methods: {
        selectChannelToInclude() {
            this.selectChannel()
        },

        selectChannelToExclude() {
            this.selectChannel(true)
        },

        selectChannel(exclude = false) {
            this.keepOpen = true

            useQuickSearchStore().show({
                families: [ 'sentinelChannels' ],
                limit: 50,
                onSelect: result => {
                    this.selectedChannels.push({
                        id: result.gid,
                        type: result.resultType == 'target-list' ? 'list' : (result.id[0] == 'G' ? 'group' : 'channel'),
                        name: result.title,
                        exclude
                    })

                    useQuickSearchStore().hide()
                },
                onHide: () => {
                    setTimeout(() => this.keepOpen = false, 500)
                }
            })
        },

        deselectChannels(channel) {
            this.selectedChannels = this.selectedChannels.filter(f => f.id != channel.id || f.type != channel.type)
        },

        toggleOnlyMultipleChannels() {
            this.onlyMultipleChannels = ! this.onlyMultipleChannels
        },

        toggleType(type) {
            this.selectedTypes.includes(type.id) ? this.selectedTypes = this.selectedTypes.filter(t => t != type.id) : this.selectedTypes.push(type.id)
        },

        reset() {
            this.selectedChannels = (this.filters.value('channels') || []).map(mention => ({ ...mention }))
            this.onlyMultipleChannels = !! this.filters.value('channelCount')
            this.selectedTypes = this.filters.value('channelType') || []
        },

        apply() {
            if (this.selectedChannels.length) {
                this.filters.set('channels', this.selectedChannels.map(mention => ({ ...mention })))
            } else {
                this.filters.remove('channels')
            }

            this.onlyMultipleChannels ? this.filters.set('channelCount', { gte: 2 }) : this.filters.remove('channelCount')
            this.selectedTypes.length ? this.filters.set('channelType', this.selectedTypes) : this.filters.remove('channelType')
        }
    }
}
</script>
