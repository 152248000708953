<template>
    <div class="flex items-center justify-center gap-x-1.5 text-3xl font-semibold text-center mb-1">
        {{$titleCase(trigger.alert.settings.frequency)}} digest for
        <span class="flex items-center gap-x-1">
            <ui-avatar :item="trigger.meta.subject" class="w-7 h-7"></ui-avatar> {{trigger.meta.subject.name}}
        </span>
    </div>

    <div class="flex items-center justify-center gap-x-1.5 mb-10 text-gray-700">
        {{date}}
    </div>

    <div class="flex justify-between mb-6">
        <div class="h-8 flex items-center gap-x-3">
            <div class="border border-gray-500 text-gray-300 hover:text-gray-100 rounded-md font-medium flex h-full">
                <a @click.prevent="activeTab = 'stream'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'stream' ? 'border border-gerulata-green-100 text-gerulata-green-100 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                    Stream
                </a>
                <a @click.prevent="activeTab = 'inspect'" href="#" class="flex items-center justify-center px-3 --inspect-button" :class="activeTab == 'inspect' ? 'border border-gerulata-green-100 text-gerulata-green-100 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                    Inspect
                </a>
            </div>
            <span>
                <span class="font-medium">{{trigger.meta.events.length}}</span> new items
            </span>
        </div>
        <div class="flex items-center gap-x-3">
            <router-link :to="{ name: 'overview.perspective', params: { perspectiveId: 'new' }, query: { 'filter[trigger]': `${trigger.id}|event|${trigger.createdAt}|${trigger.alert.name}` } }" target="_blank" class="flex items-center h-full text-gray-300 hover:text-gray-100 font-medium gap-x-1.5">
                <ui-icon name="navigation.event"></ui-icon>
                <span>Show all</span>
            </router-link>
        </div>
    </div>

    <ui-async v-show="activeTab == 'stream'" :guards="events.data" class="space-y-6 max-w-2xl mx-auto">
        <event-card v-for="event in events.data" :event="event" :key="event.id"></event-card>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadMore($event)"></ui-infinite-loading>

        <template v-slot:loading>
            <div class="flex items-center justify-center py-4 w-full h-120">
                <ui-spinner></ui-spinner>
            </div>
        </template>
    </ui-async>

    <div v-show="activeTab == 'inspect'">
        <div class="h-60 --timeline">
            <ui-analysis :store="{ analysisOptions, isRenderable: true }" class="max-w-2xl mx-auto mb-8"></ui-analysis>
        </div>
    </div>
</template>

<script>
import EventCard from '@/components/explore/cards/event'

import useTriggerDigestStore from '@/stores/alerts/trigger-digest'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        EventCard
    },

    data: () => ({
        activeTab: 'stream'
    }),

    computed: {
        ...mapState(useTriggerDigestStore, [ 'analysisOptions', 'events', 'lastReloadTime', 'trigger', 'widgets' ]),

        date() {
            return this.trigger.alert.settings.frequency == 'daily'
                ? this.$date(this.trigger.meta.dateRange.from)
                : `${this.$date(this.trigger.meta.dateRange.from)} - ${this.$date(this.trigger.meta.dateRange.to)}`
        },

        link() {
            return this.$router.resolve({ name: 'alerts.trigger', params: { alertId: this.trigger.alert.id, id: this.trigger.id } }).href
        }
    },

    methods: {
        ...mapActions(useTriggerDigestStore, [ 'loadMore' ]),

        copyLink() {
            navigator.clipboard.writeText(window.location.origin + this.link)
        }
    }
}
</script>
