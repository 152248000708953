<template>
    <div class="space-y-4 px-5 pt-5">
        <ui-async :guards="reports">
            <div v-for="report in reports" :key="report.gid" class="px-3 py-4 leading-tight mb-5 rounded-md bg-gray-600 block">
                <div class="float-right text-gray-300">
                    <ui-contextual-menu size="sm" icon="more-horizontal" :plain="true">
                        <ui-dropdown-link @click="junoLink(report)">Open content in Juno</ui-dropdown-link>
                        <ui-dropdown-link @click="showEvent(report?.eventGid)">Show event on map</ui-dropdown-link>
                    </ui-contextual-menu>
                </div>

                <div class=" items-center">
                    <div class="w-3 h-3 rounded-full inline-block mr-2" :style="{ backgroundColor: category(report)?.color }"></div>
                    <span class="capitalize">{{ category(report)?.name || "" }}</span>

                    <span v-for="subcategory in categories(report)" :key="subcategory" class="capitalize">
                        → {{ subcategory }}
                    </span>
                </div>

                <div class="mt-1">
                    <span class="text-sm text-gray-400">Created at: {{ $dateTime(report.extractedAt) }}</span>
                </div>

                <div class="text-2xl mt-1">{{ report.title }}</div>
                <div class="mt-3">{{ report.summary }}</div>
            </div>

            <ui-infinite-loading @infinite="loadReports" class="my-6" v-if="reports && (reports.length % 30 == 0) || reportsPage == 1"></ui-infinite-loading>
        </ui-async>
    </div>
</template>

<script>

import { mapState, mapActions } from "pinia"
import useChannelDetailStore from "@/stores/channels/channel-detail"
import useEventCategoriesStore from "@/stores/me/event-categories";


export default {

    computed: {
        ...mapState(useChannelDetailStore, [ 'reports', 'reportsPage' ]),

    },

    methods: {
        ...mapActions(useChannelDetailStore, [ 'loadReports' ]),

        category(report) {
            const categories = report.fullCategory.split('.')
            return useEventCategoriesStore().find(categories[0])
        },

        categories(report) {
            return report?.fullCategory?.split('.')?.slice(1).map(category => category.replaceAll('_', ' ')) || []
        },

        junoLink: function(item) {
            const juno = import.meta.env.VITE_JUNO_URL
            const contentId = item.contentGid
            const url = `${juno}/content/${contentId}`
            //
            window.open(url, '_blank').focus()
        },

        showEvent: function(eventGid) {
            window.open(`/overview/event/${eventGid}`, '_blank')
        },
    }
}
</script>
