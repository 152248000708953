import useMapStore from '@/stores/overview/map'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

export const usePerspectiveEditModalStore = defineForm({
    id: 'overviewModalsPerspectiveEdit',

    inputs: () => ({
        perspective: {},
        name: null,
        description: null,
        avatar: {},
        filters: null
    }),

    submitRequest() {
        if (! this.inputs.perspective?.id) {
            trackEvent('overview', 'new-perspective-saved')
        }

        let route = this.inputs.perspective && this.inputs.perspective.id
            ? [ 'me perspectives update', { id: this.inputs.perspective.id } ]
            : [ 'me perspectives store' ]

        return api.route(...route)
            .json({
                _method: this.inputs.perspective && this.inputs.perspective.id ? 'put' : 'post',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: {
                    color: this.inputs.avatar.color,
                    icon: this.inputs.avatar.icon,
                    imageId: this.inputs.avatar.imageId
                },
                type: 'sentinel',
                filters: this.inputs.filters,
                meta: JSON.stringify({
                    mapLayers: this.stream?.mapLayers.map(l => ({ id: l.id, show: l.show, filter: l.filter })),
                    mapCenter: useMapStore().getCenter(),
                    mapZoom: useMapStore().getZoom()
                })
            })
            .post()
    },

    async onResponse(res) {
        if (this.inputs.perspective?.id) Object.assign(this.inputs.perspective, res.data)

        await useMyPerspectivesStore().reload()

        useModal().hide('overview-perspective-edit')

        this.fulfillPromise(useMyPerspectivesStore().find(res.data.id))
    },

    state: () => ({
        stream: null
    }),

    actions: {
        open(perspective = {}, stream = null) {
            this.reset()

            this.inputs.perspective = perspective
            this.inputs.name = perspective?.name
            this.inputs.description = perspective?.description
            this.inputs.avatar = perspective?.avatar || {}

            this.inputs.filters = stream?.filters.toJson()

            this.stream = stream

            useModal().show('overview-perspective-edit')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('overview-perspective-edit')

            this.fulfillPromise()
        },

        async delete() {
            try {
                await useMyPerspectivesStore().delete({ id: this.inputs.perspective.id })
            } catch (e) {
                return
            }

            useModal().hide('overview-perspective-edit')

            this.fulfillPromise()
        }
    }
})

export default usePerspectiveEditModalStore
