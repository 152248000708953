import { asyncResource } from '@/api'

import { defineStore } from 'pinia'

export const useEventCategoriesStore = defineStore({
    id: 'eventCategories',

    state: () => ({
        resource: asyncResource({ request: (api) => api.route('sentinel events categories') }),

        isInitialized: false,
        initializationPromise: null
    }),

    getters: {
        categories(store) {
            const mapCategory = (category, parent = null) => {
                // Inherit properties from parent if not explicitly defined
                const mappedCategory = {
                    ...category,
                    parent,
                    fullName: parent ? `${parent.fullName || parent.name} • ${category.name}` : category.name,
                    color: category.color || parent?.color || null,
                    icon: category.icon || parent?.icon || null,
                };

                // Recursively map subcategories
                if (category.subcategories && category.subcategories.length) {
                    mappedCategory.subcategories = category.subcategories.map(subcategory =>
                        mapCategory(subcategory, mappedCategory)
                    );
                }

                return mappedCategory
            };

            return store.resource.data?.map(parent => mapCategory(parent)) || []
        },

        availableCategories(store) {
            return store.categories.filter(c => c.available)
                .map(c => ({ ...c, subcategories: c.subcategories.filter(sc => sc.available) }))
        },

        flatCategories(store) {
            const flatten = (categories, parentColor = null) => {
                return categories.reduce((results, category) => {
                    const categoryWithColor = { ...category, color: category.color || parentColor }
                    const children = category.subcategories || []
                    return [ ...results, categoryWithColor, ...flatten(children, categoryWithColor.color) ]
                }, [])
            }

            return flatten(store.categories || [])
        },

        sortedCategories(store) {
            const categories = [ ...store.categories ]
            return categories.sort((a, b) => {
                if (a.name === 'Other') return 1
                if (b.name === 'Other') return -1
                return a.name.localeCompare(b.name)
            })
        }
    },

    actions: {
        async initialize() {
            if (this.isInitialized) return
            if (this.initializationPromise) return this.initializationPromise

            this.initializationPromise = this.resource.fetch(this)
            await this.initializationPromise

            this.isInitialized = true
        },

        find(id) {
            return this.flatCategories.find(c => c.id == id)
        }
    }
})

export default useEventCategoriesStore
