import useMyGeoFeaturesStore from '@/stores/me/geo-features'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useGeoFeatureEditModalStore = defineForm({
    id: 'overviewModalsGeoFeatureEdit',

    inputs: () => ({
        name: null,
        feature: {},
        layer: {}
    }),

    async submitRequest() {
        let layerId = this.inputs.layer?.id || this.inputs.feature.layerId
        
        let route = this.inputs.feature.id
            ? [ 'sentinel geo-features features update', { layerId, id: this.inputs.feature.id } ]
            : [ 'sentinel geo-features features store', { layerId } ]

        const feature = { color: "blue", type:"polygon", filter: true, ...this.inputs.feature }

        return api.route(...route)
            .formData({
                _method: this.inputs.feature.id ? 'put' : 'post',
                name: this.inputs.name,
                geometry: JSON.stringify(feature.geometry),
                color: feature.color,
                type: feature.type
            })
            .post()
    },

    async onResponse(res) {
        useMyGeoFeaturesStore().reloadLayers()
        
        useModal().hide('overview-geo-feature-edit')

        this.fulfillPromise(res)
    },

    actions: {
        open(feature = {}) {
            this.reset()

            this.inputs.name = feature.name
            this.inputs.feature = feature

            if (! feature.layerId) this.inputs.layer = useMyGeoFeaturesStore().layers.find(l => l.type == 'default')

            useModal().show('overview-geo-feature-edit')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('overview-geo-feature-edit')

            this.fulfillPromise()
        },

        async delete() {
            try {
                await api.route('sentinel geo-features features delete', { layerId: this.inputs.feature.layerId, id: this.inputs.feature.id }).delete()
            } catch (e) {
                return
            }
            
            useMyGeoFeaturesStore().reloadLayers()

            useModal().hide('overview-geo-feature-edit')

            this.fulfillPromise()
        }
    }
})

export default useGeoFeatureEditModalStore
