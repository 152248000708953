<template>
    <ui-modal name="tags-manage" unstyled :content-class="contentClass">
        <div class="flex items-center justify-between px-8 py-6 border-b border-gray-600">
            <h1 class="font-semibold text-gray-300">
                My Tags
            </h1>

            <a href="#" class="text-xl text-gray-300 hover:text-gray-100 leading-none" @click.prevent="$modal.hide('tags-manage')">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>

        <div class="flex flex-1 min-h-0" v-if="mode == 'index'">
            <div class="flex flex-col w-1/3 p-2 pb-4 space-y-3 bg-gray-75 border-r border-gray-600 rounded-bl-md">
                <div class="flex flex-col flex-1 space-y-1 overflow-y-auto text-sm">
                    <a href="#" :key="group.id" v-for="group in tagGroups" class="px-3 py-1 rounded" :class="{ 'bg-gray-500': group == selectedGroup }" @click.prevent="selectedGroup = group">
                        {{group.name}}
                    </a>
                </div>
                <div class="flex justify-center">
                    <a href="#" class="flex items-center text-gerulata-green-100 hover:text-gerulata-green-300 text-sm" @click.prevent="editGroup">
                        <ui-icon name="plus-circle" class="mr-1"></ui-icon>
                        Create Group
                    </a>
                </div>
            </div>

            <div class="flex flex-col flex-1 p-4 rounded-r-md" v-if="selectedGroup">
                <div class="mb-4 px-1 flex items-center justify-between pb-5 border-b border-gray-600">
                    <span class="text-lg font-semibold">
                        {{selectedGroup.name}}
                    </span>
                    <a href="#" class="flex items-center text-gerulata-green-100 hover:text-gerulata-green-300 text-sm" @click.prevent="editGroup(selectedGroup)">
                        <ui-icon name="edit" class="mr-1"></ui-icon>
                        Edit Group
                    </a>
                </div>
                <div class="flex-1 overflow-y-auto space-y-1">
                    <template v-if="tags.length">
                        <a href="#" class="bg-gray-800 border border-gray-600 shadow-sm hover:shadow rounded flex items-center px-3 py-2" :key="tag.id" v-for="tag in tags">
                            <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${tag.color} mr-2`"></span>
                            {{tag.name}}
                            <span class="ml-auto text-gerulata-green-100 hover:text-gerulata-green-300" @click.prevent="editTag(tag, selectedGroup)">
                                <ui-icon name="edit"></ui-icon>
                            </span>
                        </a>

                        <div class="flex justify-center py-4">
                            <a href="#" class="flex items-center text-gerulata-green-100 hover:text-gerulata-green-300 text-sm" @click.prevent="editTag({}, selectedGroup)">
                                <ui-icon name="plus-circle" class="mr-1"></ui-icon>
                                Create Tag
                            </a>
                        </div>
                    </template>

                    <div class="text-sm text-gray-400 py-36 h-full text-center" v-else>
                        <p>
                            Empty group.
                        </p>
                        <div class="flex justify-center mt-1">
                            <a href="#" class="flex items-center text-gerulata-green-100 hover:text-gerulata-green-300 text-sm" @click.prevent="editTag({}, selectedGroup)">
                                <ui-icon name="plus-circle" class="mr-1"></ui-icon>
                                Create Tag
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="mode == 'edit-group'">
            <ui-form :store="myModalsTagsManageGroupEditStore" v-slot="{ inputs, submitting, errors }" class="px-8 py-6">
                <label for="name" class="block text-sm text-gray-300 font-medium mb-1">
                    Name
                </label>
                <div>
                    <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
                </div>

                <div class="flex mt-8">
                    <ui-button color="red" :action="deleteGroup" v-if="inputs.group.id">
                        Delete
                    </ui-button>

                    <ui-button :action="cancelGroupEdit" class="ml-auto">
                        Cancel
                    </ui-button>

                    <ui-button type="submit" color="green" :disabled="submitting" class="ml-4">
                        {{ inputs.group.id ? 'Save' : 'Create' }}
                    </ui-button>
                </div>
            </ui-form>
        </div>

        <div v-else-if="mode == 'edit-tag'">
            <ui-form :store="myModalsTagsManageTagEditStore" v-slot="{ inputs, submitting, errors }" class="px-8 py-6">
                <label for="name" class="block text-sm text-gray-300 font-medium mb-1">
                    Name
                </label>
                <div>
                    <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
                </div>

                <div class="mt-6">
                    <label class="block text-sm text-gray-300 font-medium mb-1">
                        Color
                    </label>
                    <div class="flex flex-wrap mt-2 -mx-1">
                        <div class="flex" :key="index" v-for="variant, index in colorVariants">
                            <a href="#" class="w-6 h-6 rounded-full m-1" :class="{ [`bg-${currentColor}-${variant}`]: true, 'ring': inputs.color, 'opacity-25 ring-transparent': inputs.color && `${currentColor}-${variant}` != inputs.color }" :key="index" v-for="currentColor, index in colors" @click.prevent="inputs.color = `${currentColor}-${variant}`"></a>
                        </div>
                    </div>
                    <div class="text-xs text-red-600 mt-1" v-if="errors.color">
                        <div>Please select a color for this tag.</div>
                    </div>
                </div>

                <div class="flex mt-8">
                    <ui-button color="red" :action="deleteTag" v-if="inputs.tag.id">
                        Delete
                    </ui-button>

                    <ui-button :action="cancelTagEdit" class="ml-auto">
                        Cancel
                    </ui-button>

                    <ui-button type="submit" color="green" :disabled="submitting" class="ml-4">
                        {{ inputs.tag.id ? 'Save' : 'Create' }}
                    </ui-button>
                </div>
            </ui-form>
        </div>
    </ui-modal>
</template>

<script>
import useMyModalsTagsManageStore from '@/stores/me/modals/tags-manage'
import useMyModalsTagsManageGroupEditStore from '@/stores/me/modals/tags-manage-group-edit'
import useMyModalsTagsManageTagEditStore from '@/stores/me/modals/tags-manage-tag-edit'

import useTaggingStore from '@/stores/me/tagging'

import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    data: () => ({
        // Explicitly listing all generated color classes to make sure they are included in the generated stylesheet.
        // bg-gray-300 bg-gray-400 bg-gray-500 bg-gray-600 bg-gray-700 bg-gray-800
        // bg-red-300 bg-red-400 bg-red-500 bg-red-600 bg-red-700 bg-red-800
        // bg-yellow-300 bg-yellow-400 bg-yellow-500 bg-yellow-600 bg-yellow-700 bg-yellow-800
        // bg-green-300 bg-green-400 bg-green-500 bg-green-600 bg-green-700 bg-green-800
        // bg-blue-300 bg-blue-400 bg-blue-500 bg-blue-600 bg-blue-700 bg-blue-800
        // bg-indigo-300 bg-indigo-400 bg-indigo-500 bg-indigo-600 bg-indigo-700 bg-indigo-800
        // bg-purple-300 bg-purple-400 bg-purple-500 bg-purple-600 bg-purple-700 bg-purple-800
        // bg-pink-300 bg-pink-400 bg-pink-500 bg-pink-600 bg-pink-700 bg-pink-800
        // text-gray-300 text-gray-400 text-gray-500 text-gray-600 text-gray-700 text-gray-800
        // text-red-300 text-red-400 text-red-500 text-red-600 text-red-700 text-red-800
        // text-yellow-300 text-yellow-400 text-yellow-500 text-yellow-600 text-yellow-700 text-yellow-800
        // text-green-300 text-green-400 text-green-500 text-green-600 text-green-700 text-green-800
        // text-blue-300 text-blue-400 text-blue-500 text-blue-600 text-blue-700 text-blue-800
        // text-indigo-300 text-indigo-400 text-indigo-500 text-indigo-600 text-indigo-700 text-indigo-800
        // text-purple-300 text-purple-400 text-purple-500 text-purple-600 text-purple-700 text-purple-800
        // text-pink-300 text-pink-400 text-pink-500 text-pink-600 text-pink-700 text-pink-800
        colors: [ 'gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink' ],
        colorVariants: [ '300', '400', '500', '600', '700', '800' ]
    }),

    computed: {
        ...mapState(useTaggingStore, {
            tagGroups: store => [ ...store.tagGroups ].sort((a, b) => a.name.localeCompare(b.name))
        }),

        ...mapState(useMyModalsTagsManageStore, {
            mode: 'mode',
            tags: store => [ ...store.selectedGroup.tags ].sort((a, b) => a.name.localeCompare(b.name))
        }),

        ...mapWritableState(useMyModalsTagsManageStore, {
            selectedGroup: 'selectedGroup'
        }),

        ...mapStores(useMyModalsTagsManageGroupEditStore, useMyModalsTagsManageTagEditStore),

        contentClass() {
            return `bg-gray-700 flex flex-col rounded-md ${this.mode == 'index' ? 'h-128' : ''} max-h-full w-full`
        }
    },

    methods: {
        ...mapActions(useMyModalsTagsManageGroupEditStore, { cancelGroupEdit: 'cancel', deleteGroup: 'delete', editGroup: 'show' }),
        ...mapActions(useMyModalsTagsManageTagEditStore, { cancelTagEdit: 'cancel', deleteTag: 'delete', editTag: 'show' })
    }
}
</script>
