<template>
    <div class="w-1/3 pr-6 absolute right-0 top-5 bottom-10" v-if="show">
        <div class="h-full w-full rounded-md bg-gradient-to-b shadow-xl from-gray-600/80 to-gray-700/80 backdrop-blur border border-gray-500/70 pointer-events-auto flex flex-col">

            <div class="flex flex-col h-full items-center justify-center" v-if="loading">
                <ui-spinner></ui-spinner>
            </div>

            <channel-wrapper v-else-if="channel" />
            <channels-wrapper v-else-if="channels" />
        </div>
    </div>
</template>

<script>

import { mapState } from "pinia";
import ChannelWrapper from "@/components/channels/detail/channel-wrapper.vue"
import ChannelsWrapper from "@/components/channels/detail/channels-wrapper.vue"
import useChannelDetailStore from "@/stores/channels/channel-detail"


export default {
    components: {
        ChannelsWrapper, ChannelWrapper
    },

    computed: {
        ...mapState(useChannelDetailStore, [ 'channel', 'channels', 'loading', 'show' ])
    }
}
</script>
