<template>
    <a @click="channelClick(channel)" target="_blank" rel="noreferrer" v-for="channel in channels" :key="channel.id" class="flex justify-between items-center space-x-5 leading-tight cursor-pointer mb-3">
        <div class="flex items-center">
            <div class="w-14 h-14">
                <ui-avatar :item="channel" class="inline-block justify-center w-14 h-14 rounded-full bg-gray-400 object-contain"></ui-avatar>
            </div>

            <div class="ml-2.5">
                <div class="font-semibold text-gray-100">
                    {{ channel.title }}

                    <span class="font-semibold text-gray-100" v-if="channel.title != channel.titleInternational">
                        ({{ channel.titleInternational }})
                    </span>
                </div>

                <div v-if="channel.type" class="flex items-center space-x-1 text-sm text-gray-300 float-left">
                    <span class="capitalize">{{ platforms[channel.type]?.label || channel.type }}</span>
                </div>
            </div>
        </div>

        <div class="text-xl text-gray-300 ml-1 ">{{ channel.reliability }}</div>
    </a>
</template>

<script>
import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    props: [ 'channels', 'channelClick' ],

    computed: {
        ...mapState(useDetailsStore, [ 'platforms' ])
    }
}
</script>
