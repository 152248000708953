<template>
    <div class="px-2 py-4">
        <h1 class="text-2xl my-2">Channel:</h1>
        <json-tree :data="channel" :level="3"></json-tree>

        <h1 class="text-2xl my-2">Reports:</h1>
        <json-tree :data="reports" :level="3"></json-tree>
    </div>
</template>

<script>
import JsonTree from 'vue-json-tree'

import useChannelDetailStore from "@/stores/channels/channel-detail"

import { mapState } from 'pinia'

export default {
    components: {
        JsonTree
    },
    
    computed: {
        ...mapState(useChannelDetailStore, [ 'channel', 'reports' ])
    }
}
</script>

<style>
.json-tree {
    @apply text-amber-500;
}

.json-tree-root {
    @apply bg-gray-600;
}

.json-tree-paired,
.json-tree-row:hover {
    @apply bg-gray-700;
}

.json-tree-value-string {
    @apply text-lime-300;
}

.json-tree-value-number {
    @apply text-cyan-500;
}
</style>
