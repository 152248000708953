<template>
    <div class="space-y-4">
        <div>
            <label for="frequency" class="block text-sm text-gray-300 font-medium mb-1">
                Frequency
            </label>

            <div>
                <ui-select id="frequency" :options="frequency" v-model="store.inputs.settings.frequency" form></ui-select>
                <div class="mt-1 text-xs text-gray-400">
                    You will receive your digest at the start of the next day, week or month.
                </div>
            </div>
        </div>

        <div>
            <label for="deliveryTime" class="block text-sm text-gray-300 font-medium mb-1">
                Delivery Time
            </label>

            <div>
                <div class="flex gap-x-3">
                    <ui-select id="deliveryTime" :options="deliveryTime" v-model="store.inputs.settings.deliveryTime" form></ui-select>
                </div>
                <div class="mt-1 text-xs text-gray-400">
                    Choose the time, when you would like to receive your digests.
                </div>
            </div>
        </div>

        <div v-if="store.inputs.settings.frequency == 'daily'">
            <label for="deliverySecondTime" class="block text-sm text-gray-300 font-medium mb-1">
                Mid-day Digest
            </label>

            <div>
                <div class="flex gap-x-3">
                    <ui-select v-if="store.inputs.settings.frequency == 'daily'" id="deliverySecondTime" :options="deliveryTime" v-model="store.inputs.settings.deliverySecondTime" placeholder="(optional)" clearable form></ui-select>
                </div>
                <div class="mt-1 text-xs text-gray-400">
                    For daily digests, you can optionally specify an additional delivery time, when you'll receive a partial digest for current day.
                </div>
            </div>
        </div>

        <div>
            <label for="sorting" class="block text-sm text-gray-300 font-medium mb-1">
                Content Sorting
            </label>

            <div>
                <ui-select id="sorting" :options="sorting" v-model="store.inputs.settings.sorting" form></ui-select>
                <div class="mt-1 text-xs text-gray-400">
                    Your digest will include a sample of the 100 top published content items sorted by the selected metric.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        frequency: [
            { id: 'daily', name: 'Daily' },
            { id: 'weekly', name: 'Weekly' },
            { id: 'monthly', name: 'Monthly' }
        ],

        sorting: [
            { id: 'date-desc', name: 'Latest', icon: 'calendar' },
            { id: 'date-asc', name: 'Oldest', icon: 'calendar' }
        ]
    }),

    computed: {
        deliveryTime() {
            return Array(24).fill(null).map((_, hour) => ({ id: hour, name: `${hour}:00` }))
        }
    }
}
</script>