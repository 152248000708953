import { asyncResource, events } from '@/api'

import { defineStore } from 'pinia'

export const useTriggerEveryMatchStore = defineStore({
    id: 'alertTriggerEveryMatch',

    state: () => ({
        trigger: null,

        events: asyncResource({
            method: 'post',
            request: (api, store, payload) => events()
                .ids(store.trigger.meta.events)
                .query(payload)
                .sorting('date-desc')
                .limit(25)
                .toRequest(),
            paginated: true
        }),

        lastReloadTime: +(new Date)
    }),

    actions: {
        async load(trigger) {
            this.trigger = trigger
            this.lastReloadTime = +(new Date)

            this.events.reset()
            this.events.fetchFirst(this)
        },

        async loadMore(infiniteScroll) {
            if (this.events.isFetching) return infiniteScroll?.loaded()

            let items = await this.events.fetchNext(this)

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()
        }
    }
})

export default useTriggerEveryMatchStore
